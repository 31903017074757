import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { SettingsContext } from "../../../SettingsContext";
import PhoneNumberInput from "../../../global/PhoneNumberInput";
import { addCustomerToOrganization } from "../../../../services/Http/Api";
import { useTranslation } from 'react-i18next';

const UserCreate = ({ onClose, onUserAdded }) => {
    const { siteSettings } = useContext(SettingsContext);
    const { t } = useTranslation();
    const [formData, setFormData] = useState({
        name: '',
        surname: '',
        email: '',
        phone_number: '',
        phone_country_id: '',
        country_id: '',
        personal_id: '',
        gender: ''
    });

    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handlePhoneNumberChange = ({ phoneCountryCode, phone }) => {
        setFormData({ ...formData, phone_country_id: phoneCountryCode, phone_number: phone });
    };

    const handleSubmit = async () => {
        setLoading(true);
        setErrors({});
        try {
            await addCustomerToOrganization(formData);
            onUserAdded(); // Notify parent component
            onClose(); // Close the modal
        } catch (error) {
            if (error.response && error.response.status === 422) {
                setErrors(error.response.data.errors);
            } else {
                console.error('Failed to create buyer:', error);
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <StyledForm>
            {Object.keys(errors).length > 0 && (
                <ErrorAlert>{t('Users.UserCreate.errorAlert')}</ErrorAlert>
            )}

            <FormGroup>
                <Label>{t('Users.UserCreate.name')}</Label>
                <Input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    isInvalid={!!errors.name}
                />
                {errors.name && <Feedback>{errors.name}</Feedback>}
            </FormGroup>

            <FormGroup>
                <Label>{t('Users.UserCreate.surname')}</Label>
                <Input
                    type="text"
                    name="surname"
                    value={formData.surname}
                    onChange={handleInputChange}
                    isInvalid={!!errors.surname}
                />
                {errors.surname && <Feedback>{errors.surname}</Feedback>}
            </FormGroup>

            <FormGroup>
                <Label>{t('Users.UserCreate.email')}</Label>
                <Input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    isInvalid={!!errors.email}
                />
                {errors.email && <Feedback>{errors.email}</Feedback>}
            </FormGroup>

            <FormGroup>
                <Label>{t('Users.UserCreate.country')}</Label>
                <Select
                    name="country_id"
                    value={formData.country_id}
                    onChange={handleInputChange}
                    isInvalid={!!errors.country_id}
                >
                    <option value="">{t('Users.UserCreate.selectCountry')}</option>
                    {siteSettings?.countries.map((country) => (
                        <option key={country.id} value={country.id}>
                            {country.name}
                        </option>
                    ))}
                </Select>
                {errors.country_id && <Feedback>{errors.country_id}</Feedback>}
            </FormGroup>

            <FormGroup>
                <Label>{t('Users.UserCreate.phoneNumber')}</Label>
                <PhoneNumberInput
                    countryCodes={siteSettings.countries}
                    phoneValue={formData.phone_number}
                    phoneCountryValue={formData.phone_country_id}
                    onChange={handlePhoneNumberChange}
                />
                {errors.phone_number && <Feedback>{errors.phone_number}</Feedback>}
                {errors.phone_country_id && <Feedback>{errors.phone_country_id}</Feedback>}
            </FormGroup>

            <FormGroup>
                <Label>{t('Users.UserCreate.personalId')}</Label>
                <Input
                    type="text"
                    name="personal_id"
                    value={formData.personal_id}
                    onChange={handleInputChange}
                    isInvalid={!!errors.personal_id}
                />
                {errors.personal_id && <Feedback>{errors.personal_id}</Feedback>}
            </FormGroup>

            <FormGroup>
                <Label>{t('Users.UserCreate.gender')}</Label>
                <Select
                    name="gender"
                    value={formData.gender}
                    onChange={handleInputChange}
                    isInvalid={!!errors.gender}
                >
                    <option value="">{t('Users.UserCreate.selectGender')}</option>
                    <option value="Male">{t('Users.UserCreate.male')}</option>
                    <option value="Female">{t('Users.UserCreate.female')}</option>
                </Select>
                {errors.gender && <Feedback>{errors.gender}</Feedback>}
            </FormGroup>

            <ButtonContainer>
                <Button type="button" onClick={onClose} disabled={loading}>
                    {t('Users.UserCreate.cancel')}
                </Button>
                <SubmitButton type="button" onClick={handleSubmit} disabled={loading}>
                    {loading ? t('Users.UserCreate.saving') : t('Users.UserCreate.save')}
                </SubmitButton>
            </ButtonContainer>
        </StyledForm>
    );
};

export default UserCreate;

// Styled Components
const StyledForm = styled.form`
    display: flex;
    flex-direction: column;
    gap: 15px;
`;

const ErrorAlert = styled.div`
    background-color: rgba(255, 0, 0, 0.1);
    color: red;
    padding: 10px;
    border-radius: 5px;
    font-size: 0.9rem;
`;

const FormGroup = styled.div`
    display: flex;
    flex-direction: column;
`;

const Label = styled.label`
    font-size: 0.9rem;
    font-weight: bold;
    margin-bottom: 5px;
`;

const Input = styled.input`
    padding: 10px;
    border: 1px solid ${({ isInvalid }) => (isInvalid ? 'red' : '#ccc')};
    border-radius: 5px;
    font-size: 1rem;
    outline: none;

    &:focus {
        border-color: rgba(130, 22, 146, 0.8);
    }
`;

const Select = styled.select`
    padding: 10px;
    border: 1px solid ${({ isInvalid }) => (isInvalid ? 'red' : '#ccc')};
    border-radius: 5px;
    font-size: 1rem;
    outline: none;

    &:focus {
        border-color: rgba(130, 22, 146, 0.8);
    }
`;

const Feedback = styled.span`
    font-size: 0.8rem;
    color: red;
    margin-top: 5px;
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 10px;
`;

const Button = styled.button`
    background-color: #f0f0f0;
    color: #333;
    border: 1px solid #ccc;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
        background-color: #e0e0e0;
    }
`;

const SubmitButton = styled(Button)`
    background-color: rgba(130, 22, 146, 1);
    color: white;
    border: none;

    &:hover {
        background-color: rgba(130, 22, 146, 0.9);
    }
`;
