import React, { useState, useEffect } from 'react';
import { TabContent, TabPane, Spinner } from 'reactstrap';
import { Modal } from 'react-bootstrap';
import styled from 'styled-components';
import classnames from 'classnames';
import ItemDescription from './ItemDescription';
import TransactionsTable from './TransactionsTable';
import { useParams } from 'react-router-dom';
import { fetchItemDetails } from "../../../../services/Http/Api";
import { useTranslation } from 'react-i18next';
import QuestionModal from "../../../global/QuestionModal";
import { MdHelpOutline, MdInfoOutline } from 'react-icons/md';

const ViewItem = () => {
    const { itemId } = useParams();
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState('1');
    const [itemDetails, setItemDetails] = useState(null);
    const [loading, setLoading] = useState(false);
    const [showTutorialModal, setShowTutorialModal] = useState(false);
    const [showQuestionModal, setShowQuestionModal] = useState(false);

    useEffect(() => {
        const getItemDetails = async () => {
            setLoading(true);
            try {
                const data = await fetchItemDetails(itemId);
                setItemDetails(data);
            } catch (error) {
                console.error('Error fetching item details:', error);
            } finally {
                setLoading(false);
            }
        };

        getItemDetails();
    }, [itemId]);

    const toggleTab = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    const handleShowTutorialModal = () => setShowTutorialModal(true);
    const handleCloseTutorialModal = () => setShowTutorialModal(false);
    const handleShowQuestionModal = () => setShowQuestionModal(true);
    const handleCloseQuestionModal = () => setShowQuestionModal(false);

    return (
        <ViewItemContainer>
            <Header>
                <Tabs>
                    <Tab
                        active={activeTab === '1'}
                        onClick={() => toggleTab('1')}
                    >
                        {t('Items.ViewItem.tabs.description')}
                    </Tab>
                    <Tab
                        active={activeTab === '2'}
                        onClick={() => toggleTab('2')}
                    >
                        {t('Items.ViewItem.tabs.transactions')}
                    </Tab>
                </Tabs>
                <ButtonGroup>
                    <IconButton onClick={handleShowQuestionModal}>
                        <MdHelpOutline />
                    </IconButton>
                    <IconButton onClick={handleShowTutorialModal}>
                        <MdInfoOutline />
                    </IconButton>
                </ButtonGroup>
            </Header>

            <Content>
                <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                        <StyledCard>
                            {loading ? (
                                <SpinnerWrapper>
                                    <Spinner />
                                </SpinnerWrapper>
                            ) : (
                                <ItemDescription itemDetails={itemDetails} />
                            )}
                        </StyledCard>
                    </TabPane>
                    <TabPane tabId="2">
                        <StyledCard>
                            <TransactionsTable itemId={itemId} />
                        </StyledCard>
                    </TabPane>
                </TabContent>
            </Content>

            <QuestionModal
                show={showQuestionModal}
                onClose={handleCloseQuestionModal}
                param="item_view"
            />

            <StyledModal show={showTutorialModal} onHide={handleCloseTutorialModal} size="xl" centered>
                <ModalHeader>
                    <ModalTitle>{t('Items.ViewItem.Tutorial.title')}</ModalTitle>
                    <CloseButton onClick={handleCloseTutorialModal}>&times;</CloseButton>
                </ModalHeader>
                <ModalBody>
                    <TutorialCard>
                        <h5>{t('Items.ViewItem.Tutorial.tabs.description.title')}</h5>
                        <p>{t('Items.ViewItem.Tutorial.tabs.description.message')}</p>
                    </TutorialCard>
                    <TutorialCard>
                        <h5>{t('Items.ViewItem.Tutorial.tabs.transactions.title')}</h5>
                        <p>{t('Items.ViewItem.Tutorial.tabs.transactions.message')}</p>
                    </TutorialCard>
                </ModalBody>
            </StyledModal>
        </ViewItemContainer>
    );
};

export default ViewItem;

// Styled Components
const ViewItemContainer = styled.div`
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    border-bottom: 2px solid rgba(130, 22, 146, 0.2);
`;

const Tabs = styled.div`
    display: flex;
`;

const Tab = styled.button`
    padding: 10px 20px;
    background: ${({ active }) => (active ? '#fff' : 'transparent')};
    border: none;
    border-bottom: ${({ active }) =>
    active ? '4px solid rgba(130, 22, 146, 1)' : 'none'};
    color: ${({ active }) => (active ? 'rgba(130, 22, 146, 1)' : '#333')};
    font-weight: bold;
    cursor: pointer;

    &:hover {
        color: rgba(130, 22, 146, 1);
    }
`;

const ButtonGroup = styled.div`
    display: flex;
    gap: 10px;
`;

const IconButton = styled.button`
    background-color: rgba(130, 22, 146, 0.1);
    color: rgba(130, 22, 146, 1);
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
        background-color: rgba(130, 22, 146, 0.2);
    }

    svg {
        font-size: 1.5rem;
    }
`;

const Content = styled.div`
    margin-top: 20px;
`;

const StyledCard = styled.div`
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const SpinnerWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
`;

const StyledModal = styled(Modal)`
    .modal-content {
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    }
`;

const ModalHeader = styled.div`
    padding: 15px;
    background-color: rgba(130, 22, 146, 0.1);
    border-bottom: 1px solid rgba(130, 22, 146, 0.2);
`;

const ModalTitle = styled.h5`
    margin: 0;
    color: rgba(130, 22, 146, 1);
    font-weight: bold;
`;

const CloseButton = styled.button`
    background: none;
    border: none;
    font-size: 1.5rem;
    color: #333;

    &:hover {
        color: rgba(130, 22, 146, 1);
    }
`;

const ModalBody = styled.div`
    padding: 20px;
`;

const TutorialCard = styled.div`
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 8px;
    border: 1px solid rgba(130, 22, 146, 0.2);
    color: #333;
    margin-bottom: 15px;

    h5 {
        font-size: 1.25rem;
        color: rgba(130, 22, 146, 1);
        margin-bottom: 10px;
    }

    p {
        font-size: 1rem;
        line-height: 1.5;
    }
`;
