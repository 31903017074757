import React from 'react';
import { FlagIcon } from 'react-flag-kit';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const SalesByCountries = ({ data }) => {
    const { t } = useTranslation();

    return (
        <StyledCard>
            <CardHeader>
                <CardTitle>{t('Users.UserStatistics.salesByCountry.title')}</CardTitle>
            </CardHeader>
            <CardBody>
                <CountryList>
                    {data.map((countryData, index) => (
                        <CountryItem key={index}>
                            <FlagContainer>
                                <FlagIcon code={countryData.country.iso} size={64} className="rounded" />
                            </FlagContainer>
                            <CountryContent>
                                <CountryDetails>
                                    <CountrySales>{countryData.country.name}</CountrySales>
                                    <CountryUsers>{t('Users.UserStatistics.salesByCountry.userCount', { userCount: countryData.user_count })}</CountryUsers>
                                </CountryDetails>
                                <SalesBadge>${parseFloat(countryData.total_sales).toLocaleString()}</SalesBadge>
                            </CountryContent>
                        </CountryItem>
                    ))}
                </CountryList>
            </CardBody>
        </StyledCard>
    );
};

export default SalesByCountries;

// Styled Components
const StyledCard = styled.div`
    height: 100%;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    display: flex;
    flex-direction: column;
`;

const CardHeader = styled.div`
    padding: 15px 20px;
    background-color: rgba(130, 22, 146, 0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const CardTitle = styled.h5`
    margin: 0;
    color: rgba(130, 22, 146, 1);
    font-weight: bold;
`;

const CardBody = styled.div`
    padding: 20px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
`;

const CountryList = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
`;

const CountryItem = styled.li`
    display: flex;
    align-items: center;
    padding: 15px 0;
    border-bottom: 1px solid #e0e0e0;

    &:last-child {
        border-bottom: none;
    }
`;

const FlagContainer = styled.div`
    width: 48px;  /* Circle diameter */
    height: 48px; /* Circle diameter */
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
`;

const CountryContent = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;

const CountryDetails = styled.div`
    display: flex;
    flex-direction: column;
`;

const CountrySales = styled.h6`
    margin: 0;
    font-size: 1rem;
    font-weight: bold;
    color: #333;
`;

const CountryUsers = styled.small`
    color: #888;
    font-size: 0.875rem;
`;

const SalesBadge = styled.span`
    background-color: rgba(130, 22, 146, 0.8);
    color: #fff;
    padding: 6px 12px;
    border-radius: 12px;
    font-weight: bold;
    font-size: 0.9rem;
`;
