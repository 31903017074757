import React, { useState, useEffect } from 'react';
import i18n from '../lang/i18n';
import LanguageButton from '../shared/layout/components/buttons/LanguageButton';

const LanguageProvider = ({ children }) => {
    const [language, setLanguage] = useState(null);

    useEffect(() => {
        const savedLanguage = localStorage.getItem('language');
        if (savedLanguage) {
            setLanguage(savedLanguage);
      i18n.changeLanguage(savedLanguage);
        }
  }, []);

    const handleLanguageSelect = (lang) => {
        setLanguage(lang);
        localStorage.setItem('language', lang);
    i18n.changeLanguage(lang);
    };

    if (!language) {
        return (
      <div
        style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        textAlign: 'center',
        }}
      >
        <h2>Select Your Language</h2>
        <div
          style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
            gap: '2vw',
        marginTop: '2vw',
          }}
        >
            <LanguageButton
                onClick={() => handleLanguageSelect('en')}
                countryCode="GB"
                bgColor="transparent"
                hoverBgColor="#f0f0f0"
                borderColor="#000"
            />

            <LanguageButton
                onClick={() => handleLanguageSelect('hr')}
                countryCode="HR"
                bgColor="transparent"
                hoverBgColor="#f0f0f0"
                borderColor="#000"
            />
        </div>
      </div>
    );
  }

  return <>{children}</>;
};

export default LanguageProvider;
