import React, { useContext } from 'react';
import styled from 'styled-components';
import { SettingsContext } from "../SettingsContext";

const PeriodSelectBox = ({ value, onChange }) => {
    const { siteSettings, loading } = useContext(SettingsContext);

    if (loading) {
        return <LoadingText>Loading...</LoadingText>;
    }

    return (
        <Select value={value} onChange={onChange}>
            {siteSettings.periods.map((period) => (
                <Option key={period.value + period.name} value={period.value}>
                    {period.name}
                </Option>
            ))}
        </Select>
    );
};

export default PeriodSelectBox;

// Styled Components

const Select = styled.select`
    width: auto;
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #fff;
    color: #333;
    transition: border-color 0.3s, box-shadow 0.3s;

    &:focus {
        border-color: rgba(130, 22, 146, 0.8);
        box-shadow: 0 0 5px rgba(130, 22, 146, 0.5);
        outline: none;
    }

    @media (max-width: 768px) {
        font-size: 0.9rem;
        padding: 8px;
    }
`;

const Option = styled.option`
    font-size: 1rem;

    @media (max-width: 768px) {
        font-size: 0.9rem;
    }
`;

const LoadingText = styled.div`
    font-size: 1rem;
    color: rgba(130, 22, 146, 1);
    font-weight: bold;
    text-align: center;

    @media (max-width: 768px) {
        font-size: 0.9rem;
    }
`;
