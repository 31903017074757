import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const UserInfoModal = ({ show, onClose }) => {
    const { t } = useTranslation();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [errors, setErrors] = useState({});

    const handleSubmit = () => {
        const newErrors = {};
        if (!name) newErrors.name = t('UserInfoModal.errors.nameRequired');
        if (!email) newErrors.email = t('UserInfoModal.errors.emailRequired');

        setErrors(newErrors);

        if (Object.keys(newErrors).length === 0) {
            localStorage.setItem('contactEmail', email);
            localStorage.setItem('contactName', name);
            onClose(); // Close the modal after saving data
        }
    };

    if (!show) return null;

    return (
        <ModalOverlay>
            <StyledModal>
                <ModalHeader>
                    <ModalTitle>{t('UserInfoModal.title')}</ModalTitle>
                    <CloseButton onClick={onClose}>&times;</CloseButton>
                </ModalHeader>
                <ModalBody>
                    <Explanation>{t('UserInfoModal.explanation')}</Explanation>
                    <SubExplanation>{t('UserInfoModal.explanationSub')}</SubExplanation>
                    {Object.keys(errors).length > 0 && (
                        <ErrorAlert>{t('UserInfoModal.errors.generic')}</ErrorAlert>
                    )}
                    <Form>
                        <FormGroup>
                            <label>{t('UserInfoModal.name')}</label>
                            <input
                                type="text"
                                placeholder={t('UserInfoModal.namePlaceholder')}
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                className={errors.name ? 'error' : ''}
                            />
                            {errors.name && <ErrorMessage>{errors.name}</ErrorMessage>}
                        </FormGroup>
                        <FormGroup>
                            <label>{t('UserInfoModal.email')}</label>
                            <input
                                type="email"
                                placeholder={t('UserInfoModal.emailPlaceholder')}
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className={errors.email ? 'error' : ''}
                            />
                            {errors.email && <ErrorMessage>{errors.email}</ErrorMessage>}
                        </FormGroup>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <CancelButton onClick={onClose}>{t('UserInfoModal.cancel')}</CancelButton>
                    <SaveButton onClick={handleSubmit}>{t('UserInfoModal.save')}</SaveButton>
                </ModalFooter>
            </StyledModal>
        </ModalOverlay>
    );
};

export default UserInfoModal;

// Styled Components
const ModalOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1050;
`;

const StyledModal = styled.div`
    background-color: #fff;
    border-radius: 10px;
    width: 90%;
    max-width: 500px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    overflow: hidden;
`;

const ModalHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    background-color: rgba(130, 22, 146, 0.1);
    border-bottom: 1px solid rgba(130, 22, 146, 0.2);
`;

const ModalTitle = styled.h5`
    font-size: 1.5rem;
    color: rgba(130, 22, 146, 1);
    margin: 0;
`;

const CloseButton = styled.button`
    background: none;
    border: none;
    font-size: 1.5rem;
    color: #333;
    cursor: pointer;

    &:hover {
        color: rgba(130, 22, 146, 1);
    }
`;

const ModalBody = styled.div`
    padding: 20px;
`;

const Explanation = styled.p`
    font-size: 1rem;
    margin-bottom: 10px;
`;

const SubExplanation = styled.p`
    font-size: 0.9rem;
    color: #666;
    margin-bottom: 20px;
`;

const ErrorAlert = styled.div`
    background-color: #f8d7da;
    color: #842029;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 15px;
    font-size: 0.9rem;
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    gap: 15px;
`;

const FormGroup = styled.div`
    display: flex;
    flex-direction: column;

    label {
        font-size: 1rem;
        font-weight: bold;
        margin-bottom: 5px;
    }

    input {
        padding: 10px;
        font-size: 1rem;
        border: 1px solid #ccc;
        border-radius: 5px;
        transition: border-color 0.3s;

        &.error {
            border-color: #dc3545;
        }

        &:focus {
            border-color: rgba(130, 22, 146, 0.8);
        }
    }
`;

const ErrorMessage = styled.div`
    font-size: 0.8rem;
    color: #dc3545;
    margin-top: 5px;
`;

const ModalFooter = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    padding: 15px;
    border-top: 1px solid rgba(130, 22, 146, 0.2);
    background-color: rgba(130, 22, 146, 0.05);
`;

const CancelButton = styled.button`
    background-color: transparent;
    color: #333;
    border: 1px solid rgba(130, 22, 146, 0.8);
    padding: 8px 15px;
    border-radius: 5px;
    font-size: 0.9rem;
    cursor: pointer;

    &:hover {
        background-color: rgba(130, 22, 146, 0.1);
    }
`;

const SaveButton = styled.button`
    background-color: rgba(130, 22, 146, 1);
    color: #fff;
    border: none;
    padding: 8px 15px;
    border-radius: 5px;
    font-size: 0.9rem;
    cursor: pointer;

    &:hover {
        background-color: rgba(130, 22, 146, 0.9);
    }
`;
