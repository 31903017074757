import React from 'react';
import { useTranslation } from 'react-i18next';
import SwaggerUI from 'swagger-ui-react';
import 'swagger-ui-react/swagger-ui.css';
import styled from 'styled-components';

const POSIntegration = () => {
    const { t } = useTranslation(); // Initialize translation hook

    const swaggerSpec = {
        swagger: "2.0",
        info: {
            title: t('POSIntegration.apiTitle'), // Translated API title
            version: "1.0.0",
            description: t('POSIntegration.apiDescription') // Translated API description
        },
        host: "demo.gsoft.hr",
        basePath: "/api/integration",
        paths: {
            "/generateApiKey": {
                post: {
                    summary: t('POSIntegration.generateApiKeySummary'), // Translated summary
                    parameters: [
                        {
                            name: "organizationId",
                            in: "body",
                            required: true,
                            schema: {
                                type: "string"
                            }
                        }
                    ],
                    responses: {
                        200: {
                            description: t('POSIntegration.apiKeyGenerated'), // Translated description
                            schema: {
                                type: "object",
                                properties: {
                                    apiKey: {
                                        type: "string"
                                    },
                                    secret: {
                                        type: "string"
                                    }
                                }
                            }
                        }
                    }
                }
            },
            "/importItems": {
                post: {
                    summary: t('POSIntegration.importItemsSummary'), // Translated summary
                    parameters: [
                        {
                            name: "items",
                            in: "body",
                            required: true,
                            schema: {
                                type: "array",
                                items: {
                                    type: "object",
                                    properties: {
                                        id: { type: "string" },
                                        name: { type: "string" },
                                        price: { type: "number" },
                                        category: { type: "string" }
                                    }
                                }
                            }
                        }
                    ],
                    responses: {
                        200: {
                            description: t('POSIntegration.itemsImportedSuccessfully'), // Translated description
                            schema: {
                                type: "object",
                                properties: {
                                    success: { type: "boolean" },
                                    message: { type: "string" }
                                }
                            }
                        }
                    }
                }
            }
        }
    };

    return (
        <Container>
            <Header>{t('POSIntegration.pageTitle')}</Header>
            <SwaggerContainer>
                <SwaggerUI spec={swaggerSpec} />
            </SwaggerContainer>
        </Container>
    );
};

export default POSIntegration;

// Styled Components
const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #f9f9f9;
    min-height: 100vh;
`;

const Header = styled.h1`
    color: rgba(130, 22, 146, 1);
    font-weight: bold;
    margin-bottom: 20px;
`;

const SwaggerContainer = styled.div`
    width: 100%;
    max-width: 900px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    overflow: hidden;

    .swagger-ui {
        font-family: 'Roboto', sans-serif;
    }

    .swagger-ui .topbar {
        display: none; /* Hide the top bar for a cleaner look */
    }

    .swagger-ui .info {
        margin-bottom: 20px;
    }

    .swagger-ui .opblock-summary {
        background-color: rgba(130, 22, 146, 0.1);
        border-left: 5px solid rgba(130, 22, 146, 1);
        &:hover {
            background-color: rgba(130, 22, 146, 0.2);
        }
    }

    .swagger-ui .btn {
        background-color: rgba(130, 22, 146, 1);
        color: #fff;
        &:hover {
            background-color: rgba(130, 22, 146, 0.9);
        }
    }
`;
