import React, { useState, useEffect } from 'react';
import { Row, Col, Form, FormGroup } from 'reactstrap';
import Select from 'react-select';
import { fetchManufacturers } from "../../../services/Http/Api";
import { useTranslation } from 'react-i18next';
import { MdSearch } from 'react-icons/md';
import styled from 'styled-components';

const StyledInput = styled.input`
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
    outline: none;
    width: 100%;

    &:focus {
        border-color: rgba(130, 22, 146, 0.8);
        box-shadow: 0 0 5px rgba(130, 22, 146, 0.5);
    }
`;

const SearchButton = styled.button`
    display: flex;
    align-items: center;
    gap: 8px;
    background-color: rgba(130, 22, 146, 1);
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 15px;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: rgba(130, 22, 146, 0.9);
    }

    .icon {
        font-size: 1.2rem;
    }
`;

const customSelectStyles = {
    control: (provided) => ({
        ...provided,
        border: '1px solid #ccc',
        borderRadius: '5px',
        boxShadow: 'none',
        '&:hover': {
            borderColor: 'rgba(130, 22, 146, 0.8)',
        },
    }),
    placeholder: (provided) => ({
        ...provided,
        fontSize: '1rem',
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isFocused ? 'rgba(130, 22, 146, 0.1)' : 'white',
        color: 'black',
        '&:hover': {
            backgroundColor: 'rgba(130, 22, 146, 0.2)',
        },
    }),
};

const SearchFilters = ({ onFilter }) => {
    const { t } = useTranslation();
    const [searchTerm, setSearchTerm] = useState('');
    const [manufacturerId, setManufacturerId] = useState('');
    const [manufacturers, setManufacturers] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await fetchManufacturers({ page: currentPage, perPage: 100 });
                setManufacturers(result.data);
            } catch (error) {
                console.error('Error fetching manufacturers:', error);
            }
        };

        fetchData();
    }, [currentPage]);

    const handleSearch = (e) => {
        e.preventDefault();
        onFilter({
            searchTerm,
            manufacturerId: manufacturerId || null,
        });
    };

    return (
        <Form onSubmit={handleSearch}>
            <Row className="align-items-end">
                <Col md="6">
                    <FormGroup>
                        <StyledInput
                            type="text"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            placeholder={t('Items.SearchFilters.search_placeholder')}
                        />
                    </FormGroup>
                </Col>

                <Col md="6">
                    <FormGroup>
                        <Select
                            placeholder={t('Items.SearchFilters.select_manufacturer')}
                            options={manufacturers.map((manufacturer) => ({
                                value: manufacturer.manufacturer_id,
                                label: `${manufacturer.manufacturer_name} (${manufacturer.item_count})`,
                            }))}
                            onChange={(selectedOption) => setManufacturerId(selectedOption?.value || '')}
                            isClearable
                            styles={customSelectStyles}
                        />
                    </FormGroup>
                </Col>

                <Col md="12" className="text-end">
                    <SearchButton type="submit">
                        <MdSearch className="icon" />
                        {t('Items.SearchFilters.search_button')}
                    </SearchButton>
                </Col>
            </Row>
        </Form>
    );
};

export default SearchFilters;
