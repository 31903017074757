// ManufacturerSelectorComponent.js
import React, { useContext, useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import { FormGroup, Label, Input } from 'reactstrap';
import { fetchManufacturers } from "../../../../services/Http/Api";
import { SettingsContext } from "../../../SettingsContext";

const ManufacturerSelectorComponent = ({ value, operator, onChange, target, type, attribute }) => {
    const { siteSettings } = useContext(SettingsContext);
    const [manufacturerOptions, setManufacturerOptions] = useState([]); // Store fetched manufacturer options
    const [selectedManufacturers, setSelectedManufacturers] = useState([]); // Store selected manufacturers

    // Get supported operators based on target, type, and attribute
    let supportedOperators = [];
    if (target === 'conditions') {
        const conditionTypes = siteSettings?.getConditionTypes() || [];
        const selectedConditionType = conditionTypes.find(condType => condType.key === type);

        if (selectedConditionType) {
            const selectedAttribute = selectedConditionType.getAttributes().find(attr => attr.key === attribute);
            if (selectedAttribute) {
                supportedOperators = selectedAttribute.supportedOperators || [];
            }
        }
    }

    // Fetch all manufacturers once on component mount
    useEffect(() => {
        const fetchInitialManufacturers = async () => {
            try {
                const response = await fetchManufacturers({ search: '', perPage: 100, page: 1 });
                const options = response.data.map(manufacturer => ({
                    value: manufacturer.manufacturer_id,
                    label: manufacturer.manufacturer_name,
                }));
                setManufacturerOptions(options);

                // Preselect manufacturers based on the passed `value`
                if (value) {
                    let manufacturerIds = [];
                    try {
                        manufacturerIds = JSON.parse(value); // Parse the JSON string
                    } catch (error) {
                        console.error('Invalid JSON format for manufacturer IDs:', error);
                        manufacturerIds = [];
                    }

                    // Filter out the selected manufacturers from the fetched options
                    const preSelectedManufacturers = options.filter(option => manufacturerIds.includes(option.value));
                    setSelectedManufacturers(preSelectedManufacturers);
                }
            } catch (error) {
                console.error('Error fetching manufacturers:', error);
            }
        };

        fetchInitialManufacturers(); // Fetch manufacturers once on mount
    }, []); // Empty dependency array ensures this runs only once on mount

    // Load manufacturer options based on user search input
    const loadOptions = async (inputValue) => {
        try {
            const response = await fetchManufacturers({ search: inputValue, perPage: 10, page: 1 });
            return response.data.map(manufacturer => ({
                value: manufacturer.manufacturer_id,
                label: manufacturer.manufacturer_name,
            }));
        } catch (error) {
            console.error('Error fetching manufacturers:', error);
            return [];
        }
    };

    // Handle the selection change
    const handleSelectChange = (selected) => {
        if (!selected) {
            setSelectedManufacturers([]);
            onChange('value', JSON.stringify([])); // Update parent component with an empty array
            return;
        }

        // Update the state and parent component with selected values
        const selectedValues = selected.map(opt => opt.value);
        setSelectedManufacturers(selected); // Update the local state with the selected manufacturers
        onChange('value', JSON.stringify(selectedValues)); // Update the parent component with JSON string
    };

    return (
        <FormGroup>
            {/* Operator Selection */}
            <Label for="operator">Operator</Label>
            <Input
                type="select"
                id="operator"
                value={operator || ''}
                onChange={(e) => onChange('operator', e.target.value)}
            >
                <option value="">Select Operator</option>
                {supportedOperators.map(op => (
                    <option key={op} value={op}>{op}</option>
                ))}
            </Input>

            {/* Manufacturer Selection */}
            <Label className="mt-2" for="value">Manufacturer</Label>
            <AsyncSelect
                id="value"
                isMulti
                cacheOptions
                defaultOptions={manufacturerOptions} // Use fetched manufacturer options as default
                loadOptions={loadOptions} // Use loadOptions function to fetch data when typing
                value={selectedManufacturers} // Ensure this value is controlled by state
                onChange={handleSelectChange}
                placeholder="Search and select manufacturers"
                noOptionsMessage={() => "No manufacturers available"}
            />
        </FormGroup>
    );
};

export default ManufacturerSelectorComponent;
