import React, { useEffect, useState, useContext } from 'react';
import AsyncSelect from 'react-select';
import { FormGroup, Label, Input } from 'reactstrap';
import { fetchAllVenues } from "../../../../services/Http/Api";
import { SettingsContext } from "../../../SettingsContext";

const VenueSelectorComponent = ({ value, operator, onChange, target, type, attribute }) => {
    const { siteSettings } = useContext(SettingsContext);
    const [venueOptions, setVenueOptions] = useState([]);
    const [selectedVenues, setSelectedVenues] = useState([]);

    // Fetch all venue options once when the component mounts
    useEffect(() => {
        const fetchVenues = async () => {
            try {
                const response = await fetchAllVenues(); // Fetch all venues once
                const options = response.data.map(venue => ({
                    value: venue.id,
                    label: venue.name,
                }));
                setVenueOptions(options); // Set all fetched venues as available options

                // Preselect the venues based on the passed `value`
                if (value) {
                    let venueIds = [];
                    try {
                        venueIds = JSON.parse(value); // Parse the JSON string
                    } catch (error) {
                        console.error('Invalid JSON format for venue IDs:', error);
                        venueIds = [];
                    }

                    // Filter out the selected venues from the fetched options
                    const preSelectedVenues = options.filter(option => venueIds.includes(option.value));
                    setSelectedVenues(preSelectedVenues);
                }
            } catch (error) {
                console.error('Error fetching venues:', error);
            }
        };

        fetchVenues();
    }, []); // Fetch once when the component mounts

    // Get supported operators based on target, type, and attribute
    let supportedOperators = [];
    if (target === 'conditions') {
        const conditionTypes = siteSettings?.getConditionTypes() || [];
        const selectedConditionType = conditionTypes.find(condType => condType.key === type);
        if (selectedConditionType) {
            const selectedAttribute = selectedConditionType.getAttributes().find(attr => attr.key === attribute);
            if (selectedAttribute) {
                supportedOperators = selectedAttribute.supportedOperators || [];
            }
        }
    }

    // Handle the selection change
    const handleSelectChange = (selected) => {
        const selectedValues = selected ? selected.map(opt => opt.value) : [];
        setSelectedVenues(selected); // Update the local state with the selected venues
        onChange('value', JSON.stringify(selectedValues)); // Update the parent component with JSON string
    };

    return (
        <FormGroup>
            {/* Operator Selection */}
            <Label for="operator">Operator</Label>
            <Input
                type="select"
                id="operator"
                value={operator || ''}
                onChange={(e) => onChange('operator', e.target.value)}
            >
                <option value="">Select Operator</option>
                {supportedOperators.map(op => (
                    <option key={op} value={op}>{op}</option>
                ))}
            </Input>

            {/* Venue Selection */}
            <Label className="mt-2" for="venue">Venue</Label>
            <AsyncSelect
                id="venue"
                isMulti
                cacheOptions
                options={venueOptions} // Use the fetched venueOptions directly
                defaultOptions={venueOptions} // Ensure this uses the venueOptions directly
                value={selectedVenues}
                onChange={handleSelectChange}
                placeholder="Search and select venues"
                noOptionsMessage={() => "No options available"}
            />
        </FormGroup>
    );
};

export default VenueSelectorComponent;
