import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import DataTable from 'react-data-table-component';
import { fetchTransactionStats } from '../../../services/Http/Api';
import { SettingsContext } from "../../SettingsContext";
import QuestionModal from "../../global/QuestionModal";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import {
    MdBusiness,
    MdAttachMoney,
    MdMonetizationOn,
    MdReceipt,
    MdPeople, MdHelpOutline,
} from "react-icons/md";

const TransactionsOverview = ({ period }) => {
    const { t } = useTranslation();
    const { siteSettings } = useContext(SettingsContext);
    const [transactions, setTransactions] = useState([]);
    const [totals, setTotals] = useState(null);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(100);
    const [totalRows, setTotalRows] = useState(0);
    const [sortBy, setSortBy] = useState('date');
    const [sortDirection, setSortDirection] = useState('asc');
    const [showQuestionModal, setShowQuestionModal] = useState(false);

    useEffect(() => {
        const loadStats = async () => {
            setLoading(true);
            try {
                const data = await fetchTransactionStats({
                    period,
                    page: currentPage,
                    perPage,
                    sortBy,
                    sortDirection
                });

                setTotals(data.totals);
                setTransactions(data.stats.data);
                setTotalRows(data.stats.total);
            } catch (error) {
                console.error('Failed to load stats', error);
            } finally {
                setLoading(false);
            }
        };

        loadStats();
    }, [period, currentPage, perPage, sortBy, sortDirection]);

    const handlePageChange = (page) => setCurrentPage(page);
    const handlePerRowsChange = (newPerPage, page) => {
        setPerPage(newPerPage);
        setCurrentPage(page);
    };

    const handleSort = (column, sortDirection) => {
        const fieldMapping = {
            [t('Transactions.TransactionsOverview.columns.date')]: 'date',
            [t('Transactions.TransactionsOverview.columns.totalPrice')]: 'total_price',
            [t('Transactions.TransactionsOverview.columns.totalRevenue')]: 'total_revenue',
            [t('Transactions.TransactionsOverview.columns.averagePrice')]: 'average_price',
            [t('Transactions.TransactionsOverview.columns.averageRevenue')]: 'average_revenue',
            [t('Transactions.TransactionsOverview.columns.transactionCount')]: 'transaction_count',
        };
        setSortBy(fieldMapping[column.name] || 'date');
        setSortDirection(sortDirection);
    };

    const numberFormatter = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        useGrouping: true,
    });

    const columns = [
        { name: t('Transactions.TransactionsOverview.columns.date'), selector: row => new Date(row.date).toLocaleDateString(), sortable: true },
        { name: t('Transactions.TransactionsOverview.columns.totalPrice'), selector: row => numberFormatter.format(row.total_price), sortable: true },
        { name: t('Transactions.TransactionsOverview.columns.totalRevenue'), selector: row => numberFormatter.format(row.total_revenue), sortable: true },
        { name: t('Transactions.TransactionsOverview.columns.averagePrice'), selector: row => numberFormatter.format(row.average_price || 0), sortable: true },
        { name: t('Transactions.TransactionsOverview.columns.averageRevenue'), selector: row => numberFormatter.format(row.average_revenue || 0), sortable: true },
        { name: t('Transactions.TransactionsOverview.columns.transactionCount'), selector: row => row.transaction_count, sortable: true },
    ];

    return (
        <Container>
            <TotalsContainer>
                <TotalBox color="primary">
                    <MdBusiness className="icon" />
                    <p>{t('Transactions.TransactionsOverview.distinctManufacturers')}</p>
                    <h4>{totals ? totals.distinct_manufacturers : 0}</h4>
                </TotalBox>
                <TotalBox color="success">
                    <MdAttachMoney className="icon" />
                    <p>{t('Transactions.TransactionsOverview.totalPrice')}</p>
                    <h4>{totals ? numberFormatter.format(totals.total_price || 0) : '0.00'}</h4>
                </TotalBox>
                <TotalBox color="info">
                    <MdMonetizationOn className="icon" />
                    <p>{t('Transactions.TransactionsOverview.totalRevenue')}</p>
                    <h4>{totals ? numberFormatter.format(totals.total_revenue || 0) : '0.00'}</h4>
                </TotalBox>
                <TotalBox color="warning">
                    <MdReceipt className="icon" />
                    <p>{t('Transactions.TransactionsOverview.totalTransactions')}</p>
                    <h4>{totals ? totals.total_transactions : 0}</h4>
                </TotalBox>
                <TotalBox color="danger">
                    <MdPeople className="icon" />
                    <p>{t('Transactions.TransactionsOverview.distinctBuyers')}</p>
                    <h4>{totals ? totals.distinct_buyer_count : 0}</h4>
                </TotalBox>
            </TotalsContainer>

            <QuestionButton onClick={() => setShowQuestionModal(true)}>
                <MdHelpOutline className="info-icon" />
            </QuestionButton>

            <DataTable
                columns={columns}
                data={transactions}
                pagination
                paginationServer
                paginationTotalRows={totalRows}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handlePerRowsChange}
                onSort={handleSort}
                sortServer
                highlightOnHover
                progressPending={loading}
                paginationPerPage={perPage}
                paginationRowsPerPageOptions={[10, 25, 50, 100]}
            />

            <QuestionModal
                show={showQuestionModal}
                onClose={() => setShowQuestionModal(false)}
                param="transactions_table"
            />
        </Container>
    );
};

export default TransactionsOverview;

// Styled Components
const Container = styled.div`
    padding: 20px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const TotalsContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
    margin-bottom: 20px;

    @media (max-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 480px) {
        grid-template-columns: repeat(1, 1fr);
    }
`;

const TotalBox = styled.div`
    background: ${({ color }) => getColor(color)};
    color: white;
    border-radius: 10px;
    padding: 20px;
    text-align: center;
    position: relative;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

    p {
        font-size: 1rem;
        margin: 10px 0 5px;
    }

    h4 {
        font-size: 1.5rem;
        font-weight: bold;
    }

    .icon {
        font-size: 3rem;
        margin-bottom: 10px;
        color: rgba(255, 255, 255, 0.8);
    }
`;

const getColor = (color) => {
    switch (color) {
        case 'primary':
            return '#007bff';
        case 'success':
            return '#28a745';
        case 'info':
            return '#17a2b8';
        case 'warning':
            return '#ffc107';
        case 'danger':
            return '#dc3545';
        default:
            return '#6c757d';
    }
};

const QuestionButton = styled.button`
    background-color: rgba(130, 22, 146, 0.1);
    color: rgba(130, 22, 146, 1);
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
        background-color: rgba(130, 22, 146, 0.2);
    }

    .info-icon {
        font-size: 1.5rem;
    }
`;
