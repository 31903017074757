import React, { useContext, useEffect, useState } from 'react';
import { SettingsContext } from "../../SettingsContext";
import { fetchTransactionProgress } from '../../../services/Http/Api';
import ReactApexChart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import QuestionModal from "../../global/QuestionModal";
import styled from 'styled-components';
import { MdHelpOutline } from 'react-icons/md';

const TransactionsProgress = ({ period }) => {
    const { siteSettings } = useContext(SettingsContext);
    const { t } = useTranslation();
    const [progressData, setProgressData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showQuestionModal, setShowQuestionModal] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const response = await fetchTransactionProgress({ period });
                setProgressData(response);
                setLoading(false);
            } catch (err) {
                setError(err);
                setLoading(false);
            }
        };

        if (period) {
            fetchData();
        }
    }, [period]);

    if (loading) {
        return <p>{t('Transactions.TransactionsProgress.loading')}</p>;
    }

    if (error) {
        return <p>{t('Transactions.TransactionsProgress.error', { error: error.message })}</p>;
    }

    const currentPeriodTotals = progressData.totals.current_period_totals;
    const previousPeriodTotals = progressData.totals.previous_period_totals;
    const currentDemographics = progressData.demographics.current_period_demographics;
    const previousDemographics = progressData.demographics.previous_period_demographics;

    const countries = siteSettings?.getAllCountries() || [];

    const getCountryNameById = (id) => {
        const country = countries.find(country => country.id === Number(id));
        return country ? country.name : id;
    };

    const generateChartData = (metric) => {
        const isCountryMetric = metric === 'country_summary' || metric === 'residential_country_summary';
        return {
            categories: isCountryMetric
                ? currentPeriodTotals.map(item => getCountryNameById(item.name))
                : currentPeriodTotals.map(item => item.name),
            currentSeries: currentPeriodTotals.map(item => item[metric]),
            previousSeries: previousPeriodTotals.map(item => item[metric]),
        };
    };

    const generateDemographicChartData = (metric, currentData, previousData) => {
        const categories = Object.keys(currentData[0][metric] || {});

        const currentSeries = currentData.map(item => ({
            name: getCountryNameById(item.name),
            data: categories.map(category => item[metric][category] || 0)
        }));

        const previousSeries = previousData.map(item => ({
            name: getCountryNameById(item.name),
            data: categories.map(category => item[metric][category] || 0)
        }));

        return { categories, currentSeries, previousSeries };
    };

    const createChartOptions = (categories, metricLabel) => ({
        chart: {
            type: 'bar',
            height: 350
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '55%',
                endingShape: 'rounded'
            },
        },
        dataLabels: {
            enabled: true,
            style: {
                fontSize: '12px',
                colors: ['#fff']
            }
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        xaxis: {
            categories: categories,
        },
        yaxis: {
            title: {
                text: metricLabel
            }
        },
        fill: {
            opacity: 1
        },
        tooltip: {
            y: {
                formatter: function (val) {
                    return val;
                }
            }
        }
    });

    const renderChart = (metric, metricLabel) => {
        const chartData = generateChartData(metric);

        const chartSeries = [
            {
                name: `${t('Transactions.TransactionsProgress.currentPeriod')} - ${metricLabel}`,
                data: chartData.currentSeries,
            },
            {
                name: `${t('Transactions.TransactionsProgress.previousPeriod')} - ${metricLabel}`,
                data: chartData.previousSeries,
            },
        ];

        return (
            <ReactApexChart
                key={metric}
                options={createChartOptions(chartData.categories, metricLabel)}
                series={chartSeries}
                type="bar"
                height={350}
            />
        );
    };

    const renderDemographicChart = (metric, metricLabel) => {
        const chartData = generateDemographicChartData(metric, currentDemographics, previousDemographics);

        const chartSeries = [
            {
                name: `${t('Transactions.TransactionsProgress.currentPeriod')} - ${metricLabel}`,
                data: chartData.currentSeries.reduce((acc, curr) => curr.data.map((val, idx) => (acc[idx] || 0) + val), [])
            },
            {
                name: `${t('Transactions.TransactionsProgress.previousPeriod')} - ${metricLabel}`,
                data: chartData.previousSeries.reduce((acc, curr) => curr.data.map((val, idx) => (acc[idx] || 0) + val), [])
            }
        ];

        return (
            <ReactApexChart
                key={metric}
                options={createChartOptions(chartData.categories, metricLabel)}
                series={chartSeries}
                type="bar"
                height={350}
            />
        );
    };

    return (
        <Container>
            <Header>
                <Title>{t('Transactions.TransactionsProgress.title')}</Title>
                <PeriodDescription>
                    {t('Transactions.TransactionsProgress.showingDataFor', { period })}
                </PeriodDescription>
                <QuestionButton onClick={() => setShowQuestionModal(true)}>
                    <MdHelpOutline className="info-icon" />
                </QuestionButton>
            </Header>

            {progressData && (
                <>
                    <SectionTitle>{t('Transactions.TransactionsProgress.totals')}</SectionTitle>
                    {renderChart('total_price', t('Transactions.TransactionsProgress.totalPrice'))}
                    {renderChart('total_revenue', t('Transactions.TransactionsProgress.totalRevenue'))}
                    {renderChart('total_transactions', t('Transactions.TransactionsProgress.totalTransactions'))}
                    {renderChart('distinct_buyer_count', t('Transactions.TransactionsProgress.distinctBuyerCount'))}

                    <h3>{t('Transactions.TransactionsProgress.demographics')}</h3>
                    {renderDemographicChart('age_group_summary', t('Transactions.TransactionsProgress.ageGroups'))}
                    {renderDemographicChart('gender_summary', t('Transactions.TransactionsProgress.genderDistribution'))}
                    {renderDemographicChart('country_summary', t('Transactions.TransactionsProgress.countryDistribution'))}
                    {renderDemographicChart('city_summary', t('Transactions.TransactionsProgress.cityDistribution'))}
                    {renderDemographicChart('residential_country_summary', t('Transactions.TransactionsProgress.residentialCountryDistribution'))}
                </>
            )}

            {/* Question Modal */}
            <QuestionModal
                show={showQuestionModal}
                onClose={() => setShowQuestionModal(false)}
                param="transactions_progress"
            />
        </Container>
    );
};

export default TransactionsProgress;

// Styled Components
const Container = styled.div`
    padding: 20px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const Header = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;

    @media (min-width: 768px) {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
`;

const Title = styled.h1`
    font-size: 1.5rem;
    color: rgba(130, 22, 146, 1);
    margin: 0;
`;

const PeriodDescription = styled.p`
    font-size: 1rem;
    color: #555;
    margin: 0;

    @media (max-width: 768px) {
        text-align: center;
    }
`;

const QuestionButton = styled.button`
    background: none;
    border: none;
    color: rgba(130, 22, 146, 1);
    cursor: pointer;
    .info-icon {
        font-size: 1.5rem;
    }

    &:hover {
        color: rgba(110, 12, 136, 1);
    }
`;

const SectionTitle = styled.h3`
    font-size: 1.25rem;
    color: rgba(130, 22, 146, 1);
    margin-bottom: 10px;
`;
