import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { fetchDashboardData } from '../../services/Http/Api';
import {
    MdAttachMoney,
    MdShowChart,
    MdPeople,
    MdStoreMallDirectory,
    MdBusiness,
    MdReceipt,
    MdShoppingCart,
    MdMonetizationOn,
    MdPieChart,
    MdAccountBalance,
    MdInfoOutline,
    MdHelpOutline
} from 'react-icons/md';
import { Modal } from 'react-bootstrap';
import QuestionModal from '../global/QuestionModal';
import styled from 'styled-components';

function Dashboard() {
    const { t } = useTranslation();
    const [dashboardData, setDashboardData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showDashboardTutorialModal, setShowDashboardTutorialModal] = useState(!localStorage.getItem('DashboardTutorialSeen'));
    const [showQuestionModal, setShowQuestionModal] = useState(false);

    useEffect(() => {
        const loadDashboardData = async () => {
            try {
                const data = await fetchDashboardData();
                if (Array.isArray(data) && data.length > 0) {
                    const filteredData = filterData(data[0]);
                    setDashboardData(filteredData);
                } else {
                    setError(new Error('Invalid dashboard data format.'));
                }
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        loadDashboardData();
    }, []);

    const handleCloseDashboardTutorialModal = () => {
        setShowDashboardTutorialModal(false);
        localStorage.setItem('DashboardTutorialSeen', 'true');
    };

    const handleShowDashboardTutorialModal = () => {
        setShowDashboardTutorialModal(true);
    };

    const handleShowQuestionModal = () => {
        setShowQuestionModal(true);
    };

    const handleCloseQuestionModal = () => {
        setShowQuestionModal(false);
    };

    if (loading) {
        return <div className="loading">{t('Dashboard.loading')}</div>;
    }

    if (error) {
        return <div className="error">{t('Dashboard.error')}: {error.message}</div>;
    }

    return (
        <DashboardContainer>
            <DashboardHeader>
                <Title>{t('Dashboard.title')}</Title>
                <ButtonGroup>
                    <IconButton onClick={handleShowQuestionModal}>
                        <MdHelpOutline className="info-icon" />
                    </IconButton>
                    <IconButton onClick={handleShowDashboardTutorialModal}>
                        <MdInfoOutline className="info-icon" />
                    </IconButton>
                </ButtonGroup>
            </DashboardHeader>

            <GridContainer>
                {dashboardData && Object.entries(dashboardData).map(([key, value]) => (
                    <GridItem key={key}>
                        <ItemContent>
                            {selectIcon(key)}
                            <ItemTitle>{t(`Dashboard.${key}`)}</ItemTitle>
                            <ItemValue>{formatNumber(key, value)}</ItemValue>
                        </ItemContent>
                    </GridItem>
                ))}
            </GridContainer>

            <StyledModal show={showDashboardTutorialModal} onHide={handleCloseDashboardTutorialModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{t('Dashboard.Tutorial.title')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <TutorialCard>
                        <h5>{t('Dashboard.Tutorial.explanation_title')}</h5>
                        <p>{t('Dashboard.Tutorial.explanation_message')}</p>
                    </TutorialCard>
                </Modal.Body>
            </StyledModal>

            <QuestionModal show={showQuestionModal} onClose={handleCloseQuestionModal} param="dashboard" />
        </DashboardContainer>
    );
}

const filterData = (data) => {
    const keysToExtract = [
        'average_revenue_per_user',
        'average_selling_transaction_value',
        'belonging_users_count',
        'campaigns_count',
        'manufacturer_count',
        'revenue_value',
        'selling_transactions_count',
        'sold_items_count',
        'total_transaction_volume',
        'users_count',
        'venues_count'
    ];

    return Object.fromEntries(
        Object.entries(data).filter(([key, value]) => keysToExtract.includes(key) && parseFloat(value) !== 0)
    );
};

const formatNumber = (key, value) => {
    // Specify keys that need to be formatted as floats
    const floatKeys = ['average_revenue_per_user', 'average_selling_transaction_value', 'revenue_value', 'total_transaction_volume'];
    if (floatKeys.includes(key)) {
    return parseFloat(value).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    }
    // Format as an integer for other values
    return parseInt(value, 10).toLocaleString();
};

const selectIcon = (key) => {
    const iconMapping = {
        'average_revenue_per_user': <MdAttachMoney style={{ color: '#4caf50' }} size={50} />,
        'average_selling_transaction_value': <MdShowChart style={{ color: '#ff9800' }} size={50} />,
        'belonging_users_count': <MdPeople style={{ color: '#18de15' }} size={50} />,
        'campaigns_count': <MdPieChart style={{ color: '#9c27b0' }} size={50} />,
        'manufacturer_count': <MdBusiness style={{ color: '#f44336' }} size={50} />,
        'revenue_value': <MdReceipt style={{ color: '#3f51b5' }} size={50} />,
        'selling_transactions_count': <MdShoppingCart style={{ color: '#ff5722' }} size={50} />,
        'sold_items_count': <MdStoreMallDirectory style={{ color: '#795548' }} size={50} />,
        'total_transaction_volume': <MdMonetizationOn style={{ color: '#8bc34a' }} size={50} />,
        'users_count': <MdPeople style={{ color: '#2196f3' }} size={50} />,
        'venues_count': <MdAccountBalance style={{ color: '#607d8b' }} size={50} />
    };

    return iconMapping[key] || <MdShowChart style={{ color: '#9e9e9e' }} size={50} />;
};

export default Dashboard;

// Styled Components
const DashboardContainer = styled.div`
    padding: 20px;
    font-family: Arial, sans-serif;
`;

const DashboardHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 15px;
    border-bottom: 2px solid rgba(130, 22, 146, 0.8);
`;

const Title = styled.h1`
    color: rgba(130, 22, 146, 1);
    font-size: 1.8rem;
    font-weight: bold;
    margin: 0;
`;

const ButtonGroup = styled.div`
    display: flex;
    gap: 10px;
`;

const IconButton = styled.button`
    background-color: rgba(130, 22, 146, 0.1);
    color: rgba(130, 22, 146, 1);
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
        background-color: rgba(130, 22, 146, 0.2);
    }

    .info-icon {
        font-size: 1.5rem;
    }
`;

const GridContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
    margin-top: 20px;

    @media (max-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
        gap: 15px;
    }

    @media (max-width: 480px) {
        grid-template-columns: repeat(2, 1fr);
        gap: 10px;
    }
`;

const GridItem = styled.div`
    background: #f8f9fa;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
`;

const ItemContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const ItemTitle = styled.h3`
    font-size: 1.1rem;
    color: rgba(130, 22, 146, 1);
    margin-top: 10px;
`;

const ItemValue = styled.p`
    font-size: 1.3rem;
    font-weight: bold;
    color: #333;
`;

const StyledModal = styled(Modal)`
    .modal-content {
        border-radius: 10px;
    }
    .modal-header {
        border-bottom: 1px solid rgba(130, 22, 146, 0.2);
    }
`;

const TutorialCard = styled.div`
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 8px;
    border: 1px solid rgba(130, 22, 146, 0.2);
    color: #333;

    h5 {
        font-size: 1.25rem;
        color: rgba(130, 22, 146, 1);
        margin-bottom: 10px;
    }

    p {
        font-size: 1rem;
        line-height: 1.5;
    }
`;
