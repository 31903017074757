import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faMapMarkerAlt, faGlobe, faBuilding, faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from "react-i18next";
import styled from 'styled-components';

const VenueDetails = ({ venue }) => {
    const { t } = useTranslation();
    const socialLinks = venue.social_media_links ? JSON.parse(venue.social_media_links) : {};

    return (
        <Container>
            <Header>
                <Title>{t('Venues.VenueView.VenueDetails.title')}</Title>
            </Header>
            <Content>
                <Row>
                    <Column>
                        <Label>
                            <FontAwesomeIcon icon={faBuilding} className="icon" />
                            {t('Venues.VenueView.VenueDetails.name_label')}
                        </Label>
                        <Value>{venue.name}</Value>
                    </Column>
                    <Column>
                        <Label>
                            <FontAwesomeIcon icon={faEnvelope} className="icon" />
                            {t('Venues.VenueView.VenueDetails.email_label')}
                        </Label>
                        <Value>{venue.email}</Value>
                    </Column>
                    <Column>
                        <Label>{t('Venues.VenueView.VenueDetails.description_label')}</Label>
                        <Value>{venue.description}</Value>
                    </Column>
                    <Column>
                        <Label>
                            <FontAwesomeIcon icon={faGlobe} className="icon" />
                            {t('Venues.VenueView.VenueDetails.website_label')}
                        </Label>
                        <Value>
                            <a href={venue.website} target="_blank" rel="noopener noreferrer" className="link">
                                {venue.website}
                            </a>
                        </Value>
                    </Column>
                    <Column>
                        <Label>
                            <FontAwesomeIcon icon={faPhoneAlt} className="icon" />
                            {t('Venues.VenueView.VenueDetails.phone_label')}
                        </Label>
                        <Value>{venue.phone_country?.phonecode && `+${venue.phone_country.phonecode}`} {venue.phone}</Value>
                    </Column>
                    <Column>
                        <Label>
                            <FontAwesomeIcon icon={faMapMarkerAlt} className="icon" />
                            {t('Venues.VenueView.VenueDetails.address_label')}
                        </Label>
                        <Value>{venue.address}</Value>
                    </Column>
                    <Column>
                        <Label>{t('Venues.VenueView.VenueDetails.city_label')}</Label>
                        <Value>{venue.city}</Value>
                    </Column>
                    <Column>
                        <Label>{t('Venues.VenueView.VenueDetails.country_label')}</Label>
                        <Value>{venue.country?.name}</Value>
                    </Column>
                    <Column>
                        <Label>{t('Venues.VenueView.VenueDetails.latitude_label')}</Label>
                        <Value>{venue.latitude}</Value>
                    </Column>
                    <Column>
                        <Label>{t('Venues.VenueView.VenueDetails.longitude_label')}</Label>
                        <Value>{venue.longitude}</Value>
                    </Column>
                    <Column>
                        <Label>{t('Venues.VenueView.VenueDetails.organization_label')}</Label>
                        <Value>{venue.organization?.name}</Value>
                    </Column>
                </Row>

                <Divider />

                <SocialMedia>
                    <SectionTitle>{t('Venues.VenueView.VenueDetails.social_media')}</SectionTitle>
                    <SocialRow>
                        {socialLinks.twitter && (
                            <SocialColumn>
                                <FontAwesomeIcon icon={faTwitter} className="icon text-info" />
                                <a href={socialLinks.twitter} target="_blank" rel="noopener noreferrer" className="social-link">
                                    {t('Venues.VenueView.VenueDetails.twitter')}
                                </a>
                            </SocialColumn>
                        )}
                        {socialLinks.facebook && (
                            <SocialColumn>
                                <FontAwesomeIcon icon={faFacebook} className="icon text-primary" />
                                <a href={socialLinks.facebook} target="_blank" rel="noopener noreferrer" className="social-link">
                                    {t('Venues.VenueView.VenueDetails.facebook')}
                                </a>
                            </SocialColumn>
                        )}
                        {socialLinks.instagram && (
                            <SocialColumn>
                                <FontAwesomeIcon icon={faInstagram} className="icon text-warning" />
                                <a href={socialLinks.instagram} target="_blank" rel="noopener noreferrer" className="social-link">
                                    {t('Venues.VenueView.VenueDetails.instagram')}
                                </a>
                            </SocialColumn>
                        )}
                    </SocialRow>
                </SocialMedia>
            </Content>
        </Container>
    );
};

export default VenueDetails;

// Styled Components
const Container = styled.div`
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
`;

const Header = styled.div`
    border-bottom: 2px solid rgba(130, 22, 146, 0.2);
    padding-bottom: 10px;
    margin-bottom: 20px;
`;

const Title = styled.h3`
    color: rgba(130, 22, 146, 1);
    font-weight: bold;
`;

const Content = styled.div``;

const Row = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
`;

const Column = styled.div`
    flex: 1 1 calc(50% - 20px);
    min-width: 250px;
`;

const Label = styled.p`
    font-weight: bold;
    margin-bottom: 5px;
    display: flex;
    align-items: center;

    .icon {
        margin-right: 8px;
        color: rgba(130, 22, 146, 1);
    }
`;

const Value = styled.p`
    color: #555;
`;

const Divider = styled.hr`
    margin: 20px 0;
    border: none;
    border-top: 2px solid rgba(130, 22, 146, 0.1);
`;

const SocialMedia = styled.div``;

const SectionTitle = styled.h4`
    color: rgba(130, 22, 146, 1);
    font-weight: bold;
    margin-bottom: 15px;
`;

const SocialRow = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
`;

const SocialColumn = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;

    .icon {
        font-size: 1.5rem;
    }

    .social-link {
        color: inherit;
        text-decoration: none;

        &:hover {
            color: rgba(130, 22, 146, 1);
        }
    }
`;
