import React, { useEffect, useState, useContext } from 'react';
import ReactApexChart from 'react-apexcharts';
import { fetchDemographicsData } from '../../../services/Http/Api';
import { useTranslation } from 'react-i18next';
import { SettingsContext } from '../../SettingsContext';
import { MdHelpOutline } from 'react-icons/md';
import QuestionModal from "../../global/QuestionModal";
import styled from 'styled-components';

const TransactionsStatistics = ({ period }) => {
    const { t } = useTranslation();
    const { siteSettings } = useContext(SettingsContext);
    const [demographicsData, setDemographicsData] = useState({
        gender: {},
        ageGroups: {},
        cities: {},
        countries: {},
        residentialCountries: {}
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showQuestionModal, setShowQuestionModal] = useState(false);

    useEffect(() => {
        const loadDemographicsData = async () => {
            setLoading(true);
            try {
                const data = await fetchDemographicsData({ period });
                setDemographicsData({
                    gender: data.gender_summary,
                    ageGroups: data.age_group_summary,
                    cities: data.city_summary,
                    countries: data.country_summary,
                    residentialCountries: data.residential_country_summary
                });
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        loadDemographicsData();
    }, [period]);

    if (loading) {
        return <Loading>{t('Transactions.TransactionsStatistics.loading')}</Loading>;
    }

    if (error) {
        return <Error>{t('Transactions.TransactionsStatistics.error', { error: error.message })}</Error>;
    }

    const countries = siteSettings?.getAllCountries() || [];

    const genderChartData = {
        series: Object.values(demographicsData.gender),
        options: {
            chart: { type: 'pie', height: 300 },
            labels: Object.keys(demographicsData.gender),
            responsive: [{ breakpoint: 768, options: { chart: { height: 250 } } }],
        },
    };

    const ageGroupChartData = {
        series: [{ name: t('Transactions.TransactionsStatistics.ageGroupDistribution'), data: Object.values(demographicsData.ageGroups) }],
        options: {
            chart: { type: 'bar', height: 300 },
            xaxis: { categories: Object.keys(demographicsData.ageGroups) },
            responsive: [{ breakpoint: 768, options: { chart: { height: 250 } } }],
        },
    };

    const countryChartData = {
        series: Object.values(demographicsData.countries).filter(country => country !== undefined),
        options: {
            chart: { type: 'pie', height: 300 },
            labels: countries.filter(country => demographicsData.countries[country.id]).map(country => country.name),
            responsive: [{ breakpoint: 768, options: { chart: { height: 250 } } }],
        },
    };

    const residentialCountryChartData = {
        series: [{ name: t('Transactions.TransactionsStatistics.residentialCountryDistribution'), data: Object.values(demographicsData.residentialCountries).filter(country => country !== undefined) }],
        options: {
            chart: { type: 'bar', height: 300 },
            xaxis: { categories: countries.filter(country => demographicsData.residentialCountries[country.id]).map(country => country.name) },
            responsive: [{ breakpoint: 768, options: { chart: { height: 250 } } }],
        },
    };

    const cityChartData = {
        series: [{ name: t('Transactions.TransactionsStatistics.cityDistribution'), data: Object.values(demographicsData.cities) }],
        options: {
            chart: { type: 'bar', height: 300 },
            xaxis: { categories: Object.keys(demographicsData.cities) },
            responsive: [{ breakpoint: 768, options: { chart: { height: 250 } } }],
        },
    };

    return (
        <Container>
            <Header>
                <Title>{t('Transactions.TransactionsStatistics.title')}</Title>
                <IconButton onClick={() => setShowQuestionModal(true)}>
                    <MdHelpOutline className="info-icon" />
                </IconButton>
            </Header>

            <ChartsContainer>
                {genderChartData.series.length > 0 && (
                    <ChartBox>
                        <ChartTitle>{t('Transactions.TransactionsStatistics.genderDistribution')}</ChartTitle>
                        <ReactApexChart options={genderChartData.options} series={genderChartData.series} type="pie" height={300} />
                    </ChartBox>
                )}

                {countryChartData.series.length > 0 && (
                    <ChartBox>
                        <ChartTitle>{t('Transactions.TransactionsStatistics.countryDistribution')}</ChartTitle>
                        <ReactApexChart options={countryChartData.options} series={countryChartData.series} type="pie" height={300} />
                    </ChartBox>
                )}
            </ChartsContainer>

            {ageGroupChartData.series[0].data.length > 0 && (
                <ChartBoxFullWidth>
                    <ChartTitle>{t('Transactions.TransactionsStatistics.ageGroupDistribution')}</ChartTitle>
                    <ReactApexChart options={ageGroupChartData.options} series={ageGroupChartData.series} type="bar" height={300} />
                </ChartBoxFullWidth>
            )}

            {cityChartData.series[0].data.length > 0 && (
                <ChartBoxFullWidth>
                    <ChartTitle>{t('Transactions.TransactionsStatistics.cityDistribution')}</ChartTitle>
                    <ReactApexChart options={cityChartData.options} series={cityChartData.series} type="bar" height={300} />
                </ChartBoxFullWidth>
            )}

            {residentialCountryChartData.series[0].data.length > 0 && (
                <ChartBoxFullWidth>
                    <ChartTitle>{t('Transactions.TransactionsStatistics.residentialCountryDistribution')}</ChartTitle>
                    <ReactApexChart options={residentialCountryChartData.options} series={residentialCountryChartData.series} type="bar" height={300} />
                </ChartBoxFullWidth>
            )}

            <QuestionModal show={showQuestionModal} onClose={() => setShowQuestionModal(false)} param="transactions_statistics" />
        </Container>
    );
};

export default TransactionsStatistics;

// Styled Components
const Container = styled.div`
    padding: 20px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
`;

const Title = styled.h1`
    font-size: 1.5rem;
    color: rgba(130, 22, 146, 1);
`;

const IconButton = styled.button`
    background: none;
    border: none;
    color: rgba(130, 22, 146, 1);
    cursor: pointer;
    .info-icon {
        font-size: 1.5rem;
    }

    &:hover {
        color: rgba(110, 12, 136, 1);
    }
`;

const ChartsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 20px;
`;

const ChartBox = styled.div`
    flex: 1;
    min-width: 300px;
    background: #f8f9fa;
    border-radius: 10px;
    padding: 15px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const ChartBoxFullWidth = styled.div`
    width: 100%;
    background: #f8f9fa;
    border-radius: 10px;
    padding: 15px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
`;

const ChartTitle = styled.h3`
    font-size: 1.25rem;
    color: rgba(130, 22, 146, 1);
    margin-bottom: 10px;
`;

const Loading = styled.div`
    text-align: center;
    font-size: 1.2rem;
    color: rgba(130, 22, 146, 1);
`;

const Error = styled.div`
    text-align: center;
    font-size: 1.2rem;
    color: #d9534f;
`;
