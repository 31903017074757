import React from 'react';
import styled from 'styled-components';
import PromotionList from './PromotionList';

const PromotionsManagementContainer = styled.div`
    padding: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    border: 1px solid #ddd;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;

    h3 {
        color: #822292;
        font-weight: bold;
        margin-bottom: 20px;
        text-align: left;
    }

    .footer {
        display: flex;
        justify-content: flex-end;
        margin-top: 20px;

        button {
            background-color: #822292;
            color: #fff;
            border: none;
            padding: 10px 20px;
            border-radius: 6px;
            cursor: pointer;
            font-size: 16px;
            transition: all 0.3s ease;

            &:hover {
                background-color: #9b35a6;
            }
        }
    }
`;

const PromotionsManagement = ({ promotions, onSelectPromotion, onAddPromotion, onNext }) => {
    return (
        <PromotionsManagementContainer>
            <h3>Manage Promotions</h3>
            <PromotionList
                promotions={promotions}
                onSelectPromotion={onSelectPromotion}
                onAddPromotion={onAddPromotion}
            />
            <div className="footer">
                <button onClick={onNext}>Next: View Calculations</button>
            </div>
        </PromotionsManagementContainer>
    );
};

export default PromotionsManagement;
