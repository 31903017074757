import React, { useState } from 'react';
import styled from 'styled-components';
import GeneralSettings from './GeneralSettings';
import UserInfoSettings from './UserInfoSettings';
import SecuritySettings from './SecuritySettings';
import BillingSettings from './BillingSettings';

const SettingsPage = () => {
    const [activeTab, setActiveTab] = useState('general');

    const renderTabContent = () => {
        switch (activeTab) {
            case 'general':
                return <GeneralSettings />;
            case 'user-info':
                return <UserInfoSettings />;
            case 'security':
                return <SecuritySettings />;
            case 'billing':
                return <BillingSettings />;
            default:
                return <GeneralSettings />;
        }
    };

    return (
        <Container>
            <Header>Settings</Header>
            <TabContainer>
                <TabItem
                    active={activeTab === 'general'}
                    onClick={() => setActiveTab('general')}
                >
                    General
                </TabItem>
                <TabItem
                    active={activeTab === 'user-info'}
                    onClick={() => setActiveTab('user-info')}
                >
                    User Info
                </TabItem>
                <TabItem
                    active={activeTab === 'security'}
                    onClick={() => setActiveTab('security')}
                >
                    Security
                </TabItem>
                <TabItem
                    active={activeTab === 'billing'}
                    onClick={() => setActiveTab('billing')}
                >
                    Billing
                </TabItem>
            </TabContainer>
            <Content>{renderTabContent()}</Content>
        </Container>
    );
};

export default SettingsPage;

// Styled Components
const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    background-color: #f9f9f9;
    min-height: 100vh;
`;

const Header = styled.h1`
    color: rgba(130, 22, 146, 1);
    font-weight: bold;
    margin-bottom: 20px;
`;

const TabContainer = styled.div`
    display: flex;
    gap: 10px;
    border-bottom: 2px solid rgba(130, 22, 146, 0.2);
`;

const TabItem = styled.div`
    padding: 10px;
    cursor: pointer;
    font-weight: bold;
    color: ${({ active }) => (active ? 'rgba(130, 22, 146, 1)' : '#333')};
    border-bottom: ${({ active }) => (active ? '4px solid rgba(130, 22, 146, 1)' : 'none')};
    transition: color 0.3s ease, border-bottom 0.3s ease;

    &:hover {
        color: rgba(130, 22, 146, 0.8);
    }
`;

const Content = styled.div`
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
`;
