import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const PromotionListContainer = styled.div`
    padding: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    margin-bottom: 20px;

    h3 {
        color: rgba(130, 22, 146, 1); /* Brand color */
        font-weight: bold;
        margin-bottom: 15px;
        text-align: left;
        font-size: 1.2rem;
    }

    .add-button {
        background-color: rgba(130, 22, 146, 1); /* Brand purple */
        color: #fff;
        border: none;
        padding: 10px 20px;
        border-radius: 6px;
        font-size: 14px;
        font-weight: bold;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px; /* Space between icon and text */
        transition: background-color 0.3s ease, transform 0.2s;

        &:hover {
            background-color: rgba(130, 22, 146, 0.9); /* Slightly darker purple */
            transform: translateY(-2px); /* Adds a subtle lift effect */
        }

        &:active {
            background-color: rgba(130, 22, 146, 0.8);
            transform: translateY(0); /* Resets lift effect */
        }

        .icon {
            font-size: 18px;
        }
    }

    .list-group {
        list-style: none;
        padding: 0;
        margin: 0;
        border-top: 1px solid #ddd;
        margin-top: 15px;

        .list-group-item {
            padding: 12px 15px;
            border-bottom: 1px solid #ddd;
            cursor: pointer;
            font-size: 14px;
            color: #333;
            transition: all 0.3s ease;

            &:hover {
                background-color: #f8f9fa; /* Light grey for hover */
            }

            &.active {
                background-color: rgba(130, 22, 146, 0.1); /* Subtle purple background */
                color: rgba(130, 22, 146, 1); /* Brand purple for text */
                font-weight: bold;
                border-color: rgba(130, 22, 146, 0.2); /* Lighter purple border */
            }
        }
    }
`;

const PromotionList = ({ promotions, selectedPromotionIndex, onSelectPromotion, onAddPromotion }) => {
    const { t } = useTranslation();

    return (
        <PromotionListContainer>
            <h3>{t('Campaigns.PromotionList.promotionsList')}</h3>
            <button className="add-button mb-2" onClick={onAddPromotion}>
                <span className="icon">+</span>
                {t('Campaigns.PromotionList.addPromotion')}
            </button>
            <ul className="list-group">
                {promotions.map((promotion, index) => (
                    <li
                        key={index}
                        className={`list-group-item ${selectedPromotionIndex === index ? 'active' : ''}`}
                        onClick={() => onSelectPromotion(index)}
                    >
                        {promotion.name || t('Campaigns.PromotionList.promotion', { index: index + 1 })}
                    </li>
                ))}
            </ul>
        </PromotionListContainer>
    );
};

export default PromotionList;
