import React, { useEffect, useState } from 'react';
import {
    Button, Form, FormGroup, Label, Input, Row, Col, Spinner,
} from 'reactstrap';
import { fetchCampaign, updateCampaign, calculateCampaign } from "../../../services/Http/Api";
import { useParams } from "react-router-dom";
import PromotionList from "./PromotionList";
import PromotionEdit from "./PromotionEdit";
import Campaign from "../../../models/global/DataModels/Campaign/Campaign";
import { useTranslation } from 'react-i18next';
import QuestionModal from "../../global/QuestionModal";
import {Modal} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfoCircle, faQuestionCircle} from "@fortawesome/free-solid-svg-icons";
import StyledDetailsContainer from "../../../shared/layout/components/containers/StyledDetailsContainer";
import StyledPromotionsContainer from "../../../shared/layout/components/containers/StyledPromotionsContainer";
import {MdHelpOutline, MdInfoOutline} from "react-icons/md";
import styled from "styled-components";

const CampaignEdit = () => {
    const { id } = useParams();
    const { t } = useTranslation(); // Initialize translation hook
    const [campaign, setCampaign] = useState(null);
    const [selectedPromotionIndex, setSelectedPromotionIndex] = useState(0);
    const [loading, setLoading] = useState(true);
    const [updating, setUpdating] = useState(false);
    const [campaignJson, setCampaignJson] = useState(null);
    const [showQuestionModal, setShowQuestionModal] = useState(false); // State for question modal
    const [showTutorialModal, setShowTutorialModal] = useState(false); // State for tutorial modal
    const [activeTab, setActiveTab] = useState('edit');
    useEffect(() => {
        const loadCampaign = async () => {
            try {
                const campaignData = await fetchCampaign(id);
                setCampaign(new Campaign(campaignData));
            } catch (error) {
                console.error('Error loading campaign:', error);
            } finally {
                setLoading(false);
            }
        };

        loadCampaign();
    }, [id]);

    const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 15px;
    border-bottom: 2px solid rgba(130, 22, 146, 0.8);

    @media (max-width: 768px) {
        flex-direction: column;
        gap: 10px;
    }
`;

    const Title = styled.h1`
    color: rgba(130, 22, 146, 1);
    font-size: 1.8rem;
    font-weight: bold;

    @media (max-width: 768px) {
        font-size: 1.5rem;
    }
`;

    const ButtonGroup = styled.div`
    display: flex;
    gap: 10px;

    @media (max-width: 768px) {
        flex-wrap: wrap;
    }
`;

    const IconButton = styled.button`
    background-color: rgba(130, 22, 146, 0.1);
    color: rgba(130, 22, 146, 1);
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
        background-color: rgba(130, 22, 146, 0.2);
    }

    .info-icon {
        font-size: 1.5rem;
    }
`;

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCampaign((prev) => ({
            ...prev,
            [name]: value,
        }));
    };
    const handlePromotionSelection = (index) => {
        setSelectedPromotionIndex(index);
    };
    const handleAddPromotion = () => {
        setCampaign(prev => {
            const updatedCampaign = JSON.parse(JSON.stringify(prev));
            updatedCampaign.promotions.push({
                id: null, // If the id will be generated later, set it to null
                name: '', // Initialize all fields as empty strings or appropriate values
                description: '',
                startDate: '',
                endDate: '',
                daysOfWeek: [],
                startTime: '',
                endTime: '',
                discount: 0,
                pointsMultiplier: 0,
                active: true,
                conditions: [], // Initialize as empty array
                targets: [],
                incentives: [],
                strategies: []
            });
            return updatedCampaign;
        });
        setSelectedPromotionIndex(campaign.promotions.length);
    };

    const handlePromotionChange = (index, field, value) => {
        setCampaign((prev) => {
            const updatedPromotions = [...prev.promotions];
            updatedPromotions[index] = {
                ...updatedPromotions[index],
                [field]: value,
            };
            return {
                ...prev,
                promotions: updatedPromotions,
            };
        });
    };
    const handleDeletePromotion = (index) => {
        setCampaign((prev) => {
            const updatedPromotions = [...prev.promotions];
            updatedPromotions.splice(index, 1); // Remove the promotion at the specified index
            return {
                ...prev,
                promotions: updatedPromotions
            };
        });
    };

    const handleAddCondition = () => {
        setCampaign((prev) => {
            const updatedPromotions = [...prev.promotions];
            updatedPromotions[selectedPromotionIndex] = {
                ...updatedPromotions[selectedPromotionIndex],
                conditions: [
                    ...updatedPromotions[selectedPromotionIndex].conditions,
                    { /* default condition properties */ }
                ],
            };
            return {
                ...prev,
                promotions: updatedPromotions,
            };
        });
    };

    const handleAddTarget = () => {
        setCampaign((prev) => {
            const updatedPromotions = [...prev.promotions];
            updatedPromotions[selectedPromotionIndex].targets.push({});
            return { ...prev, promotions: updatedPromotions };
        });
    };

    const handleAddIncentive = () => {
        setCampaign((prev) => {
            const updatedPromotions = [...prev.promotions];
            updatedPromotions[selectedPromotionIndex].incentives.push({});
            return { ...prev, promotions: updatedPromotions };
        });
    };
    const handleAddStrategy = () => {
        setCampaign((prev) => {
            // Create a copy of promotions to avoid direct state mutation
            const updatedPromotions = [...prev.promotions];

            // Add a new strategy with fully initialized default values
            const defaultStrategy = {
                id: null, // Assuming it will be generated later
                promotionId: null, // Will be linked to the promotion later
                strategyTypeId: '', // Initialize as empty string or default type
                name: '', // Set to default name or empty string
                globalUsers: false, // Default value for whether it applies to global users
                frequencyCap: null, // Default value for frequency cap, initialize if required
                impressionLimit: null, // Default value for impression limit, initialize if required
                values: [] // Initialize empty values array for strategy attributes
            };

            updatedPromotions[selectedPromotionIndex].strategies.push(defaultStrategy);

            return { ...prev, promotions: updatedPromotions };
        });
    };

    const handleUpdateCampaign = async () => {
        setUpdating(true);
        try {
            await updateCampaign(id, campaign.toBackendFormat());
            alert(t('Campaigns.CampaignEdit.successUpdate'));
        } catch (error) {
            console.error('Error updating campaign:', error);
            alert(t('Campaigns.CampaignEdit.failedUpdate'));
        } finally {
            setUpdating(false);
        }
    };
    const handleCalculate = async () => {
        try {
            const calculatedData = await calculateCampaign(campaign);
            setCampaignJson(calculatedData); // No need to convert to JSON string here
        } catch (error) {
            console.error('Error calculating campaign:', error);
            alert(t('Campaigns.CampaignEdit.failedCalculation'));
        }
    };

    if (loading) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
                <Spinner color="primary" />
            </div>
        );
    }

    return (
        <CampaignContainer>
            <Header>
                <Title>{t('Campaigns.CampaignEdit.editCampaignTitle')}</Title>
                <ButtonGroup>
                    <IconButton onClick={() => setShowQuestionModal(true)}>
                        <MdHelpOutline />
                    </IconButton>
                </ButtonGroup>
            </Header>
            <Tabs>
                <Tab active={activeTab === 'edit'} onClick={() => setActiveTab('edit')}>
                    {t('Campaigns.CampaignEdit.editTab')}
                </Tab>
                <Tab active={activeTab === 'promotions'} onClick={() => setActiveTab('promotions')}>
                    {t('Campaigns.CampaignEdit.promotionsTab')}
                </Tab>
                <Tab active={activeTab === 'analysis'} onClick={() => setActiveTab('analysis')}>
                    {t('Campaigns.CampaignEdit.analysisTab')}
                </Tab>
            </Tabs>
            <Content>
                {activeTab === 'edit' && (
                    <StyledForm>
                        {["name", "description", "goalDescription", "budget", "startDate", "endDate"].map((field) => (
                            <StyledFormGroup key={field}>
                                <StyledLabel htmlFor={`campaign${field}`}>
                                    {t(`Campaigns.${field}`)}
                                </StyledLabel>
                                <StyledInput
                                    type={field === "budget" ? "number" : field.includes("Date") ? "date" : "text"}
                                    name={field}
                                    id={`campaign${field}`}
                                    value={campaign[field] || ''}
                                    onChange={handleInputChange}
                                    placeholder={t(`Campaigns.CampaignEdit.placeholder${field.charAt(0).toUpperCase() + field.slice(1)}`)}
                                />
                            </StyledFormGroup>
                        ))}
                    </StyledForm>
                )}
                {activeTab === 'promotions' && (
                    <StyledPromotionsContainer>
                        <div className="promotion-list">
                            <PromotionList
                                promotions={campaign.promotions}
                                selectedPromotionIndex={selectedPromotionIndex}
                                onSelectPromotion={handlePromotionSelection}
                                onAddPromotion={handleAddPromotion}
                                onDeletePromotion={handleDeletePromotion}
                            />
                        </div>
                        <div className="promotion-edit">
                            {campaign.promotions.length > 0 && (
                                <PromotionEdit
                                    key={selectedPromotionIndex}
                                    promotion={campaign.promotions[selectedPromotionIndex]}
                                    selectedPromotionIndex={selectedPromotionIndex}
                                    setCampaign={setCampaign}
                                    onUpdatePromotion={(field, value) => handlePromotionChange(field, value)}
                                    onAddCondition={handleAddCondition}
                                    onAddTarget={handleAddTarget}
                                    onAddIncentive={handleAddIncentive}
                                    onAddStrategy={handleAddStrategy}
                                />
                            )}
                        </div>
                    </StyledPromotionsContainer>
                )}
                {activeTab === 'analysis' && (
                    <Row className="mt-4">
                        <Col md={12}>
                            <Row className="mb-3">
                                <h4>{t('Campaigns.CampaignEdit.calculations')}</h4>
                            </Row>
                            <Row className="mb-3">
                                <Col md={6}><strong>{t('Campaigns.CampaignEdit.revenue')}: {campaignJson?.totalBudget || '-'}</strong></Col>
                                <Col md={6}><strong>{t('Campaigns.CampaignEdit.reach')}: {campaignJson?.totalReach || '-'}</strong></Col>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <Button color="primary" onClick={handleCalculate} disabled={updating}>
                                        {updating ? <Spinner size="sm" /> : t('Campaigns.CampaignEdit.calculate')}
                                    </Button>
                                </Col>
                                <Col md={4}>
                                    <Button color="primary" onClick={handleUpdateCampaign} disabled={updating}>
                                        {updating ? <Spinner size="sm" /> : t('Campaigns.CampaignEdit.updateCampaign')}
                                    </Button>
                                </Col>
                            </Row>
                            {campaignJson && (
                                <>
                                    {/* Reaches Section */}
                                    <Row className="mt-4">
                                        <Col>
                                            <StyledSection>
                                                <h6>{t('Campaigns.CampaignEdit.reaches')}</h6>
                                                {campaignJson.reaches.map((promotionReach, promotionIndex) => (
                                                    <div key={`reach_${promotionIndex}`} className="mb-3">
                                                        <strong>{t('Campaigns.CampaignEdit.promotion')} {promotionIndex + 1}:</strong>
                                                        <ul>
                                                            <li>{t('Campaigns.CampaignEdit.initialReach')}: {promotionReach.initialReach}</li>
                                                            {promotionReach.targets.map((target, targetIndex) => (
                                                                <li key={`target_reach_${targetIndex}`}>
                                                                    {t('Campaigns.CampaignEdit.target')} {targetIndex + 1} {t('Campaigns.CampaignEdit.reach')}: {target.reach}
                                                                </li>
                                                            ))}
                                                            {promotionReach.strategies.map((strategy, strategyIndex) => (
                                                                <li key={`strategy_reach_${strategyIndex}`}>
                                                                    {t('Campaigns.CampaignEdit.strategy')} {strategyIndex + 1} ({t('Campaigns.CampaignEdit.type')}: {strategy.strategyType}) {t('Campaigns.CampaignEdit.reach')}: {strategy.reach}
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                ))}
                                            </StyledSection>
                                        </Col>
                                    </Row>

                                    {/* Prices Section */}
                                    <Row className="mt-4">
                                        <Col>
                                            <StyledSection>
                                                <h6>{t('Campaigns.CampaignEdit.prices')}</h6>
                                                {campaignJson.prices.map((promotionPrice, promotionIndex) => (
                                                    <div key={`price_${promotionIndex}`} className="mb-3">
                                                        <strong>{t('Campaigns.CampaignEdit.promotion')} {promotionIndex + 1}:</strong>
                                                        <ul>
                                                            {promotionPrice.strategies.map((strategy, strategyIndex) => (
                                                                <li key={`strategy_price_${strategyIndex}`}>
                                                                    {t('Campaigns.CampaignEdit.strategy')} {strategyIndex + 1} ({t('Campaigns.CampaignEdit.type')}: {strategy.strategyType}) {t('Campaigns.CampaignEdit.price')}: ${strategy.price.toFixed(2)}
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                ))}
                                            </StyledSection>
                                        </Col>
                                    </Row>

                                    {/* Suggestions Section */}
                                    <Row className="mt-4">
                                        <Col>
                                            <StyledSection>
                                                <h6>{t('Campaigns.CampaignEdit.suggestions')}</h6>
                                                {campaignJson.suggestions.map((promotionSuggestions, promotionIndex) => (
                                                    <div key={`suggestions_${promotionIndex}`} className="mb-3">
                                                        <strong>{t('Campaigns.CampaignEdit.promotion')} {promotionIndex + 1}:</strong>
                                                        <ul>
                                                            {promotionSuggestions.length > 0 ? (
                                                                promotionSuggestions.map((suggestion, suggestionIndex) => (
                                                                    <li key={`suggestion_${suggestionIndex}`}>{suggestion}</li>
                                                                ))
                                                            ) : (
                                                                <li>{t('Campaigns.CampaignEdit.noSuggestions')}</li>
                                                            )}
                                                        </ul>
                                                    </div>
                                                ))}
                                            </StyledSection>
                                        </Col>
                                    </Row>
                                </>
                            )}
                        </Col>
                    </Row>

                )}
            </Content>
            <QuestionModal
                show={showQuestionModal}
                onClose={() => setShowQuestionModal(false)}
                param="campaigns_overview" // Pass the parameter for the question part
            />

            {/* Tutorial Modal */}
            <Modal show={showTutorialModal} onHide={() => setShowTutorialModal(false)} size="xl" centered>
                <div className="modal-header">
                    <h5 className="modal-title">{t('Campaigns.CampaignEdit.Tutorial.tutorialTitle')}</h5>
                    <button type="button" className="close" onClick={() => setShowTutorialModal(false)}>
                        <span>&times;</span>
                    </button>
                </div>
                <Modal.Body>
                    <div style={{ height: 'auto' }}>
                        <div className="welcome-message" style={{ marginBottom: '1.5rem' }}>
                            <p style={{ fontSize: '1.5rem', lineHeight: '1.6', color: 'rgba(130, 22, 146, 1)' }}>
                                {t('Campaigns.CampaignEdit.Tutorial.welcomeMessage')}
                            </p>
                        </div>

                        {/* Campaign Settings Overview */}
                        <div className="card" style={{ padding: '20px', marginBottom: '1.5rem', backgroundColor: '#f8f9fa', border: '1px solid rgba(0, 0, 0, 0.1)' }}>
                            <h5 style={{ fontSize: '1.25rem', marginBottom: '1rem', color: 'rgba(130, 22, 146, 1)' }}>
                                {t('Campaigns.CampaignEdit.Tutorial.campaignSettingsTitle')}
                            </h5>
                            <p style={{ fontSize: '1rem', lineHeight: '1.6', margin: 0 }}>
                                {t('Campaigns.CampaignEdit.Tutorial.campaignSettingsMessage')}
                            </p>
                        </div>

                        {/* Promotions List Section */}
                        <div className="card" style={{ padding: '20px', marginBottom: '1.5rem', backgroundColor: '#f8f9fa', border: '1px solid rgba(0, 0, 0, 0.1)' }}>
                            <h5 style={{ fontSize: '1.25rem', marginBottom: '1rem', color: 'rgba(130, 22, 146, 1)' }}>
                                {t('Campaigns.CampaignEdit.Tutorial.promotionsListTitle')}
                            </h5>
                            <p style={{ fontSize: '1rem', lineHeight: '1.6', margin: 0 }}>
                                {t('Campaigns.CampaignEdit.Tutorial.promotionsListMessage')}
                            </p>
                            <ul>
                                <li>{t('Campaigns.CampaignEdit.Tutorial.promotionEditInstruction')}</li>
                                <li>{t('Campaigns.CampaignEdit.Tutorial.addPromotionInstruction')}</li>
                            </ul>
                        </div>

                        {/* Conditions Section */}
                        <div className="card" style={{ padding: '20px', marginBottom: '1.5rem', backgroundColor: '#f8f9fa', border: '1px solid rgba(0, 0, 0, 0.1)' }}>
                            <h5 style={{ fontSize: '1.25rem', marginBottom: '1rem', color: 'rgba(130, 22, 146, 1)' }}>
                                {t('Campaigns.CampaignEdit.Tutorial.conditionsTitle')}
                            </h5>
                            <p style={{ fontSize: '1rem', lineHeight: '1.6', margin: 0 }}>
                                {t('Campaigns.CampaignEdit.Tutorial.conditionsMessage')}
                            </p>
                            <ul>
                                <li>{t('Campaigns.CampaignEdit.Tutorial.conditionsInstruction1')}</li>
                                <li>{t('Campaigns.CampaignEdit.Tutorial.conditionsInstruction2')}</li>
                            </ul>
                        </div>

                        {/* Strategies Section */}
                        <div className="card" style={{ padding: '20px', marginBottom: '1.5rem', backgroundColor: '#f8f9fa', border: '1px solid rgba(0, 0, 0, 0.1)' }}>
                            <h5 style={{ fontSize: '1.25rem', marginBottom: '1rem', color: 'rgba(130, 22, 146, 1)' }}>
                                {t('Campaigns.CampaignEdit.Tutorial.strategiesTitle')}
                            </h5>
                            <p style={{ fontSize: '1rem', lineHeight: '1.6', margin: 0 }}>
                                {t('Campaigns.CampaignEdit.Tutorial.strategiesMessage')}
                            </p>
                            <ul>
                                <li>{t('Campaigns.CampaignEdit.Tutorial.strategiesInstruction1')}</li>
                                <li>{t('Campaigns.CampaignEdit.Tutorial.strategiesInstruction2')}</li>
                            </ul>
                        </div>

                        {/* Targets Section */}
                        <div className="card" style={{ padding: '20px', marginBottom: '1.5rem', backgroundColor: '#f8f9fa', border: '1px solid rgba(0, 0, 0, 0.1)' }}>
                            <h5 style={{ fontSize: '1.25rem', marginBottom: '1rem', color: 'rgba(130, 22, 146, 1)' }}>
                                {t('Campaigns.CampaignEdit.Tutorial.targetsTitle')}
                            </h5>
                            <p style={{ fontSize: '1rem', lineHeight: '1.6', margin: 0 }}>
                                {t('Campaigns.CampaignEdit.Tutorial.targetsMessage')}
                            </p>
                            <ul>
                                <li>{t('Campaigns.CampaignEdit.Tutorial.targetsInstruction1')}</li>
                                <li>{t('Campaigns.CampaignEdit.Tutorial.targetsInstruction2')}</li>
                            </ul>
                        </div>

                        {/* Incentives Section */}
                        <div className="card" style={{ padding: '20px', marginBottom: '1.5rem', backgroundColor: '#f8f9fa', border: '1px solid rgba(0, 0, 0, 0.1)' }}>
                            <h5 style={{ fontSize: '1.25rem', marginBottom: '1rem', color: 'rgba(130, 22, 146, 1)' }}>
                                {t('Campaigns.CampaignEdit.Tutorial.incentivesTitle')}
                            </h5>
                            <p style={{ fontSize: '1rem', lineHeight: '1.6', margin: 0 }}>
                                {t('Campaigns.CampaignEdit.Tutorial.incentivesMessage')}
                            </p>
                            <ul>
                                <li>{t('Campaigns.CampaignEdit.Tutorial.incentivesInstruction1')}</li>
                                <li>{t('Campaigns.CampaignEdit.Tutorial.incentivesInstruction2')}</li>
                            </ul>
                        </div>

                        {/* Schedules Section */}
                        <div className="card" style={{ padding: '20px', marginBottom: '1.5rem', backgroundColor: '#f8f9fa', border: '1px solid rgba(0, 0, 0, 0.1)' }}>
                            <h5 style={{ fontSize: '1.25rem', marginBottom: '1rem', color: 'rgba(130, 22, 146, 1)' }}>
                                {t('Campaigns.CampaignEdit.Tutorial.schedulesTitle')}
                            </h5>
                            <p style={{ fontSize: '1rem', lineHeight: '1.6', margin: 0 }}>
                                {t('Campaigns.CampaignEdit.Tutorial.schedulesMessage')}
                            </p>
                            <ul>
                                <li>{t('Campaigns.CampaignEdit.Tutorial.schedulesInstruction1')}</li>
                                <li>{t('Campaigns.CampaignEdit.Tutorial.schedulesInstruction2')}</li>
                            </ul>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </CampaignContainer>
    );
};

export default CampaignEdit;

// Styled Components
const CampaignContainer = styled.div`
    padding: 20px;
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const Title = styled.h1`
    color: #820e92;
`;

const Tabs = styled.div`
    display: flex;
    margin-top: 20px;
`;

const Tab = styled.button`
    flex: 1;
    padding: 10px;
    border: none;
    background: ${({ active }) => (active ? '#fff' : 'transparent')};
    border-bottom: ${({ active }) => (active ? '4px solid #820e92' : 'none')};
    color: ${({ active }) => (active ? '#820e92' : '#333')};
    cursor: pointer;
`;

const Content = styled.div`
    margin-top: 20px;
`;

const StyledForm = styled.form`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

const StyledFormGroup = styled.div`
    display: flex;
    flex-direction: column;
`;

const StyledLabel = styled.label`
    font-size: 0.9rem;
    font-weight: bold;
    margin-bottom: 5px;
`;

const StyledInput = styled.input`
    padding: 10px;
    border: 1px solid ${({ isInvalid }) => (isInvalid ? 'red' : '#ccc')};
    border-radius: 5px;
    font-size: 1rem;
    outline: none;

    &:focus {
        border-color: rgba(130, 22, 146, 0.8);
        box-shadow: 0 0 5px rgba(130, 22, 146, 0.5);
    }
`;

const StyledFeedback = styled.span`
    font-size: 0.8rem;
    color: red;
    margin-top: 5px;
`;
const StyledSection = styled.div`
    margin-bottom: 20px;
    padding: 15px;
    border-radius: 8px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    h6 {
        font-weight: bold;
        color: rgba(130, 22, 146, 1); /* Brand color */
        margin-bottom: 15px;
    }

    ul {
        padding-left: 20px;
        list-style-type: disc;
    }

    li {
        margin-bottom: 8px;
        color: #333;
    }

    strong {
        display: block;
        margin-bottom: 10px;
        color: #444;
    }
`;
