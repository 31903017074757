import React from 'react';
import { LanguageButton } from '../buttons';
import styled from 'styled-components';

const LanguageSelector = ({ selectedLanguage, onLanguageChange }) => {
    return (
        <LanguageSelectorContainer>
            <LanguageButton
                selected={selectedLanguage === 'en'}
                onClick={() => onLanguageChange('en')}
                countryCode="GB"
            />
            <LanguageButton
                selected={selectedLanguage === 'hr'}
                onClick={() => onLanguageChange('hr')}
                countryCode="HR"
            />
        </LanguageSelectorContainer>
    );
};

const LanguageSelectorContainer = styled.div`
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-bottom: 20px;
`;

export default LanguageSelector;
