import React, { useEffect, useState } from 'react';
import { fetchOrganizations } from '../../services/Http/Api'; // Ensure path is correct

function WarrantiesTemplatesList() {

    return (
        <div>
            <h1>WarrnitiesTemplates</h1>
            <ul>
            </ul>
        </div>
    );
}

export default WarrantiesTemplatesList;
