import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const ForgotPasswordPage = () => {
    const { t } = useTranslation();
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setMessage('');
        setLoading(true);

        try {
            setMessage(t('forgotPasswordPage.reset_link_sent'));
            setTimeout(() => navigate('/login'), 3000); // Redirect to login page after a delay
        } catch (err) {
            setError(t('forgotPasswordPage.reset_link_error'));
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="forgot-password-container">
            <h2>{t('forgotPasswordPage.title')}</h2>
            <p>{t('forgotPasswordPage.instructions')}</p>

            {message && <div className="alert alert-success">{message}</div>}
            {error && <div className="alert alert-danger">{error}</div>}

            <form onSubmit={handleSubmit}>
                <div className="form-group mb-3">
                    <label>{t('forgotPasswordPage.email_label')}</label>
                    <input
                        type="email"
                        className="form-control"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </div>
                <button type="submit" className="btn btn-primary w-100" disabled={loading}>
                    {loading ? t('forgotPasswordPage.loading') : t('forgotPasswordPage.submit_button')}
                </button>
            </form>

            <button className="btn btn-link mt-3" onClick={() => navigate('/login')}>
                {t('forgotPasswordPage.back_to_login')}
            </button>
        </div>
    );
};

export default ForgotPasswordPage;
