import React from 'react';
import { Card, CardBody } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
    MdModelTraining,
    MdDescription,
    MdCalendarToday,
    MdAccessTime,
    MdVerified,
    MdLink,
} from 'react-icons/md';

const ItemDescription = ({ itemDetails }) => {
    const { t } = useTranslation();

    if (!itemDetails) return <div>{t('Items.ItemDescription.no_details')}</div>;

    // Parse technical specifications and append nested specifications
    const technicalSpecifications = itemDetails.technical_specifications
        ? JSON.parse(itemDetails.technical_specifications)
        : {};

    const fullSpecifications = {
        ...technicalSpecifications,
        ...(technicalSpecifications.specifications || {}),
    };
    delete fullSpecifications.specifications; // Remove nested object after merging

    const formatWarrantyDuration = (months) => {
        const years = Math.floor(months / 12);
        const remainingMonths = months % 12;
        const yearsDisplay = years > 0 ? `${years} ${t('Items.ItemDescription.year', { count: years })}` : '';
        const monthsDisplay = remainingMonths > 0 ? `${remainingMonths} ${t('Items.ItemDescription.month', { count: remainingMonths })}` : '';
        return `${yearsDisplay}${yearsDisplay && monthsDisplay ? ' ' : ''}${monthsDisplay}` || '0 months';
    };

    return (
        <Container>
            {/* General Information */}
            <InfoCard>
                <CardBody>
                    <Header>
                        <ProductImage src={itemDetails.image_url} alt={itemDetails.product_name} />
                        <div>
                            <ProductTitle>{itemDetails.product_name}</ProductTitle>
                            <ManufacturerSubtitle>{itemDetails.manufacturer.name}</ManufacturerSubtitle>
                        </div>
                    </Header>

                    <InfoGrid>
                        <InfoItem>
                            <MdModelTraining size={20} className="icon" />
                            <strong>{t('Items.ItemDescription.model_number')}:</strong>
                            <span>{itemDetails.model_number}</span>
                        </InfoItem>
                        <InfoItem>
                            <MdDescription size={20} className="icon" />
                            <strong>{t('Items.ItemDescription.description')}:</strong>
                            <span>{itemDetails.description || t('Items.ItemDescription.no_description')}</span>
                        </InfoItem>
                        <InfoItem>
                            <MdCalendarToday size={20} className="icon" />
                            <strong>{t('Items.ItemDescription.release_year')}:</strong>
                            <span>{itemDetails.release_year}</span>
                        </InfoItem>
                        <InfoItem>
                            <MdAccessTime size={20} className="icon" />
                            <strong>{t('Items.ItemDescription.warranty_duration')}:</strong>
                            <span>{formatWarrantyDuration(itemDetails.warranty_duration)}</span>
                        </InfoItem>
                        <InfoItem>
                            <MdVerified size={20} className="icon" />
                            <strong>{t('Items.ItemDescription.warranty_coverage')}:</strong>
                            <span>{itemDetails.warranty_coverage}</span>
                        </InfoItem>
                        <InfoItem>
                            <MdLink size={20} className="icon" />
                            <strong>{t('Items.ItemDescription.documentation_url')}:</strong>
                            <a href={itemDetails.documentation_url} target="_blank" rel="noopener noreferrer">
                                {t('Items.ItemDescription.view_documentation')}
                            </a>
                        </InfoItem>
                    </InfoGrid>
                </CardBody>
            </InfoCard>

            {/* Technical Specifications */}
            <SpecificationsCard>
                <CardBody>
                    <SpecificationsTitle>{t('Items.ItemDescription.technical_specifications')}</SpecificationsTitle>
                    {Object.keys(fullSpecifications).length > 0 ? (
                        <SpecificationsGrid>
                            {Object.entries(fullSpecifications).map(([key, value]) => (
                                <SpecificationItem key={key}>
                                    <strong>{key.replace(/_/g, ' ')}:</strong>
                                    <span>{value}</span>
                                </SpecificationItem>
                            ))}
                        </SpecificationsGrid>
                    ) : (
                        <NoSpecifications>{t('Items.ItemDescription.no_technical_specifications')}</NoSpecifications>
                    )}
                </CardBody>
            </SpecificationsCard>
        </Container>
    );
};

export default ItemDescription;

// Styled Components
const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

const InfoCard = styled(Card)`
    border: 1px solid rgba(130, 22, 146, 0.2);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const SpecificationsCard = styled(Card)`
    border: 1px solid rgba(130, 22, 146, 0.2);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const Header = styled.div`
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 20px;
`;

const ProductImage = styled.img`
    max-width: 150px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
`;

const ProductTitle = styled.h3`
    color: rgba(130, 22, 146, 1);
    font-weight: bold;
    margin: 0;
`;

const ManufacturerSubtitle = styled.h5`
    color: #666;
    margin: 0;
`;

const InfoGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 15px;
`;

const InfoItem = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;

    .icon {
        color: rgba(130, 22, 146, 1);
    }

    strong {
        font-weight: bold;
    }

    span {
        flex: 1;
        text-align: left;
    }
`;

const SpecificationsTitle = styled.h4`
    color: rgba(130, 22, 146, 1);
    font-weight: bold;
    margin-bottom: 15px;
`;

const SpecificationsGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 15px;
`;

const SpecificationItem = styled.div`
    background-color: #f8f9fa;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    strong {
        display: block;
        margin-bottom: 5px;
        font-weight: bold;
    }
`;

const NoSpecifications = styled.div`
    text-align: center;
    color: #999;
    font-style: italic;
`;
