import React, { useState } from 'react';
import {
    TabContent,
    TabPane,
    Spinner, Modal,
} from 'reactstrap';
import styled from 'styled-components';
import DataTable from 'react-data-table-component';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import QuestionModal from "../global/QuestionModal";

const WarrantiesList = () => {
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState('1');
    const [loading, setLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [showTemplateModal, setShowTemplateModal] = useState(false);
    const [newTemplateName, setNewTemplateName] = useState('');
    const [newTemplateDescription, setNewTemplateDescription] = useState('');
    const [selectedManufacturer, setSelectedManufacturer] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [showQuestionModal, setShowQuestionModal] = useState(false);
    const [showTutorialModal, setShowTutorialModal] = useState(false);

    const warranties = [
        {
            id: 1,
            name: 'Standard Warranty',
            status: 'Active',
            expirationDate: '2025-12-31',
            coverageDetails: 'Covers manufacturing defects.',
            termsAndConditions: 'Must provide proof of purchase.',
            documentationUrl: 'http://example.com/standard-warranty',
        },
        {
            id: 2,
            name: 'Extended Warranty',
            status: 'Active',
            expirationDate: '2026-06-30',
            coverageDetails: 'Covers all parts and labor for an additional year.',
            termsAndConditions: 'No accidental damage coverage.',
            documentationUrl: 'http://example.com/extended-warranty',
        },
    ];

    const toggleTab = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const columns = [
        { name: t('Warranties.warrantyName'), selector: row => row.name, sortable: true },
        { name: t('Warranties.status'), selector: row => row.status, sortable: true },
        { name: t('Warranties.expirationDate'), selector: row => row.expirationDate, sortable: true },
        { name: t('Warranties.coverageDetails'), selector: row => row.coverageDetails, sortable: true },
        { name: t('Warranties.termsAndConditions'), selector: row => row.termsAndConditions, sortable: true },
        { name: t('Warranties.documentation'), cell: row => <a href={row.documentationUrl} target="_blank" rel="noopener noreferrer">{t('Warranties.view')}</a> }
    ];

    const filteredWarranties = warranties.filter(warranty =>
        warranty.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <Container>
            {/* Navigation Tabs */}
            <TabContainer>
                <TabItem
                    active={activeTab === '1'}
                    onClick={() => toggleTab('1')}
                >
                    {t('Warranties.warrantiesOverview')}
                </TabItem>
                <TabItem
                    active={activeTab === '2'}
                    onClick={() => toggleTab('2')}
                >
                    {t('Warranties.searchWarranties')}
                </TabItem>
            </TabContainer>

            {/* Action Buttons */}
            <ActionButtons>
                <IconButton onClick={() => setShowQuestionModal(true)}>
                    <FontAwesomeIcon icon={faQuestionCircle} />
                </IconButton>
                <IconButton onClick={() => setShowTutorialModal(true)}>
                    <FontAwesomeIcon icon={faInfoCircle} />
                </IconButton>
            </ActionButtons>

            {/* Tab Content */}
            <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                    <CardStyled>
                        {loading ? (
                            <Spinner color="primary" />
                        ) : (
                            <DataTable
                                columns={columns}
                                data={filteredWarranties}
                                pagination
                                highlightOnHover
                                noDataComponent={<NoData>{t('Warranties.noWarranties')}</NoData>}
                            />
                        )}
                    </CardStyled>
                </TabPane>

                <TabPane tabId="2">
                    <CardStyled>
                        <InputStyled
                            type="text"
                            value={searchTerm}
                            onChange={handleSearchChange}
                            placeholder={t('Warranties.enterWarrantyName')}
                        />
                        <DataTable
                            columns={columns}
                            data={filteredWarranties}
                            pagination
                            highlightOnHover
                            noDataComponent={<NoData>{t('Warranties.noWarranties')}</NoData>}
                        />
                    </CardStyled>
                </TabPane>
            </TabContent>

            {/* Question Modal */}
            <QuestionModal
                show={showQuestionModal}
                onClose={() => setShowQuestionModal(false)}
                param="warranties_overview"
            />

            {/* Tutorial Modal */}
            <Modal show={showTutorialModal} onHide={() => setShowTutorialModal(false)} size="xl" centered>
                <ModalHeader>
                    <h5>{t('Warranties.tutorialTitle')}</h5>
                    <CloseButton onClick={() => setShowTutorialModal(false)}>&times;</CloseButton>
                </ModalHeader>
                <ModalBody>
                    {t('Warranties.tutorialContent')}
                </ModalBody>
            </Modal>
        </Container>
    );
};

export default WarrantiesList;

// Styled Components
const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
`;

const TabContainer = styled.div`
    display: flex;
    gap: 10px;
    border-bottom: 2px solid rgba(130, 22, 146, 0.2);
`;

const TabItem = styled.div`
    padding: 10px;
    cursor: pointer;
    font-weight: bold;
    color: ${({ active }) => (active ? 'rgba(130, 22, 146, 1)' : '#333')};
    border-bottom: ${({ active }) => (active ? '4px solid rgba(130, 22, 146, 1)' : 'none')};

    &:hover {
        color: rgba(130, 22, 146, 0.8);
    }
`;

const ActionButtons = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 10px;
`;

const IconButton = styled.button`
    background-color: rgba(130, 22, 146, 0.1);
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: rgba(130, 22, 146, 1);

    &:hover {
        background-color: rgba(130, 22, 146, 0.2);
    }
`;

const CardStyled = styled.div`
    background-color: #fff;
    border: 1px solid rgba(130, 22, 146, 0.2);
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const InputStyled = styled.input`
    padding: 10px;
    border: 1px solid rgba(130, 22, 146, 0.4);
    border-radius: 5px;
    width: 100%;
    margin-bottom: 20px;
    &:focus {
        outline: none;
        border-color: rgba(130, 22, 146, 1);
    }
`;

const NoData = styled.div`
    text-align: center;
    color: #999;
    font-style: italic;
    padding: 20px;
`;

const ModalHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    background-color: rgba(130, 22, 146, 0.1);
    border-bottom: 1px solid rgba(130, 22, 146, 0.2);
`;

const ModalBody = styled.div`
    padding: 20px;
    color: #333;
    font-size: 1rem;
    line-height: 1.5;
`;

const CloseButton = styled.button`
    background: none;
    border: none;
    font-size: 1.5rem;
    color: #333;
    cursor: pointer;

    &:hover {
        color: rgba(130, 22, 146, 1);
    }
`;
