const theme = {
    colors: {
        // General Colors
        brand: 'rgba(130, 22, 146, 1)', // Primary brand color
        brandLight: 'rgba(130, 22, 146, 0.8)', // Light variant of brand color
        brandLighter: 'rgba(130, 22, 146, 0.2)', // Lighter variant
        brandBackground: 'rgba(130, 22, 146, 0.1)', // Background variant
        textPrimary: '#333', // Primary text color
        textSecondary: '#666', // Secondary text color

        // Specific Context Colors
        lightGreen: '#4caf50', // Represents growth or success
        vibrantOrange: '#ff9800', // Represents energy or progress
        freshGreen: '#18de15', // Represents new users or activity
        deepPurple: '#9c27b0', // Represents campaigns or innovation
        alertRed: '#f44336', // Represents manufacturers or warnings
        shadedBlue: '#3f51b5', // Represents transactions or reliability
        boldOrange: '#ff5722', // Represents selling or actions
        earthyBrown: '#795548', // Represents inventory or stability
        limeGreen: '#8bc34a', // Represents transaction volume or freshness
        skyBlue: '#2196f3', // Represents users or calmness
        slateGray: '#607d8b', // Represents venues or neutrality
        neutralGray: '#9e9e9e', // Default fallback color
    },
    spacing: {
        small: '10px',
        medium: '20px',
        large: '40px',
    },
    fontSizes: {
        small: '0.9rem',
        medium: '1.1rem',
        large: '1.5rem',
        extraLarge: '1.8rem',
    },
};

export default theme;
