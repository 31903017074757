import React, { useState, useEffect } from 'react';
import { fetchUsersStatistics } from '../../../services/Http/Api';
import SalesByCountries from './components/SalesByCountries';
import {
    MdPeople,
    MdPerson,
    MdPublic,
    MdAttachMoney,
    MdAccountBalanceWallet,
    MdHelpOutline,
    MdAccountCircle
} from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import QuestionModal from "../../global/QuestionModal";
import styled from 'styled-components';

const Statistics = () => {
    const { t } = useTranslation();
    const [statistics, setStatistics] = useState(null);
    const [loading, setLoading] = useState(true);
    const [showQuestionModal, setShowQuestionModal] = useState(false);

    useEffect(() => {
        const getStatistics = async () => {
            try {
                const data = await fetchUsersStatistics();
                setStatistics(data && Object.keys(data).length > 0 ? data : null);
            } catch (error) {
                console.error('Error fetching statistics:', error);
            } finally {
                setLoading(false);
            }
        };

        getStatistics();
    }, []);

    if (loading) return <p>{t('Users.UserStatistics.loading')}</p>;
    if (!statistics) return <p>{t('Users.UserStatistics.noStatistics')}</p>;

    const handleShowQuestionModal = () => setShowQuestionModal(true);
    const handleCloseQuestionModal = () => setShowQuestionModal(false);

    return (
        <StatisticsContainer>
            <Header>
                <Title>{t('Users.UserStatistics.overviewTitle')}</Title>
                <IconButton onClick={handleShowQuestionModal}>
                    <MdHelpOutline className="info-icon" />
                </IconButton>
            </Header>

            <StatsGrid>
                <StatCard color="primary">
                    <MdPeople size={30} className="icon" />
                    <StatContent>
                        <StatTitle>{t('Users.UserStatistics.totalBuyers')}</StatTitle>
                        <StatValue>{statistics.total_buyers.toLocaleString()}</StatValue>
                    </StatContent>
                </StatCard>

                <StatCard color="success">
                    <MdPerson size={30} className="icon" />
                    <StatContent>
                        <StatTitle>{t('Users.UserStatistics.totalBuyersOwn')}</StatTitle>
                        <StatValue>{statistics.total_buyers_own.toLocaleString()}</StatValue>
                    </StatContent>
                </StatCard>

                <StatCard color="info">
                    <MdPublic size={30} className="icon" />
                    <StatContent>
                        <StatTitle>{t('Users.UserStatistics.totalBuyersGlobal')}</StatTitle>
                        <StatValue>{statistics.total_buyers_global.toLocaleString()}</StatValue>
                    </StatContent>
                </StatCard>

                <StatCard color="warning">
                    <MdAttachMoney size={30} className="icon" />
                    <StatContent>
                        <StatTitle>{t('Users.UserStatistics.totalRevenueOwn')}</StatTitle>
                        <StatValue>${parseFloat(statistics.total_revenue_own).toLocaleString()}</StatValue>
                    </StatContent>
                </StatCard>

                <StatCard color="danger">
                    <MdAccountBalanceWallet size={30} className="icon" />
                    <StatContent>
                        <StatTitle>{t('Users.UserStatistics.totalRevenueGlobal')}</StatTitle>
                        <StatValue>${parseFloat(statistics.total_revenue_global).toLocaleString()}</StatValue>
                    </StatContent>
                </StatCard>
            </StatsGrid>

            <Row>
                <Column>
                    <SalesByCountries data={statistics.summary_by_countries} />
                </Column>

                <Column>
                    <StyledCard>
                        <CardHeader>
                            <CardTitle>{t('Users.UserStatistics.topBuyersByItems')}</CardTitle>
                        </CardHeader>
                        <CardBody>
                            <ListGroup>
                                {statistics.top_buyers_by_items.map((buyer, index) => (
                                    <ListItem key={index}>
                                        <ProfileContainer>
                                            <MdAccountCircle size={40} color="rgba(130, 22, 146, 0.8)" />
                                            <ProfileName>{buyer.user_name} {buyer.user_surname}</ProfileName>
                                        </ProfileContainer>
                                        <ItemCount>{buyer.count_of_items} {t('Users.UserStatistics.items')}</ItemCount>
                                        <StyledBadge ownBuyer={buyer.own_buyer}>
                                            {buyer.own_buyer ? t('Users.UserStatistics.own') : t('Users.UserStatistics.global')}
                                        </StyledBadge>
                                    </ListItem>
                                ))}
                            </ListGroup>
                        </CardBody>
                    </StyledCard>
                </Column>
            </Row>

            <Row>
                <HalfColumn>
                    <StyledCard>
                        <CardHeader>
                            <CardTitle>{t('Users.UserStatistics.topBuyersByTotalSpent')}</CardTitle>
                        </CardHeader>
                        <CardBody>
                            <ListGroup>
                                {statistics.top_buyers_by_total_spent.map((buyer, index) => (
                                    <ListItem key={index}>
                                        <ProfileContainer>
                                            <MdAccountCircle size={40} color="rgba(130, 22, 146, 0.8)" />
                                            <ProfileName>{buyer.user_name} {buyer.user_surname}</ProfileName>
                                        </ProfileContainer>
                                        <ItemCount>${parseFloat(buyer.total_spent).toLocaleString()}</ItemCount>
                                        <StyledBadge ownBuyer={buyer.own_buyer}>
                                            {buyer.own_buyer ? t('Users.UserStatistics.own') : t('Users.UserStatistics.global')}
                                        </StyledBadge>
                                    </ListItem>
                                ))}
                            </ListGroup>
                        </CardBody>
                    </StyledCard>
                </HalfColumn>
            </Row>

            <QuestionModal
                show={showQuestionModal}
                onClose={handleCloseQuestionModal}
                param="users_statistics"
            />
        </StatisticsContainer>
    );
};

export default Statistics;

// Styled Components
const StatisticsContainer = styled.div`
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

    @media (max-width: 768px) {
        padding: 10px;
    }
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    @media (max-width: 768px) {
        flex-direction: column;
        gap: 10px;
    }
`;

const Title = styled.h3`
    color: rgba(130, 22, 146, 1);
    font-size: 1.5rem;
    font-weight: bold;

    @media (max-width: 768px) {
        font-size: 1.2rem;
    }
`;

const StatsGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
    gap: 15px;

    @media (max-width: 768px) {
        gap: 10px;
    }
`;

const StatCard = styled.div`
    display: flex;
    align-items: center;
    padding: 15px;
    background: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-left: 4px solid ${({ color }) => getColor(color)};

    .icon {
        font-size: 2rem;
        color: ${({ color }) => getColor(color)};
        margin-right: 15px;
    }

    @media (max-width: 768px) {
        padding: 10px;
        .icon {
            font-size: 1.5rem;
        }
    }
`;

const getColor = (color) => {
    switch (color) {
        case 'primary':
            return '#007bff';
        case 'success':
            return '#28a745';
        case 'info':
            return '#17a2b8';
        case 'warning':
            return '#ffc107';
        case 'danger':
            return '#dc3545';
        default:
            return '#333';
    }
};

const Row = styled.div`
    display: flex;
    gap: 20px;
    margin-top: 20px;

    @media (max-width: 768px) {
        flex-direction: column;
        gap: 15px;
    }
`;

const Column = styled.div`
    flex: 1;

    @media (max-width: 768px) {
        width: 100%;
    }
`;

const HalfColumn = styled.div`
    flex: 0.5;

    @media (max-width: 768px) {
        flex: 1;
    }
`;

const StyledCard = styled.div`
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    overflow: hidden;

    @media (max-width: 768px) {
        padding: 10px;
    }
`;

const CardHeader = styled.div`
    padding: 15px 20px;
    background-color: rgba(130, 22, 146, 0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 768px) {
        padding: 10px;
    }
`;

const CardTitle = styled.h5`
    margin: 0;
    color: rgba(130, 22, 146, 1);
    font-weight: bold;

    @media (max-width: 768px) {
        font-size: 1rem;
    }
`;

const CardBody = styled.div`
    padding: 20px;
    flex-grow: 1;

    @media (max-width: 768px) {
        padding: 10px;
    }
`;

const ListGroup = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;

    @media (max-width: 768px) {
        padding: 5px;
    }
`;

const ListItem = styled.li`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid #e0e0e0;

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
        gap: 5px;
    }
`;

const ProfileContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;

    @media (max-width: 768px) {
        gap: 5px;
    }
`;

const ProfileName = styled.span`
    font-size: 1rem;
    font-weight: bold;
    color: #333;

    @media (max-width: 768px) {
        font-size: 0.9rem;
    }
`;

const ItemCount = styled.span`
    font-size: 1rem;
    color: #555;

    @media (max-width: 768px) {
        font-size: 0.85rem;
    }
`;

const StyledBadge = styled.span`
    padding: 6px 12px;
    border-radius: 12px;
    font-weight: bold;
    font-size: 0.85rem;
    color: #fff;
    background-color: ${({ ownbuyer }) => (ownbuyer ? '#28a745' : '#007bff')};
    text-transform: uppercase;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    @media (max-width: 768px) {
        padding: 4px 8px;
        font-size: 0.75rem;
    }
`;
const StatContent = styled.div`
    display: flex;
    flex-direction: column;
`;

const StatTitle = styled.h5`
    font-size: 1.1rem;
    color: rgba(130, 22, 146, 0.8);
`;

const StatValue = styled.p`
    font-size: 1.3rem;
    font-weight: bold;
`;
const IconButton = styled.button`
    background: none;
    border: none;
    color: rgba(130, 22, 146, 1);
    cursor: pointer;
    .info-icon {
        font-size: 1.5rem;
    }
`;
