import React from 'react';
import styled from 'styled-components';
import { FormGroup, Label, Input } from 'reactstrap';

const PromotionSettingsContainer = styled.div`
    padding: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    h5 {
        color: #822292;
        font-weight: bold;
        margin-bottom: 20px;
        text-align: left;
    }

    .form-group {
        margin-bottom: 15px;

        label {
            font-weight: 500;
            color: #333;
        }

        input, textarea {
            padding: 10px;
            border: 1px solid #ddd;
            border-radius: 6px;
            font-size: 14px;
            transition: border-color 0.3s ease;

            &:focus {
                border-color: #822292;
                outline: none;
            }
        }
    }

    .checkbox-group {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;

        label {
            display: flex;
            align-items: center;
            font-weight: 500;

            input {
                margin-right: 8px;
            }
        }
    }
`;
const StyledInput = styled.input`
    padding: 10px;
    border: 1px solid ${({ isInvalid }) => (isInvalid ? 'red' : '#ccc')};
    border-radius: 5px;
    font-size: 1rem;
    outline: none;

    &:focus {
        border-color: rgba(130, 22, 146, 0.8);
        box-shadow: 0 0 5px rgba(130, 22, 146, 0.5);
    }
`;
const StyledTextarea = styled.textarea`
    padding: 10px;
    border: 1px solid ${({ isInvalid }) => (isInvalid ? 'red' : '#ccc')};
    border-radius: 5px;
    font-size: 1rem;
    outline: none;
    resize: vertical;

    &:focus {
        border-color: rgba(130, 22, 146, 0.8);
        box-shadow: 0 0 5px rgba(130, 22, 146, 0.5);
    }
`;
const StyledForm = styled.form`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

const StyledFormGroup = styled.div`
    display: flex;
    flex-direction: column;
`;

const StyledLabel = styled.label`
    font-size: 0.9rem;
    font-weight: bold;
    margin-bottom: 5px;
`;
const PromotionSettings = ({ promotion, onChange }) => {
    const daysOfWeek = [
        { label: 'Sunday', value: 'Sunday' },
        { label: 'Monday', value: 'Monday' },
        { label: 'Tuesday', value: 'Tuesday' },
        { label: 'Wednesday', value: 'Wednesday' },
        { label: 'Thursday', value: 'Thursday' },
        { label: 'Friday', value: 'Friday' },
        { label: 'Saturday', value: 'Saturday' }
    ];

    // Check if all days are selected
    const isEveryday = promotion.daysOfWeek?.length === 7;

    const handleInputChange = (field, value) => {
        onChange(field, value);
    };

    const handleDayChange = (day, isChecked) => {
        let updatedDaysOfWeek = [...(promotion.daysOfWeek || [])];

        if (day === 'Everyday') {
            updatedDaysOfWeek = isChecked ? daysOfWeek.map(d => d.value) : [];
        } else {
            if (isChecked) {
                updatedDaysOfWeek.push(day);
            } else {
                updatedDaysOfWeek = updatedDaysOfWeek.filter(d => d !== day);
            }
        }

        handleInputChange('daysOfWeek', updatedDaysOfWeek);
    };

    return (
        <PromotionSettingsContainer>
            <h5>Promotion Settings</h5>

            <StyledFormGroup className="form-group">
                <StyledLabel for="promotionName">Promotion Name</StyledLabel>
                <StyledInput
                    type="text"
                    id="promotionName"
                    value={promotion.name || ''}
                    onChange={(e) => handleInputChange('name', e.target.value)}
                    placeholder="Enter promotion name"
                />
            </StyledFormGroup>

            <StyledFormGroup className="form-group">
                <StyledLabel for="description">Description</StyledLabel>
                <StyledInput
                    type="textarea"
                    id="description"
                    value={promotion.description || ''}
                    onChange={(e) => handleInputChange('description', e.target.value)}
                    placeholder="Enter promotion description"
                />
            </StyledFormGroup>

            <StyledFormGroup className="form-group">
                <StyledLabel>Days of the Week</StyledLabel>
                <div className="checkbox-group">
                    <StyledLabel check>
                        <Input
                            type="checkbox"
                            checked={isEveryday}
                            onChange={(e) => handleDayChange('Everyday', e.target.checked)}
                        />
                        Everyday
                    </StyledLabel>
                    {daysOfWeek.map((day, index) => (
                        <StyledLabel check key={index}>
                            <Input
                                type="checkbox"
                                checked={promotion.daysOfWeek?.includes(day.value)}
                                onChange={(e) => handleDayChange(day.value, e.target.checked)}
                            />
                            {day.label}
                        </StyledLabel>
                    ))}
                </div>
            </StyledFormGroup>

            <StyledFormGroup className="form-group">
                <StyledLabel for="startTime">Start Time</StyledLabel>
                <StyledInput
                    type="time"
                    id="startTime"
                    value={promotion.startTime || ''}
                    onChange={(e) => handleInputChange('startTime', e.target.value)}
                />
            </StyledFormGroup>

            <StyledFormGroup className="form-group">
                <StyledLabel for="endTime">End Time</StyledLabel>
                <StyledInput
                    type="time"
                    id="endTime"
                    value={promotion.endTime || ''}
                    onChange={(e) => handleInputChange('endTime', e.target.value)}
                />
            </StyledFormGroup>

            <StyledFormGroup className="form-group">
                <StyledLabel for="discount">Discount</StyledLabel>
                <StyledInput
                    type="number"
                    id="discount"
                    value={promotion.discount || ''}
                    onChange={(e) => handleInputChange('discount', e.target.value)}
                    placeholder="Enter discount percentage"
                />
            </StyledFormGroup>
        </PromotionSettingsContainer>
    );
};

export default PromotionSettings;
