import React, { useState } from 'react';
import styled from 'styled-components';
import { Form, FormGroup, Label, Input, Button, Spinner } from 'reactstrap';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
const CampaignDetailsContainer = styled.div`
    padding: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    width: 100%;
    margin: 0;
    border: 1px solid #ddd;

    h3 {
        color: #822292;
        text-align: center; /* Center heading */
        margin-bottom: 20px;
        font-weight: bold;
    }

    .form-group {
        margin-bottom: 20px;
    }

    label {
        font-weight: 500;
        color: #333;
        margin-bottom: 8px;
    }

    input {
        padding: 10px;
        border: 1px solid #ddd;
        border-radius: 6px;
        font-size: 16px;
        transition: all 0.3s ease;

        &:focus {
            border-color: #822292;
            box-shadow: 0 0 5px rgba(130, 34, 146, 0.3);
            outline: none;
        }
    }

    .form-footer {
        display: flex;
        justify-content: center; /* Center buttons horizontally */
        align-items: center;
        margin-top: 20px;
        gap: 15px; /* Add spacing between buttons */

        button {
            padding: 10px 20px;
            border-radius: 8px;
            font-size: 16px;
            cursor: pointer;
            transition: all 0.3s ease;
            font-weight: 500;

            &.btn-primary {
                background-color: #822292;
                color: #ffffff;

                &:hover {
                    background-color: #9b35a6;
                }
            }

            &.btn-secondary {
                background-color: #ddd;
                color: #333;

                &:hover {
                    background-color: #bbb;
                }
            }

            svg {
                margin-right: 8px;
            }
        }
    }

    @media (max-width: 768px) {
        padding: 15px;

        .form-footer {
            flex-wrap: wrap;
            gap: 10px;
        }

        button {
            width: auto;
        }
    }
`;

const CampaignDetails = ({ campaign, setCampaign, onNext, onBack }) => {
    const { t } = useTranslation();
    const [updating, setUpdating] = useState(false);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCampaign((prev) => ({
            ...prev,
            [name]: name.includes('Date') ? moment(value).toISOString() : value,
        }));
    };

    const handleUpdateCampaign = async () => {
        setUpdating(true);
        try {
            alert(t('Campaign updated successfully!'));
        } catch (error) {
            console.error('Error updating campaign:', error);
        } finally {
            setUpdating(false);
        }
    };

    const formatDateForInput = (date) => {
        return date ? moment(date).format('YYYY-MM-DD') : '';
    };

    return (
        <CampaignDetailsContainer>
            <h3>{t('Edit Campaign Details')}</h3>
            <Form>
                {['name', 'description', 'goalDescription', 'budget', 'startDate', 'endDate'].map((field) => (
                    <FormGroup className="form-group" key={field}>
                        <Label for={`campaign${field}`}>{t(`Campaigns.${field}`)}</Label>
                        <Input
                            type={field === 'budget' ? 'number' : field.includes('Date') ? 'date' : 'text'}
                            name={field}
                            id={`campaign${field}`}
                            value={
                                field.includes('Date')
                                    ? formatDateForInput(campaign[field])
                                    : campaign[field] || ''
                            }
                            onChange={handleInputChange}
                            placeholder={t(
                                `Campaigns.CampaignEdit.placeholder${field.charAt(0).toUpperCase() + field.slice(1)}`
                            )}
                        />
                    </FormGroup>
                ))}
            </Form>
            <div className="form-footer">
                <Button className="btn-primary" onClick={onBack}>
                    {t('Back')}
                </Button>
                <Button className="btn-primary" onClick={handleUpdateCampaign} disabled={updating}>
                    {updating ? <Spinner size="sm" /> : t('Update')}
                </Button>
                <Button className="btn-primary" onClick={onNext}>
                    {t('Next')}
                </Button>
            </div>
        </CampaignDetailsContainer>
    );
};

export default CampaignDetails;

