import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import UserCreate from "./UserCreate";

const UserModal = ({ show, onClose, onUserAdded }) => {
    const { t } = useTranslation();

    if (!show) return null;

    return (
        <StyledModal>
            <Backdrop onClick={onClose} />
            <ModalContent>
                <ModalHeader>
                    <ModalTitle>{t('Users.UsersList.modalTitle')}</ModalTitle>
                    <CloseButton onClick={onClose}>&times;</CloseButton>
                </ModalHeader>
                <ModalBody>
                    <UserCreate onClose={onClose} onUserAdded={onUserAdded} />
                </ModalBody>
            </ModalContent>
        </StyledModal>
    );
};

export default UserModal;

// Styled Components
const StyledModal = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1050;
`;

const Backdrop = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1040;
`;

const ModalContent = styled.div`
    position: relative;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 500px;
    max-width: 90%;
    z-index: 1050;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    @media (max-width: 768px) {
        width: 90%;
    }
`;

const ModalHeader = styled.div`
    padding: 15px 20px;
    background-color: rgba(130, 22, 146, 0.1);
    border-bottom: 1px solid rgba(130, 22, 146, 0.2);
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const ModalTitle = styled.h5`
    margin: 0;
    color: rgba(130, 22, 146, 1);
    font-weight: bold;
`;

const CloseButton = styled.button`
    background: none;
    border: none;
    font-size: 1.5rem;
    color: #333;
    cursor: pointer;

    &:hover {
        color: rgba(130, 22, 146, 1);
    }
`;

const ModalBody = styled.div`
    padding: 20px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
`;
