import React, { useContext } from 'react';
import { FormGroup, Label, Input } from 'reactstrap';
import {SettingsContext} from "../../../SettingsContext";

const UserAgeComponent = ({ value, operator, onChange, target, type, attribute }) => {
    const { siteSettings } = useContext(SettingsContext);
    let conditionTypes = [];
    let supportedOperators = [];

    // Check if the target is 'conditions' and fetch condition types
    if (target === 'conditions') {
        conditionTypes = siteSettings?.getConditionTypes() || [];

        // Find the condition type and attribute to get supported operators
        const selectedConditionType = conditionTypes.find(condType => condType.key === type);
        if (selectedConditionType) {
            const selectedAttribute = selectedConditionType.getAttributes().find(attr => attr.key === attribute);
            if (selectedAttribute) {
                supportedOperators = selectedAttribute.supportedOperators || [];
            }
        }
    }
    if (target === 'targets') {
        conditionTypes = siteSettings?.getTargetTypes() || [];

        // Find the condition type and attribute to get supported operators
        const selectedConditionType = conditionTypes.find(condType => condType.key === type);
        if (selectedConditionType) {
            const selectedAttribute = selectedConditionType.getAttributes().find(attr => attr.key === attribute);
            if (selectedAttribute) {
                supportedOperators = selectedAttribute.supportedOperators || [];
            }
        }
    }

    return (
        <FormGroup>
            <Label for="operator">Operator</Label>
            <Input
                type="select"
                id="operator"
                value={operator || ''}
                onChange={(e) => onChange('operator', e.target.value)}
            >
                <option value="">Select Operator</option>
                {supportedOperators.map(op => (
                    <option key={op} value={op}>
                        {op}
                    </option>
                ))}
            </Input>

            <Label for="value">Age</Label>
            <Input
                type="number"
                id="value"
                value={value || ''}
                onChange={(e) => onChange('value', e.target.value)}
                placeholder="Enter Age"
            />
        </FormGroup>
    );
};

export default UserAgeComponent;
