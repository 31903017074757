import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { fetchUserTimeline } from '../../../services/Http/Api';
import { useTranslation } from 'react-i18next';
import { MdHelpOutline } from 'react-icons/md';
import QuestionModal from "../../global/QuestionModal";
import styled from 'styled-components';

const Timeline = () => {
    const { t } = useTranslation();
    const [chartData, setChartData] = useState({ categories: [], series: [] });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showQuestionModal, setShowQuestionModal] = useState(false);

    const handleShowQuestionModal = () => setShowQuestionModal(true);
    const handleCloseQuestionModal = () => setShowQuestionModal(false);

    useEffect(() => {
        const loadTimeline = async () => {
            try {
                setLoading(true);
                const data = await fetchUserTimeline();

                const categories = data.map(item => item.registration_month);
                const counts = data.map(item => item.user_count);

                setChartData({
                    categories,
                    series: [
                        {
                            name: t('Users.Timeline.title'),
                            data: counts
                        }
                    ]
                });
            } catch (error) {
                setError(t('Users.Timeline.error'));
                console.error('Error:', error);
            } finally {
                setLoading(false);
            }
        };

        loadTimeline();
    }, [t]);

    if (loading) {
        return <LoadingMessage>{t('Users.Timeline.loading')}</LoadingMessage>;
    }

    if (error) {
        return <ErrorMessage>{error}</ErrorMessage>;
    }

    const chartOptions = {
        chart: {
            type: 'bar',
            height: 350,
            toolbar: {
                show: false
            }
        },
        xaxis: {
            categories: chartData.categories,
            title: {
                text: t('Users.Timeline.xAxisTitle')
            },
            labels: {
                rotate: -45
            }
        },
        yaxis: {
            title: {
                text: t('Users.Timeline.yAxisTitle')
            }
        },
        title: {
            text: t('Users.Timeline.title'),
            align: 'center'
        }
    };

    return (
        <TimelineContainer>
            <Header>
                <IconButton onClick={handleShowQuestionModal}>
                    <MdHelpOutline className="icon" />
                </IconButton>
            </Header>
            <ChartContainer>
                <ReactApexChart
                    options={chartOptions}
                    series={chartData.series}
                    type="bar"
                    height={350}
                />
            </ChartContainer>
            <QuestionModal
                show={showQuestionModal}
                onClose={handleCloseQuestionModal}
                param="users_timeline"
            />
        </TimelineContainer>
    );
};

export default Timeline;

// Styled Components
const TimelineContainer = styled.div`
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

    @media (max-width: 768px) {
        padding: 15px;
    }
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
    }
`;

const Title = styled.h3`
    color: rgba(130, 22, 146, 1);
    font-size: 1.5rem;
    font-weight: bold;
    margin: 0;

    @media (max-width: 768px) {
        font-size: 1.25rem;
    }
`;

const IconButton = styled.button`
    background: none;
    border: none;
    color: rgba(130, 22, 146, 1);
    font-size: 1.5rem;
    cursor: pointer;
    display: flex;
    align-items: center;

    &:hover {
        color: rgba(130, 22, 146, 0.8);
    }

    @media (max-width: 768px) {
        font-size: 1.25rem;
    }
`;

const ChartContainer = styled.div`
    margin-top: 20px;

    @media (max-width: 768px) {
        margin-top: 15px;
    }
`;

const LoadingMessage = styled.div`
    font-size: 1.2rem;
    color: rgba(130, 22, 146, 1);
    text-align: center;
`;

const ErrorMessage = styled.div`
    font-size: 1.2rem;
    color: red;
    text-align: center;
`;
