import React, { useState, useEffect } from 'react';
import CampaignDetails from './CampaignDetails';
import PromotionsManagement from './PromotionsManagement';
import PromotionWizard from './PromotionWizard';
import Calculation from './Calculation';
import { fetchCampaign, updateCampaign, calculateCampaign } from "../../../services/Http/Api";
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { MdOutlineCampaign, MdOutlineCalculate, MdAddCircleOutline, MdArrowBack } from 'react-icons/md';
import Campaign from "../../../models/global/DataModels/Campaign/Campaign";
const CampaignWizardContainer = styled.div`
    width: 100%;
    height: 100vh; /* Full height of the screen */
    background-color: #fff;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
`;

const WizardHeader = styled.div`
    text-align: center;
    margin-bottom: 20px;
    h2 {
        color: #822292;
    }
`;

const WizardSteps = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    border-bottom: 2px solid #ddd;
    padding-bottom: 10px;
`;

const WizardStep = styled.div`
    flex: 1;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s ease;
    border-bottom: 2px solid ${({ active }) => (active ? '#822292' : 'transparent')};
    color: ${({ active }) => (active ? '#822292' : '#333')};
    font-weight: ${({ active }) => (active ? 'bold' : 'normal')};
    display: flex;
    flex-direction: column;
    align-items: center;

    &:hover {
        color: #822292;
    }

    svg {
        font-size: 28px;
        margin-bottom: 4px;
    }
`;

const WizardContent = styled.div`
    flex: 1; /* Ensures the content area stretches */
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
    overflow-y: auto; /* Allows scrolling if content is large */
`;

const WizardFooter = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    button {
        padding: 10px 20px;
        border-radius: 4px;
        font-size: 16px;
        cursor: pointer;
        border: none;
    }

    .btn-secondary {
        background-color: #ddd;
        color: #333;
    }

    .btn-primary {
        background-color: #822292;
        color: #fff;
    }

    svg {
        margin-right: 8px;
        font-size: 20px;
    }
`;

const CampaignWizard = () => {
    const { id } = useParams();
    const { t } = useTranslation();

    const [currentStep, setCurrentStep] = useState('CampaignDetails');
    const [campaign, setCampaign] = useState(null);
    const [loading, setLoading] = useState(true);
    const [selectedPromotionIndex, setSelectedPromotionIndex] = useState(null);
    const defaultPromotionData = {
        id: null,
        name: '',
        description: '',
        startDate: '',
        endDate: '',
        daysOfWeek: [],
        startTime: '',
        endTime: '',
        discount: 0,
        pointsMultiplier: 0,
        active: true,
        conditions: [],
        targets: [],
        incentives: [],
        strategies: [],
    };
    useEffect(() => {
        const loadCampaign = async () => {
            try {
                const data = await fetchCampaign(id);
                setCampaign(new Campaign(data)); // Fix for using new Campaign
            } catch (error) {
                console.error('Error loading campaign:', error);
            } finally {
                setLoading(false);
            }
        };

        loadCampaign();
    }, [id]);

    const handleNextStep = (nextStep) => setCurrentStep(nextStep);

    const handleBack = () => {
        if (currentStep === 'PromotionsManagement') setCurrentStep('CampaignDetails');
        if (currentStep === 'PromotionWizard') setCurrentStep('PromotionsManagement');
        if (currentStep === 'Calculation') setCurrentStep('PromotionsManagement');
    };

    const handlePromotionSelection = (index) => {
        setSelectedPromotionIndex(index);
        handleNextStep('PromotionWizard');
    };

    if (loading) {
        return (
            <CampaignWizardContainer>
                <p>{t('loading')}</p>
            </CampaignWizardContainer>
        );
    }

    return (
        <CampaignWizardContainer>
            <WizardHeader>
                <h2>{t('Campaign Wizard')}</h2>
            </WizardHeader>
            <WizardSteps>
                <WizardStep
                    active={currentStep === 'CampaignDetails'}
                    onClick={() => setCurrentStep('CampaignDetails')}
                >
                    <MdOutlineCampaign />
                    {t('Campaign Details')}
                </WizardStep>
                <WizardStep
                    active={currentStep === 'PromotionsManagement'}
                    onClick={() =>
                        currentStep !== 'CampaignDetails' &&
                        setCurrentStep('PromotionsManagement')
                    }
                >
                    <MdAddCircleOutline />
                    {t('Promotions Management')}
                </WizardStep>
                <WizardStep
                    active={currentStep === 'PromotionWizard'}
                    onClick={() =>
                        currentStep === 'PromotionsManagement' &&
                        setCurrentStep('PromotionWizard')
                    }
                >
                    <MdAddCircleOutline />
                    {t('Promotion Wizard')}
                </WizardStep>
                <WizardStep
                    active={currentStep === 'Calculation'}
                    onClick={() =>
                        currentStep === 'PromotionsManagement' &&
                        setCurrentStep('Calculation')
                    }
                >
                    <MdOutlineCalculate />
                    {t('Calculation')}
                </WizardStep>
            </WizardSteps>
            <WizardContent>
                {currentStep === 'CampaignDetails' && (
                    <CampaignDetails
                        campaign={campaign}
                        setCampaign={setCampaign}
                        onNext={() => handleNextStep('PromotionsManagement')}
                    />
                )}
                {currentStep === 'PromotionsManagement' && (
                    <PromotionsManagement
                        promotions={campaign.promotions}
                        onSelectPromotion={handlePromotionSelection}
                        onAddPromotion={() => {
                            setCampaign((prev) => ({
                                ...prev,
                                promotions: [...prev.promotions, defaultPromotionData],
                            }));
                        }}
                        onNext={() => handleNextStep('Calculation')}
                    />
                )}
                {currentStep === 'PromotionWizard' && selectedPromotionIndex !== null && (
                    <PromotionWizard
                        promotion={campaign.promotions[selectedPromotionIndex]}
                        setPromotion={(updatedPromotion) => {
                            const updatedPromotions = [...campaign.promotions];
                            updatedPromotions[selectedPromotionIndex] = updatedPromotion;
                            setCampaign({ ...campaign, promotions: updatedPromotions });
                        }}
                        onBack={handleBack}
                    />
                )}
                {currentStep === 'Calculation' && (
                    <Calculation
                        campaign={campaign}
                        onCalculate={async () => {
                            const data = await calculateCampaign(campaign);
                            console.log(data);
                        }}
                        onSave={async () => {
                            await updateCampaign(id, campaign);
                            alert(t('Campaign saved successfully!'));
                        }}
                    />
                )}
            </WizardContent>
            <WizardFooter>
                {currentStep !== 'CampaignDetails' && (
                    <button className="btn-secondary" onClick={handleBack}>
                        <MdArrowBack />
                        {t('Back')}
                    </button>
                )}
                {currentStep === 'Calculation' && (
                    <button className="btn-primary" onClick={() => alert(t('Saving Campaign...'))}>
                        {t('Save Campaign')}
                    </button>
                )}
            </WizardFooter>
        </CampaignWizardContainer>
    );
};

export default CampaignWizard;

