import React, { useState, useEffect } from 'react';
import UsersList from './UsersList';
import Statistics from './Statistics';
import Timeline from './Timeline';
import { useTranslation } from 'react-i18next';
import QuestionModal from '../../global/QuestionModal';
import { MdInfoOutline, MdHelpOutline } from 'react-icons/md';
import styled from 'styled-components';

const Users = () => {
    const { t } = useTranslation();
    const [showUsersTutorialModal, setShowUsersTutorialModal] = useState(!localStorage.getItem('UsersTutorialSeen'));
    const [showQuestionModal, setShowQuestionModal] = useState(false);
    const [activeTab, setActiveTab] = useState('statistics');

    useEffect(() => {
        if (!localStorage.getItem('UsersTutorialSeen')) {
            setShowUsersTutorialModal(true);
        }
    }, []);

    const handleCloseTutorialModal = () => {
        setShowUsersTutorialModal(false);
        localStorage.setItem('UsersTutorialSeen', 'true');
    };

    const handleShowQuestionModal = () => setShowQuestionModal(true);
    const handleCloseQuestionModal = () => setShowQuestionModal(false);

    return (
        <UsersContainer>
            <Header>
                <Title>{t('Users.title')}</Title>
                <ButtonGroup>
                    <IconButton onClick={handleShowQuestionModal}>
                        <MdHelpOutline className="info-icon" />
                    </IconButton>
                    <IconButton onClick={() => setShowUsersTutorialModal(true)}>
                        <MdInfoOutline className="info-icon" />
                    </IconButton>
                </ButtonGroup>
            </Header>

            <Tabs>
                <Tab
                    active={activeTab === 'statistics'}
                    onClick={() => setActiveTab('statistics')}
                >
                    {t('Users.statistics')}
                </Tab>
                <Tab
                    active={activeTab === 'timeline'}
                    onClick={() => setActiveTab('timeline')}
                >
                    {t('Users.timeline')}
                </Tab>
                <Tab
                    active={activeTab === 'usersList'}
                    onClick={() => setActiveTab('usersList')}
                >
                    {t('Users.usersList')}
                </Tab>
            </Tabs>

            <Content>
                {activeTab === 'statistics' && <Statistics />}
                {activeTab === 'timeline' && <Timeline />}
                {activeTab === 'usersList' && <UsersList />}
            </Content>

            {/* Tutorial Modal */}
            <StyledModal show={showUsersTutorialModal}>
                <ModalHeader>
                    <ModalTitle>{t('Users.Tutorial.title')}</ModalTitle>
                    <CloseButton onClick={handleCloseTutorialModal}>&times;</CloseButton>
                </ModalHeader>
                <ModalBody>
                    <TutorialCard>
                        <h5>{t('Users.Tutorial.explanation_title')}</h5>
                        <p>{t('Users.Tutorial.explanation_message')}</p>
                    </TutorialCard>
                </ModalBody>
            </StyledModal>

            {/* Question Modal */}
            <QuestionModal
                show={showQuestionModal}
                onClose={handleCloseQuestionModal}
                param="users"
            />
        </UsersContainer>
    );
};

export default Users;

// Styled Components

const UsersContainer = styled.div`
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

    @media (max-width: 768px) {
    padding: 10px;
    }
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 15px;
    border-bottom: 2px solid rgba(130, 22, 146, 0.8);

    @media (max-width: 768px) {
        flex-direction: column;
        gap: 10px;
    }
`;

const Title = styled.h1`
    color: rgba(130, 22, 146, 1);
    font-size: 1.8rem;
    font-weight: bold;

    @media (max-width: 768px) {
        font-size: 1.5rem;
    }
`;

const ButtonGroup = styled.div`
    display: flex;
    gap: 10px;

    @media (max-width: 768px) {
        flex-wrap: wrap;
    }
`;

const IconButton = styled.button`
    background-color: rgba(130, 22, 146, 0.1);
    color: rgba(130, 22, 146, 1);
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
        background-color: rgba(130, 22, 146, 0.2);
    }

    .info-icon {
        font-size: 1.5rem;
    }
`;

const Tabs = styled.div`
    display: flex;
    margin-top: 20px;
    border-bottom: 2px solid rgba(130, 22, 146, 0.2);

    @media (max-width: 768px) {
        flex-wrap: wrap;
        gap: 10px;
    }
`;

const Tab = styled.button`
    flex: 1;
    padding: 10px;
    border: none;
    background: ${({ active }) => (active ? '#fff' : 'transparent')};
    border-bottom: ${({ active }) =>
        active ? '4px solid rgba(130, 22, 146, 1)' : 'none'};
    color: ${({ active }) => (active ? 'rgba(130, 22, 146, 1)' : '#333')};
    font-weight: bold;
    cursor: pointer;
    text-align: center;

    &:hover {
        color: rgba(130, 22, 146, 1);
    }

    @media (max-width: 768px) {
        padding: 8px;
        font-size: 0.9rem;
    }
`;

const Content = styled.div`
    margin-top: 20px;
`;

const StyledModal = styled.div`
    display: ${({ show }) => (show ? 'flex' : 'none')};
    flex-direction: column;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 500px;
    max-width: 90%;
    z-index: 1050;
`;

const ModalHeader = styled.div`
    padding: 15px;
    background-color: rgba(130, 22, 146, 0.1);
    border-bottom: 1px solid rgba(130, 22, 146, 0.2);
`;

const ModalTitle = styled.h5`
    margin: 0;
    color: rgba(130, 22, 146, 1);
    font-weight: bold;
`;

const CloseButton = styled.button`
    background: none;
    border: none;
    font-size: 1.5rem;
    color: #333;
    cursor: pointer;

    &:hover {
        color: rgba(130, 22, 146, 1);
    }
`;

const ModalBody = styled.div`
    padding: 20px;
`;

const TutorialCard = styled.div`
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 8px;
    border: 1px solid rgba(130, 22, 146, 0.2);
    color: #333;
    margin-bottom: 15px;

    h5 {
        font-size: 1.25rem;
        color: rgba(130, 22, 146, 1);
        margin-bottom: 10px;
    }

    p {
        font-size: 1rem;
        line-height: 1.5;
    }
`;
