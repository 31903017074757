import React, { useState } from 'react';
import styled from 'styled-components';
import PromotionSettings from './PromotionSettings';
import PromotionTargets from './PromotionTargets';
import PromotionStrategies from './PromotionStrategies';
import PromotionConditions from './PromotionConditions';
import PromotionIncentives from './PromotionIncentives';

const PromotionWizardContainer = styled.div`
    padding: 30px;
    background-color: #ffffff;
    border-radius: 12px;
    width: 100%;
    margin: 20px 0;
    border: 1px solid #ddd;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

    h3 {
        color: #822292;
        text-align: left; /* Align to the left for consistency */
        margin-bottom: 25px;
        font-weight: bold;
    }

    .wizard-footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 30px;

        button {
            padding: 10px 20px;
            border-radius: 8px;
            font-size: 16px;
            cursor: pointer;
            transition: all 0.3s ease;
            font-weight: 500;
            border: none;

            &.btn-primary {
                background-color: #822292;
                color: #ffffff;

                &:hover {
                    background-color: #9b35a6;
                }
            }

            &.btn-secondary {
                background-color: #ddd;
                color: #333;

                &:hover {
                    background-color: #bbb;
                }
            }
        }
    }

    @media (max-width: 768px) {
        padding: 20px;

        .wizard-footer {
            flex-direction: column;
            gap: 10px;
        }

        button {
            width: 100%;
        }
    }
`;

const PromotionWizard = ({ promotion, setPromotion, onBack }) => {
    const steps = [
        { key: 'PromotionSettings', label: 'Settings' },
        { key: 'PromotionTargets', label: 'Targets' },
        { key: 'PromotionStrategies', label: 'Strategies' },
        { key: 'PromotionConditions', label: 'Conditions' },
        { key: 'PromotionIncentives', label: 'Incentives' },
    ];

    const [currentStepIndex, setCurrentStepIndex] = useState(0);

    const currentStep = steps[currentStepIndex].key;

    const handleNext = () => {
        if (currentStepIndex < steps.length - 1) {
            setCurrentStepIndex(currentStepIndex + 1);
        }
    };

    const handlePrevious = () => {
        if (currentStepIndex > 0) {
            setCurrentStepIndex(currentStepIndex - 1);
        }
    };

    return (
        <PromotionWizardContainer>
            <h3>Promotion Wizard</h3>
            {currentStep === 'PromotionSettings' && (
                <PromotionSettings
                    promotion={promotion}
                    setPromotion={setPromotion}
                />
            )}
            {currentStep === 'PromotionTargets' && (
                <PromotionTargets
                    promotion={promotion}
                    setPromotion={setPromotion}
                />
            )}
            {currentStep === 'PromotionStrategies' && (
                <PromotionStrategies
                    promotion={promotion}
                    setPromotion={setPromotion}
                />
            )}
            {currentStep === 'PromotionConditions' && (
                <PromotionConditions
                    promotion={promotion}
                    setPromotion={setPromotion}
                />
            )}
            {currentStep === 'PromotionIncentives' && (
                <PromotionIncentives
                    promotion={promotion}
                    setPromotion={setPromotion}
                />
            )}
            <div className="wizard-footer">
                {currentStepIndex > 0 && (
                    <button className="btn-secondary" onClick={handlePrevious}>
                        Previous
                    </button>
                )}
                {currentStepIndex < steps.length - 1 ? (
                    <button className="btn-primary" onClick={handleNext}>
                        Next
                    </button>
                ) : (
                    <button className="btn-primary" onClick={onBack}>
                        Finish
                    </button>
                )}
            </div>
        </PromotionWizardContainer>
    );
};


export default PromotionWizard;
