import styled from 'styled-components';

const StyledPromotionsContainer = styled.div`
    display: flex;
    gap: 20px;
    margin-top: 20px;
    padding: 10px; /* Optional: Adds padding to the overall container */
    background-color: #ffffff;
    .promotion-list {
        flex: 1;
        background-color: #ffffff;
        border: 1px solid #ddd;
        border-radius: 8px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        overflow-y: auto; /* Ensure long content scrolls within this container */
        max-width: 300px;
        h4 {
            color: rgba(130, 22, 146, 1);
            font-weight: bold;
            margin-bottom: 15px;
            font-size: 1.2rem;
        }
    }

    .promotion-edit {
        flex: 2;
        background-color: #ffffff;
        border: 1px solid #ddd;
        border-radius: 8px;
        padding: 15px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        overflow-y: auto; /* Handle long content gracefully */

        h4 {
            color: rgba(130, 22, 146, 1);
            font-weight: bold;
            margin-bottom: 15px;
            font-size: 1.2rem;
        }
    }

    @media (max-width: 768px) {
        flex-direction: column;

        .promotion-list,
        .promotion-edit {
            flex: 1;
            width: 100%;
            margin-bottom: 10px; /* Add space between stacked elements */
        }
    }
`;

export default StyledPromotionsContainer;
