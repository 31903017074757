import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import i18n from './lang/i18n';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'bootstrap/dist/css/bootstrap.min.css';
import {SettingsProvider} from "./components/SettingsContext";
import {I18nextProvider} from "react-i18next";
import AuthProvider from "./context/AuthProvider";
import AppRouter from "./AppRouter";
import LanguageProvider from "./providers/LanguageProvider";
import { ThemeProvider } from 'styled-components';
import theme from './shared/layout/theme';
import { BrowserRouter as Router } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <ThemeProvider theme={theme}>
    <SettingsProvider>
        <LanguageProvider>
            <I18nextProvider i18n={i18n}>
                <Router>
                <AuthProvider>
                    <AppRouter />
                </AuthProvider>
                </Router>
            </I18nextProvider>
        </LanguageProvider>
    </SettingsProvider>
    </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
