import React, { useEffect, useState, useMemo } from 'react';
import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import { fetchAllVenues } from '../../../services/Http/Api';
import { MdEdit, MdDelete, MdAdd } from 'react-icons/md';
import Flag from 'react-world-flags';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const VenuesList = () => {
    const { t } = useTranslation();
    const [venues, setVenues] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [perPage, setPerPage] = useState(10);
    const [page, setPage] = useState(1);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetchAllVenues();
                setVenues(response.data);
            } catch (error) {
                console.error('Error fetching venues:', error);
            }
        };

        fetchData();
    }, []);

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
        setResetPaginationToggle(!resetPaginationToggle);
    };

    const handleAddNew = () => {
        navigate('/venues/create');
    };

    const handleEditVenue = (id) => {
        navigate(`/venues/view/${id}`);
    };

    const handleDeleteVenue = (id) => {
        console.log("Deleting venue with ID:", id);
    };

    const filteredVenues = venues.filter((venue) =>
        venue.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        venue.description.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const columns = useMemo(
        () => [
            { name: t('Venues.columns.id'), selector: (row) => row.id, sortable: true },
            { name: t('Venues.columns.name'), selector: (row) => row.name, sortable: true },
            { name: t('Venues.columns.email'), selector: (row) => row.email, sortable: true },
            { name: t('Venues.columns.phone'), selector: (row) => `+${row.phone_country.phonecode}${row.phone}`, sortable: true },
            {
                name: t('Venues.columns.country'),
                selector: (row) => (
                    <CountryCell>
                        <Flag code={row.country.iso} style={{ width: '24px', height: '16px', borderRadius: '2px' }} />
                        <span>{row.country.name}</span>
                    </CountryCell>
                ),
                sortable: true,
            },
            { name: t('Venues.columns.address'), selector: (row) => row.address, sortable: true },
            {
                name: t('Venues.columns.action'),
                cell: (row) => (
                    <ActionButtons>
                        <MdEdit
                            className="edit-icon"
                            onClick={() => handleEditVenue(row.id)}
                        />
                        <MdDelete
                            className="delete-icon"
                            onClick={() => handleDeleteVenue(row.id)}
                        />
                    </ActionButtons>
                ),
                ignoreRowClick: true,
                allowOverflow: true,
                button: true,
            },
        ],
        [t]
    );

    return (
        <Container>
            <Header>
                <SearchInput
                    type="text"
                    placeholder={t('Venues.search_placeholder')}
                    value={searchTerm}
                    onChange={handleSearchChange}
                />
                <AddButton onClick={handleAddNew}>
                    <MdAdd size={20} />
                    {t('Venues.add_new_button')}
                </AddButton>
            </Header>
            <DataTable
                columns={columns}
                data={filteredVenues}
                pagination
                persistTableHead
                highlightOnHover
                resetPaginationToggle={resetPaginationToggle}
                noHeader
                onPageChange={(page) => setPage(page)}
                onChangeRowsPerPage={(newPerPage, newPage) => {
                    setPerPage(newPerPage);
                    setPage(newPage);
                }}
            />
        </Container>
    );
};

export default VenuesList;

// Styled Components
const Container = styled.div`
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

    @media (max-width: 768px) {
        padding: 10px;
    }
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
`;

const SearchInput = styled.input`
    padding: 10px;
    font-size: 1rem;
    border: 1px solid rgba(130, 22, 146, 0.4);
    border-radius: 5px;
    width: 100%;
    max-width: 300px;

    &:focus {
        outline: none;
        border-color: rgba(130, 22, 146, 0.8);
        box-shadow: 0 0 5px rgba(130, 22, 146, 0.5);
    }
`;

const AddButton = styled.button`
    display: flex;
    align-items: center;
    background-color: rgba(130, 22, 146, 1);
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;

    svg {
        margin-right: 5px;
    }

    &:hover {
        background-color: rgba(110, 12, 136, 1);
    }
`;

const CountryCell = styled.div`
    display: flex;
    align-items: center;

    span {
        margin-left: 10px;
        font-size: 0.9rem;
    }
`;

const ActionButtons = styled.div`
    display: flex;
    gap: 10px;

    .edit-icon {
        color: rgba(130, 22, 146, 1);
        cursor: pointer;
        font-size: 1.2rem;

        &:hover {
            color: rgba(110, 12, 136, 1);
        }
    }

    .delete-icon {
        color: #d9534f;
        cursor: pointer;
        font-size: 1.2rem;

        &:hover {
            color: #c9302c;
        }
    }
`;

