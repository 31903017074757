import React from 'react';
import { useNavigate } from 'react-router-dom';

const RegisterPage = () => {
    const navigate = useNavigate();

    const handleRegisterClick = (path) => {
        navigate(path);
    };

    return (
        <div className="container mt-5">
            <h2 className="text-center mb-4">Register</h2>
            <p className="text-center mb-5">Choose the best plan that suits your needs.</p>
            <div className="row justify-content-center">
                <div className="col-md-3 col-sm-6 d-flex align-items-stretch">
                    <div className="card mb-4 w-100">
                        <div className="card-body d-flex flex-column">
                            <h5 className="card-title text-center">User</h5>
                            <p className="card-text text-center">Price: Free</p>
                            <ul className="list-unstyled flex-grow-1">
                                <li>Manage Your Warranties</li>
                                <li>Get Notifications</li>
                                <li>Transfer your warranties (2 euros)</li>
                            </ul>
                            <div className="mt-auto text-center">
                                <button
                                    className="btn btn-primary"
                                    onClick={() => handleRegisterClick('/register/user')}
                                >
                                    Register as User
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-3 col-sm-6 d-flex align-items-stretch">
                    <div className="card mb-4 w-100">
                        <div className="card-body d-flex flex-column">
                            <h5 className="card-title text-center">Company</h5>
                            <p className="card-text text-center">Price: Free</p>
                            <ul className="list-unstyled flex-grow-1">
                                <li>Manage Your Warranties for your employees</li>
                                <li>Get Notifications</li>
                                <li>Transfer your warranties to your employees or 3rd party users (2 euros)</li>
                            </ul>
                            <div className="mt-auto text-center">
                                <button
                                    className="btn btn-primary"
                                    onClick={() => handleRegisterClick('/register/company')}
                                >
                                    Register as Company
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-3 col-sm-6 d-flex align-items-stretch">
                    <div className="card mb-4 w-100">
                        <div className="card-body d-flex flex-column">
                            <h5 className="card-title text-center">Reseller</h5>
                            <p className="card-text text-center">Price: 10 euros monthly</p>
                            <ul className="list-unstyled flex-grow-1">
                                <li>Manage Warranties</li>
                                <li>Sell Warranties</li>
                                <li>Claim Warranties</li>
                                <li>Advertise your sales</li>
                                <li>Track insights</li>
                            </ul>
                            <div className="mt-auto text-center">
                                <button
                                    className="btn btn-primary"
                                    onClick={() => handleRegisterClick('/register/reseller')}
                                >
                                    Register as Reseller
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-3 col-sm-6 d-flex align-items-stretch">
                    <div className="card mb-4 w-100">
                        <div className="card-body d-flex flex-column">
                            <h5 className="card-title text-center">Manufacturer</h5>
                            <p className="card-text text-center">Price: 100 euros monthly</p>
                            <ul className="list-unstyled flex-grow-1">
                                <li>Manage Warranties</li>
                                <li>Transfer Warranties</li>
                                <li>Claim Warranties</li>
                                <li>Advertise your sales</li>
                                <li>Track insights</li>
                            </ul>
                            <div className="mt-auto text-center">
                                <button
                                    className="btn btn-primary"
                                    onClick={() => handleRegisterClick('/register/manufacturer')}
                                >
                                    Register as Manufacturer
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RegisterPage;
