import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const PhoneNumberInput = ({ countryCodes, phoneValue, phoneCountryValue, onChange }) => {
    const { t } = useTranslation();
    const [selectedCountryCode, setSelectedCountryCode] = useState(phoneCountryValue || '');
    const [phoneNumber, setPhoneNumber] = useState(phoneValue || '');

    useEffect(() => {
        setSelectedCountryCode(phoneCountryValue || '');
        setPhoneNumber(phoneValue || '');
    }, [phoneValue, phoneCountryValue]);

    const handleCountryCodeChange = (e) => {
        const countryCode = e.target.value;
        setSelectedCountryCode(countryCode);
        onChange({
            phoneCountryCode: countryCode,
            phone: phoneNumber,
        });
    };

    const handlePhoneNumberChange = (e) => {
        const number = e.target.value;
        setPhoneNumber(number);
        onChange({
            phoneCountryCode: selectedCountryCode,
            phone: number,
        });
    };

    return (
        <InputGroup>
            <CountrySelect
                value={selectedCountryCode}
                onChange={handleCountryCodeChange}
            >
                <option value="">{t('PhoneInput.selectCountryCode')}</option>
                {countryCodes.map((country) => (
                    <option key={country.id} value={country.id}>
                        {`${country.name} (+${country.phonecode})`}
                    </option>
                ))}
            </CountrySelect>
            <PhoneNumber
                type="tel"
                value={phoneNumber}
                onChange={handlePhoneNumberChange}
                placeholder={t('PhoneInput.placeholder')}
            />
        </InputGroup>
    );
};

PhoneNumberInput.propTypes = {
    countryCodes: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
            name: PropTypes.string.isRequired,
            phonecode: PropTypes.string.isRequired,
        })
    ).isRequired,
    phoneValue: PropTypes.string,
    phoneCountryValue: PropTypes.string,
    onChange: PropTypes.func.isRequired,
};

export default PhoneNumberInput;

// Styled Components
const InputGroup = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 15px;
    width: 100%;

    @media (max-width: 768px) {
        flex-direction: column;
        gap: 5px;
    }
`;

const CountrySelect = styled.select`
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
    width: 100%;
    max-width: 200px;

    &:focus {
        border-color: rgba(130, 22, 146, 0.8);
        outline: none;
    }

    @media (max-width: 768px) {
        max-width: 100%;
    }
`;

const PhoneNumber = styled.input`
    flex: 2;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
    width: 100%;
    max-width: calc(100% - 210px);

    &:focus {
        border-color: rgba(130, 22, 146, 0.8);
        outline: none;
    }

    @media (max-width: 768px) {
        max-width: 100%;
    }
`;
