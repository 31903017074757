import React, { useState, useEffect } from 'react';
import { useAuth } from '../../context/AuthProvider';
import { useTranslation } from 'react-i18next';
import Logo from '../../../src/assets/images/gsoft_logo.png';
import LogoHeader from '../../shared/layout/components/misc/LogoHeader';
import LanguageSelector from '../../shared/layout/components/misc/LanguageSelector';
import LoginForm from '../../shared/layout/components/forms/LoginForm';
import Options from '../../shared/layout/components/misc/Options';
import TutorialModal from '../global/TutorialModal';
import UserInfoModal from '../global/UserInfoModal';
import styled from 'styled-components';
import {AuthContainer} from "../../shared/layout/components/containers/AuthContainer";
import StyledCard from "../../shared/layout/components/cards/StyledCard";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationCircle, faInfoCircle, faQuestionCircle} from "@fortawesome/free-solid-svg-icons";

const LoginPage = () => {
    const { t, i18n } = useTranslation();
    const { login } = useAuth();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loginError, setLoginError] = useState('');
    const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem('selectedLanguage') || 'en');
    const [showTutorialModal, setShowTutorialModal] = useState(!localStorage.getItem('tutorialSeen'));
    const [showUserInfoModal, setShowUserInfoModal] = useState(false);

    useEffect(() => {
        if (showTutorialModal) return;
        const contactEmail = localStorage.getItem('contactEmail');
        if (!contactEmail) {
            setShowUserInfoModal(true);
        }
    }, [showTutorialModal]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoginError('');
        try {
            await login(email, password);
        } catch (error) {
            setLoginError(t('LoginPage.login_error'));
        }
    };

    const handleLanguageChange = (language) => {
        setSelectedLanguage(language);
        localStorage.setItem('selectedLanguage', language);
        i18n.changeLanguage(language);
    };

    const handleCloseTutorialModal = () => {
        setShowTutorialModal(false);
        localStorage.setItem('tutorialSeen', 'true');
    };

    const handleCloseUserInfoModal = () => {
        setShowUserInfoModal(false);
    };

    return (
        <AuthContainer>
            <StyledCard>
                <LogoHeader logoSrc={Logo} title="DEMO" onHelpClick={() => setShowTutorialModal(true)} />
                <LanguageSelector
                    selectedLanguage={selectedLanguage}
                    onLanguageChange={handleLanguageChange}
                />
                <LoginForm
                    email={email}
                    setEmail={setEmail}
                    password={password}
                    setPassword={setPassword}
                    onSubmit={handleSubmit}
                    error={loginError}
                />
                <Options />
            </StyledCard>
            <TutorialModal
                show={showTutorialModal}
                onClose={handleCloseTutorialModal}
                title={t('LoginPage.Tutorial.welcome_title')}
            >
                <div style={{height: 'auto'}}>
                    <div className="welcome-message" style={{marginBottom: '1.5rem'}}>
                        <p style={{fontSize: '1.5rem', lineHeight: '1.6', color: 'rgba(130, 22, 146, 1)'}}>
                            {t('LoginPage.Tutorial.welcome_message')}
                        </p>
                    </div>

                    <div className="card" style={{
                        padding: '20px',
                        marginBottom: '1.5rem',
                        backgroundColor: '#f8f9fa',
                        border: '1px solid rgba(0, 0, 0, 0.1)'
                    }}>
                        <h5 style={{fontSize: '1.25rem', marginBottom: '1rem', color: 'rgba(130, 22, 146, 1)'}}>
                            {t('LoginPage.Tutorial.about_app_title', {defaultValue: "About the App"})}
                        </h5>
                        <p style={{fontSize: '1rem', lineHeight: '1.6', margin: 0}}>
                            {t('LoginPage.Tutorial.about_app')}
                        </p>
                    </div>

                    <div className="card" style={{
                        padding: '20px',
                        marginBottom: '1.5rem',
                        backgroundColor: '#f8f9fa',
                        border: '1px solid rgba(0, 0, 0, 0.1)'
                    }}>
                        <h5 style={{fontSize: '1.25rem', marginBottom: '1rem', color: 'rgba(130, 22, 146, 1)'}}>
                            {t('LoginPage.Tutorial.info_title', {defaultValue: "Information"})}
                        </h5>
                        <p style={{fontSize: '1.2rem', lineHeight: '1.6'}}>
                            {t('LoginPage.Tutorial.info_message')}
                            <FontAwesomeIcon icon={faInfoCircle}
                                             style={{color: 'rgba(130, 22, 146, 1)', marginLeft: '0.5rem'}}/>
                        </p>
                        <p style={{fontSize: '1.2rem', lineHeight: '1.6'}}>
                            <FontAwesomeIcon icon={faExclamationCircle} className="ms-2"
                                             style={{color: 'red', fontSize: '1rem'}}/> {/* Under Development Icon */}
                            {t('LoginPage.Tutorial.development_message')}
                        </p>
                    </div>


                    <div className="card" style={{
                        padding: '20px',
                        marginBottom: '1.5rem',
                        backgroundColor: '#f8f9fa',
                        border: '1px solid rgba(0, 0, 0, 0.1)'
                    }}>
                        <h5 style={{fontSize: '1.25rem', marginBottom: '1rem', color: 'rgba(130, 22, 146, 1)'}}>
                            {t('LoginPage.Tutorial.credentials_title', {defaultValue: "Demo Credentials"})}
                        </h5>
                        <p style={{fontSize: '1.2rem', lineHeight: '1.6'}}>
                            {t('LoginPage.Tutorial.demo_credentials').split('\n').map((line, index) => (
                                <React.Fragment key={index}>{line}<br/></React.Fragment>
                            ))}
                        </p>
                    </div>
                    <div className="card" style={{
                        padding: '20px',
                        marginBottom: '1.5rem',
                        backgroundColor: '#f8f9fa',
                        border: '1px solid rgba(0, 0, 0, 0.1)'
                    }}>
                        <h5 style={{fontSize: '1.25rem', marginBottom: '1rem', color: 'rgba(130, 22, 146, 1)'}}>
                            {t('LoginPage.Tutorial.question_title', {defaultValue: "Have Questions?"})}
                        </h5>
                        <p style={{fontSize: '1.2rem', lineHeight: '1.6'}}>
                            {t('LoginPage.Tutorial.question_message', {
                                defaultValue: "If you have any questions about the system or page, just click the question mark icon and write us your question. Someone will answer you soon."
                            })}
                            <FontAwesomeIcon icon={faQuestionCircle}
                                             style={{color: 'rgba(130, 22, 146, 1)', marginLeft: '0.5rem'}}/>
                        </p>
                    </div>
                </div>
            </TutorialModal>
            <UserInfoModal show={showUserInfoModal} onClose={handleCloseUserInfoModal}/>
        </AuthContainer>
    );
};

export default LoginPage;
