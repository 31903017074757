import React, { useState } from 'react';
import styled from 'styled-components';
import {
    TabContent,
    TabPane,
    Spinner,
} from 'reactstrap';
import DataTable from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle, faInfoCircle } from '@fortawesome/free-solid-svg-icons';

const AuthorizedServiceCenters = () => {
    const { t } = useTranslation(); // Initialize translation hook
    const [activeTab, setActiveTab] = useState('1');
    const [loading, setLoading] = useState(false);
    const [newServiceName, setNewServiceName] = useState('');
    const [newServiceAddress, setNewServiceAddress] = useState('');
    const [newServicePhone, setNewServicePhone] = useState('');

    // Dummy data for authorized service centers
    const authorizedServices = [
        { id: 1, name: 'Authorized Service A', address: '123 Main St, Zagreb', phone: '01 2345 678' },
        { id: 2, name: 'Authorized Service B', address: '456 Second St, Split', phone: '021 2345 678' },
        { id: 3, name: 'Authorized Service C', address: '789 Third St, Rijeka', phone: '051 2345 678' },
    ];

    const toggleTab = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    const columns = [
        { name: t('AuthorizedServices.name'), selector: row => row.name, sortable: true },
        { name: t('AuthorizedServices.address'), selector: row => row.address, sortable: true },
        { name: t('AuthorizedServices.phone'), selector: row => row.phone, sortable: true },
    ];

    return (
        <Container>
            {/* Navigation Tabs */}
            <TabContainer>
                <TabItem
                    active={activeTab === '1'}
                    onClick={() => toggleTab('1')}
                >
                    {t('AuthorizedServices.overview')}
                </TabItem>
                <TabItem
                    active={activeTab === '2'}
                    onClick={() => toggleTab('2')}
                >
                    {t('AuthorizedServices.addService')}
                </TabItem>
            </TabContainer>

            {/* Tab Content */}
            <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                    <CardStyled>
                        {loading ? (
                            <Spinner color="primary" />
                        ) : (
                            <DataTable
                                columns={columns}
                                data={authorizedServices}
                                pagination
                                highlightOnHover
                                noDataComponent={<NoData>{t('AuthorizedServices.noServices')}</NoData>}
                            />
                        )}
                    </CardStyled>
                </TabPane>

                <TabPane tabId="2">
                    <CardStyled>
                        <FormContainer>
                            <StyledInput
                                type="text"
                                value={newServiceName}
                                onChange={(e) => setNewServiceName(e.target.value)}
                                placeholder={t('AuthorizedServices.serviceName')}
                            />
                            <StyledInput
                                type="text"
                                value={newServiceAddress}
                                onChange={(e) => setNewServiceAddress(e.target.value)}
                                placeholder={t('AuthorizedServices.serviceAddress')}
                            />
                            <StyledInput
                                type="text"
                                value={newServicePhone}
                                onChange={(e) => setNewServicePhone(e.target.value)}
                                placeholder={t('AuthorizedServices.servicePhone')}
                            />
                            <ButtonContainer>
                                <StyledButton>{t('AuthorizedServices.create')}</StyledButton>
                            </ButtonContainer>
                        </FormContainer>
                    </CardStyled>
                </TabPane>
            </TabContent>

            {/* Action Buttons */}
            <ActionButtons>
                <IconButton>
                    <FontAwesomeIcon icon={faQuestionCircle} />
                </IconButton>
                <IconButton>
                    <FontAwesomeIcon icon={faInfoCircle} />
                </IconButton>
            </ActionButtons>
        </Container>
    );
};

export default AuthorizedServiceCenters;

// Styled Components
const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
`;

const TabContainer = styled.div`
    display: flex;
    gap: 10px;
    border-bottom: 2px solid rgba(130, 22, 146, 0.2);
`;

const TabItem = styled.div`
    padding: 10px;
    cursor: pointer;
    font-weight: bold;
    color: ${({ active }) => (active ? 'rgba(130, 22, 146, 1)' : '#333')};
    border-bottom: ${({ active }) => (active ? '4px solid rgba(130, 22, 146, 1)' : 'none')};

    &:hover {
        color: rgba(130, 22, 146, 0.8);
    }
`;

const CardStyled = styled.div`
    background-color: #fff;
    border: 1px solid rgba(130, 22, 146, 0.2);
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
`;

const StyledInput = styled.input`
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ddd;
    &:focus {
        border-color: rgba(130, 22, 146, 1);
        box-shadow: 0 0 5px rgba(130, 22, 146, 0.5);
    }
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
`;

const StyledButton = styled.button`
    background-color: rgba(130, 22, 146, 1);
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
        background-color: rgba(130, 22, 146, 0.9);
    }
`;

const ActionButtons = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 10px;
`;

const IconButton = styled.button`
    background-color: rgba(130, 22, 146, 0.1);
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: rgba(130, 22, 146, 1);

    &:hover {
        background-color: rgba(130, 22, 146, 0.2);
    }
`;

const NoData = styled.div`
    text-align: center;
    color: #999;
    font-style: italic;
    padding: 20px;
`;
