import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import styled from 'styled-components';
import { Modal } from 'react-bootstrap';
import { fetchUsersByOrganization } from '../../../services/Http/Api';
import UserCreate from './components/UserCreate';
import { useTranslation } from 'react-i18next';
import QuestionModal from '../../global/QuestionModal';
import { MdHelpOutline, MdAdd } from 'react-icons/md';
import UserModal from "./components/UserModal";

const UsersList = () => {
    const { t } = useTranslation();
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showUserModal, setShowUserModal] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [sortBy, setSortBy] = useState('id');
    const [sortDirection, setSortDirection] = useState('asc');
    const [showQuestionModal, setShowQuestionModal] = useState(false);

    const handleShowQuestionModal = () => setShowQuestionModal(true);
    const handleCloseQuestionModal = () => setShowQuestionModal(false);

    const loadUsers = async (page) => {
        setLoading(true);
        try {
            const { users, total, currentPage } = await fetchUsersByOrganization(page, perPage, sortBy, sortDirection);
            setUsers(users);
            setTotalRows(total);
            setCurrentPage(currentPage);
        } catch (error) {
            console.error('Error fetching users:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        loadUsers(currentPage);
    }, [currentPage, perPage, sortBy, sortDirection]);

    const handleUserModalShow = () => setShowUserModal(true);
    const handleUserModalClose = () => setShowUserModal(false);

    const handleUserAdded = () => {
        loadUsers(currentPage);
        handleUserModalClose();
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        setPerPage(newPerPage);
        loadUsers(page);
    };

    const handleSort = (column, sortDirection) => {
        setSortBy(column.key);
        setSortDirection(sortDirection);
    };

    const columns = [
        { name: t('Users.UsersList.columns.userId'), key: 'user_id', selector: row => row.user_id, sortable: true },
        { name: t('Users.UsersList.columns.name'), key: 'user_name', selector: row => row.user_name, sortable: true },
        {
            name: t('Users.UsersList.columns.totalSpent'),
            key: 'total_spent',
            selector: row => parseFloat(row.total_spent),
            sortable: true,
            cell: row => `$${parseFloat(row.total_spent).toLocaleString()}`
        },
        {
            name: t('Users.UsersList.columns.firstTransactionDate'),
            key: 'first_transaction_date',
            selector: row => new Date(row.first_transaction_date),
            sortable: true,
            cell: row => row.first_transaction_date ? new Date(row.first_transaction_date).toLocaleDateString() : "N/A"
        },
        {
            name: t('Users.UsersList.columns.lastTransactionDate'),
            key: 'last_transaction_date',
            selector: row => new Date(row.last_transaction_date),
            sortable: true,
            cell: row => row.last_transaction_date ? new Date(row.last_transaction_date).toLocaleDateString() : "N/A"
        },
        { name: t('Users.UsersList.columns.countOfItems'), key: 'count_of_items', selector: row => row.count_of_items, sortable: true },
        { name: t('Users.UsersList.columns.venuesCount'), key: 'venues_count', selector: row => row.venues_statistics.length, sortable: false },
    ];

    const conditionalRowStyles = [
        {
            when: row => row.own_buyer,
            style: {
                backgroundColor: 'rgba(63, 195, 128, 0.2)',
            },
        },
        {
            when: row => !row.own_buyer,
            style: {
                backgroundColor: 'rgba(248, 215, 218, 0.2)',
            },
        },
    ];

    return (
        <UsersContainer>
            <Header>
                <Title>{t('Users.UsersList.title')}</Title>
                <ActionButtons>
                    <IconButton onClick={handleUserModalShow}>
                        <MdAdd className="icon" />
                        {t('Users.UsersList.addUserButton')}
                    </IconButton>
                    <IconButton onClick={handleShowQuestionModal}>
                        <MdHelpOutline className="icon" />
                    </IconButton>
                </ActionButtons>
            </Header>

            <Legend>
                <LegendItem>
                    <LegendColor color="rgba(63, 195, 128, 0.2)" border="#3fc380" />
                    {t('Users.UsersList.ownUser')}
                </LegendItem>
                <LegendItem>
                    <LegendColor color="rgba(248, 215, 218, 0.2)" border="#f8d7da" />
                    {t('Users.UsersList.globalUser')}
                </LegendItem>
            </Legend>

            <DataTable
                columns={columns}
                data={users}
                pagination
                paginationServer
                paginationTotalRows={totalRows}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handlePerRowsChange}
                progressPending={loading}
                highlightOnHover
                conditionalRowStyles={conditionalRowStyles}
                onSort={handleSort}
                sortServer
            />

            <UserModal show={showUserModal} onClose={handleUserModalClose} onUserAdded={handleUserAdded} />


            {/* Question Modal */}
            <QuestionModal
                show={showQuestionModal}
                onClose={handleCloseQuestionModal}
                param="users_list"
            />
        </UsersContainer>
    );
};

export default UsersList;

// Styled Components
const UsersContainer = styled.div`
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

    @media (max-width: 768px) {
        padding: 15px;
    }
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
    }
`;

const Title = styled.h3`
    color: rgba(130, 22, 146, 1);
    font-size: 1.5rem;
    font-weight: bold;

    @media (max-width: 768px) {
        font-size: 1.25rem;
    }
`;

const ActionButtons = styled.div`
    display: flex;
    gap: 10px;
`;

const IconButton = styled.button`
    background-color: rgba(130, 22, 146, 0.1);
    color: rgba(130, 22, 146, 1);
    border: none;
    padding: 10px 15px;
    border-radius: 8px;
    font-size: 1rem;
    font-weight: bold;
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;

    .icon {
        font-size: 1.5rem;
    }

    &:hover {
        background-color: rgba(130, 22, 146, 0.2);
    }

    @media (max-width: 768px) {
        font-size: 0.9rem;
    }
`;

const Legend = styled.div`
    margin: 20px 0;
    display: flex;
    gap: 15px;

    @media (max-width: 768px) {
        flex-wrap: wrap;
        gap: 10px;
    }
`;

const LegendItem = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`;

const LegendColor = styled.span`
    display: inline-block;
    width: 20px;
    height: 20px;
    background-color: ${({ color }) => color};
    border: 1px solid ${({ border }) => border};
`;
