import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Tabs, Tab, Button, Modal } from 'react-bootstrap';
import {
    fetchOrganizationById,
    fetchVenuesByOrganization,
    fetchUsersByOrganization,
    addVenueToOrganization, addUserToOrganization,
    updateVenue
} from '../../services/Http/Api';
import OrganizationForm from "../global/Organization/OrganizationForm";
import UsersList from "./Users/UsersList";
import VenuesList from "./Venues/VenuesList";

const OrganizationDetail = () => {
    const { id } = useParams();
    const [organization, setOrganization] = useState(null);
    const [venues, setVenues] = useState([]);
    const [users, setUsers] = useState([]);

    useEffect(() => {
        fetchOrganizationById(id).then(data => setOrganization(data));
        fetchVenuesByOrganization(id).then(data => setVenues(data));
        fetchUsersByOrganization(id).then(data => setUsers(data));
    }, [id]);

    const handleVenueSave = async (venueData) => {
        try {
            const newVenue = await addVenueToOrganization(id, venueData);
            setVenues(prevVenues => [...prevVenues, newVenue]);
        } catch (error) {
            console.error('Failed to add venue:', error);
        }
    };

    if (!organization) {
        return <div>Loading...</div>;
    }

    return (
        <div className="container mt-4">
            <Tabs defaultActiveKey="details" id="organization-details-tabs">
                <Tab eventKey="details" title="Details">
                    <OrganizationForm organization={organization} />
                </Tab>
                <Tab eventKey="venues" title="Venues">
                    <VenuesList
                        fetchVenues={() => fetchVenuesByOrganization(id)}
                        addVenue={addVenueToOrganization}
                        updateVenue={updateVenue}
                        organizationId={id}
                    />
                </Tab>
                <Tab eventKey="users" title="Users">
                    <UsersList configParameter="organization" belongingId={id} />
                </Tab>
            </Tabs>
        </div>
    );
};

export default OrganizationDetail;
