import styled from 'styled-components';

const StyledCard = styled.div`
    background-color: ${({ theme }) => theme.colors.cardBackground || '#f8f9fa'};
    padding: ${({ theme }) => theme.spacing.medium || '20px'};
    margin-bottom: ${({ theme }) => theme.spacing.medium || '1.5rem'};
    border: 1px solid ${({ theme }) => theme.colors.border || 'rgba(0, 0, 0, 0.1)'};
    border-radius: ${({ theme }) => theme.borderRadius || '10px'};
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 100%;
`;

export default StyledCard;
