import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { fetchVenueById, updateVenue } from '../../../services/Http/Api';
import VenueDetails from './VenueDetails';
import VenueStatistic from './VenueStatistic';
import VenueIntegration from './VenueIntegration';
import VenueForm from '../../global/Venue/VenueForm';
import { useTranslation } from 'react-i18next';
import QuestionModal from "../../global/QuestionModal";
import { MdHelpOutline, MdInfoOutline } from "react-icons/md";
import styled from 'styled-components';

const VenueView = () => {
    const { id } = useParams();
    const { t } = useTranslation();
    const [venue, setVenue] = useState(null);
    const [loading, setLoading] = useState(true);
    const [activeTab, setActiveTab] = useState('details');
    const [showQuestionModal, setShowQuestionModal] = useState(false);
    const [showTutorialModal, setShowTutorialModal] = useState(false);

    useEffect(() => {
        const loadVenue = async () => {
            try {
                const response = await fetchVenueById(id);
                setVenue(response);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching venue:', error);
            }
        };

        loadVenue();
    }, [id]);

    const handleSave = async (updatedVenueData) => {
        try {
            await updateVenue(id, updatedVenueData);
        } catch (error) {
            console.error('Error saving venue:', error);
        }
    };

    if (loading) {
        return <Loading>{t('Venues.VenueView.loading')}</Loading>;
    }

    return (
        <Container>
            <Header>
                <Title>{t('Venues.VenueView.title', { name: venue.name })}</Title>
                <ButtonGroup>
                    <IconButton onClick={() => setShowQuestionModal(true)}>
                        <MdHelpOutline className="icon" />
                    </IconButton>
                    <IconButton onClick={() => setShowTutorialModal(true)}>
                        <MdInfoOutline className="icon" />
                    </IconButton>
                </ButtonGroup>
            </Header>

            <Tabs>
                <Tab active={activeTab === 'details'} onClick={() => setActiveTab('details')}>
                    {t('Venues.VenueView.tabs.details')}
                </Tab>
                <Tab active={activeTab === 'statistics'} onClick={() => setActiveTab('statistics')}>
                    {t('Venues.VenueView.tabs.statistics')}
                </Tab>
                <Tab active={activeTab === 'integration'} onClick={() => setActiveTab('integration')}>
                    {t('Venues.VenueView.tabs.integration')}
                </Tab>
                <Tab active={activeTab === 'edit'} onClick={() => setActiveTab('edit')}>
                    {t('Venues.VenueView.tabs.edit')}
                </Tab>
            </Tabs>

            <Content>
                {activeTab === 'details' && <VenueDetails venue={venue} />}
                {activeTab === 'statistics' && <VenueStatistic venueId={venue.id} />}
                {activeTab === 'integration' && <VenueIntegration venueId={venue.id} />}
                {activeTab === 'edit' && <VenueForm venue={venue} onSave={handleSave} />}
            </Content>

            <QuestionModal
                show={showQuestionModal}
                onClose={() => setShowQuestionModal(false)}
                param="venue_overview"
            />

            {showTutorialModal && (
                <TutorialModal>
                    <ModalHeader>
                        <ModalTitle>{t('Venues.VenueView.Tutorial.title')}</ModalTitle>
                        <CloseButton onClick={() => setShowTutorialModal(false)}>&times;</CloseButton>
                    </ModalHeader>
                    <ModalBody>
                        <TutorialCard>
                            <h5>{t('Venues.VenueView.Tutorial.details_title')}</h5>
                            <p>{t('Venues.VenueView.Tutorial.details_message')}</p>
                        </TutorialCard>
                        <TutorialCard>
                            <h5>{t('Venues.VenueView.Tutorial.statistics_title')}</h5>
                            <p>{t('Venues.VenueView.Tutorial.statistics_message')}</p>
                        </TutorialCard>
                        <TutorialCard>
                            <h5>{t('Venues.VenueView.Tutorial.integration_title')}</h5>
                            <p>{t('Venues.VenueView.Tutorial.integration_message')}</p>
                        </TutorialCard>
                        <TutorialCard>
                            <h5>{t('Venues.VenueView.Tutorial.edit_title')}</h5>
                            <p>{t('Venues.VenueView.Tutorial.edit_message')}</p>
                        </TutorialCard>
                    </ModalBody>
                </TutorialModal>
            )}
        </Container>
    );
};

export default VenueView;

// Styled Components
const Container = styled.div`
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

    @media (max-width: 768px) {
        padding: 10px;
    }
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 15px;
    border-bottom: 2px solid rgba(130, 22, 146, 0.8);
`;

const Title = styled.h1`
    font-size: 1.8rem;
    color: rgba(130, 22, 146, 1);

    @media (max-width: 768px) {
        font-size: 1.5rem;
    }
`;

const ButtonGroup = styled.div`
    display: flex;
    gap: 10px;
`;

const IconButton = styled.button`
    background-color: rgba(130, 22, 146, 0.1);
    color: rgba(130, 22, 146, 1);
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    .icon {
        font-size: 1.5rem;
    }

    &:hover {
        background-color: rgba(130, 22, 146, 0.2);
    }
`;

const Tabs = styled.div`
    display: flex;
    margin-top: 20px;
    border-bottom: 2px solid rgba(130, 22, 146, 0.2);
`;

const Tab = styled.button`
    flex: 1;
    padding: 10px;
    border: none;
    background: ${({ active }) => (active ? '#fff' : 'transparent')};
    border-bottom: ${({ active }) =>
    active ? '4px solid rgba(130, 22, 146, 1)' : 'none'};
    color: ${({ active }) => (active ? 'rgba(130, 22, 146, 1)' : '#333')};
    font-weight: bold;
    cursor: pointer;
    text-align: center;

    &:hover {
        color: rgba(130, 22, 146, 1);
    }
`;

const Content = styled.div`
    margin-top: 20px;
`;

const TutorialModal = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1050;
    width: 600px;
    max-width: 90%;
`;

const ModalHeader = styled.div`
    padding: 15px;
    background-color: rgba(130, 22, 146, 0.1);
    border-bottom: 1px solid rgba(130, 22, 146, 0.2);
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const ModalTitle = styled.h5`
    margin: 0;
    color: rgba(130, 22, 146, 1);
`;

const CloseButton = styled.button`
    background: none;
    border: none;
    font-size: 1.5rem;
    color: #333;

    &:hover {
        color: rgba(130, 22, 146, 1);
    }
`;

const ModalBody = styled.div`
    padding: 20px;
`;

const TutorialCard = styled.div`
    padding: 20px;
    background: #f8f9fa;
    border-radius: 8px;
    margin-bottom: 15px;
    color: #333;

    h5 {
        color: rgba(130, 22, 146, 1);
        margin-bottom: 10px;
    }

    p {
        margin: 0;
        font-size: 1rem;
        line-height: 1.5;
    }
`;

const Loading = styled.div`
    text-align: center;
    font-size: 1.5rem;
    color: rgba(130, 22, 146, 1);
`;
