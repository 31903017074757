import React from 'react';
import styled from 'styled-components';

const TutorialModal = ({ show, onClose, title, children }) => {
    if (!show) return null; // Render nothing if the modal is not visible

    return (
        <Overlay>
            <ModalContainer>
                <ModalHeader>
                    <ModalTitle>{title}</ModalTitle>
                    <CloseButton onClick={onClose}>&times;</CloseButton>
                </ModalHeader>
                <ModalBody>{children}</ModalBody>
            </ModalContainer>
        </Overlay>
    );
};

export default TutorialModal;

// Styled Components
const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5); /* Dimmed background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1050; /* Ensure it's above other content */
`;

const ModalContainer = styled.div`
    width: 90%;
    max-width: 800px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    position: relative;
`;

const ModalHeader = styled.div`
    background-color: rgba(130, 22, 146, 0.1);
    border-bottom: 1px solid rgba(130, 22, 146, 0.2);
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const ModalTitle = styled.h5`
    font-size: 1.5rem;
    color: rgba(130, 22, 146, 1);
    font-weight: bold;
    margin: 0;
`;

const CloseButton = styled.button`
    background: none;
    border: none;
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
    cursor: pointer;

    &:hover {
        color: rgba(130, 22, 146, 1);
    }
`;

const ModalBody = styled.div`
    padding: 20px;
    background-color: #f8f9fa;
    overflow-y: auto;
    max-height: 75vh; /* Prevents modal from growing too large */
    border-radius: 0 0 10px 10px;
`;
