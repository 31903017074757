import styled from 'styled-components';

const StyledMainButton = styled.button`
    padding: ${(props) => props.padding || '10px 20px'};
    font-size: ${(props) => props.fontSize || '16px'};
    cursor: pointer;
    border: ${(props) => props.border || 'none'};
    border-radius: ${(props) => props.borderRadius || '8px'};
    background-color: ${(props) => props.bgColor || '#007bff'};
    color: ${(props) => props.color || '#fff'};
    transition: background-color 0.3s, transform 0.2s;

    &:hover {
        background-color: ${(props) => props.hoverBgColor || '#0056b3'};
        transform: scale(1.05);
    }

    &:disabled {
        background-color: ${(props) => props.disabledBgColor || '#ccc'};
        cursor: not-allowed;
    }
`;

const MainButton = ({ children, ...props }) => {
    return <StyledMainButton {...props}>{children}</StyledMainButton>;
};

export default MainButton;
