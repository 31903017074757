import React from 'react';
import styled from 'styled-components';

const LoginForm = ({ email, setEmail, password, setPassword, onSubmit, error }) => {
    return (
        <Form onSubmit={onSubmit}>
            <FormGroup>
                <label>Email</label>
                <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
            </FormGroup>
            <FormGroup>
                <label>Password</label>
                <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
            </FormGroup>
            {error && <ErrorMessage>{error}</ErrorMessage>}
            <SubmitButton type="submit">Login</SubmitButton>
        </Form>
    );
};

const Form = styled.form`
    display: flex;
    flex-direction: column;
    gap: 15px;
`;

const FormGroup = styled.div`
    display: flex;
    flex-direction: column;

    label {
        margin-bottom: 5px;
        font-weight: bold;
        color: ${({ theme }) => theme.colors.textPrimary};
    }

    input {
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 5px;
        font-size: 1rem;
        outline: none;
        transition: border-color 0.3s;

        &:focus {
            border-color: ${({ theme }) => theme.colors.brandLight};
        }
    }
`;

const ErrorMessage = styled.div`
    color: red;
    font-size: 0.9rem;
    margin-bottom: 10px;
`;

const SubmitButton = styled.button`
    background-color: ${({ theme }) => theme.colors.brand};
    color: #fff;
    border: none;
    padding: 10px;
    border-radius: 5px;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;

    &:hover {
        background-color: ${({ theme }) => theme.colors.brandLight};
    }
`;

export default LoginForm;
