import React, { useContext, useEffect, useState, useMemo } from 'react';
import AsyncSelect from 'react-select/async';
import { FormGroup, Label, Input } from 'reactstrap';
import { fetchItems } from "../../../../services/Http/Api";
import { SettingsContext } from "../../../SettingsContext";

const ItemSelectorComponent = ({ value, operator, onChange, target, type, attribute }) => {
    const { siteSettings } = useContext(SettingsContext);
    const [selectedItems, setSelectedItems] = useState([]); // Store selected items

    // Get supported operators based on target, type, and attribute
    const supportedOperators = useMemo(() => {
        if (target === 'conditions') {
            const conditionTypes = siteSettings?.getConditionTypes() || [];
            const selectedConditionType = conditionTypes.find(condType => condType.key === type);

            if (selectedConditionType) {
                const selectedAttribute = selectedConditionType.getAttributes().find(attr => attr.key === attribute);
                return selectedAttribute?.supportedOperators || [];
            }
        }
        return [];
    }, [siteSettings, target, type, attribute]);

    // Fetch initial items on component mount
    useEffect(() => {
        const fetchInitialItems = async () => {
            try {
                const response = await fetchItems({ search: '', perPage: 100, page: 1 });
                const options = response.data.map(item => ({
                    value: item.id,
                    label: item.product_name,
                }));

                if (value) {
                    let itemIds = [];
                    try {
                        itemIds = JSON.parse(value);
                    } catch (error) {
                        console.error('Invalid JSON format for item IDs:', error);
                        itemIds = [];
                    }

                    const preSelectedItems = options.filter(option => itemIds.includes(option.value));
                    setSelectedItems(preSelectedItems);
                }
            } catch (error) {
                console.error('Error fetching initial items:', error);
            }
        };

        fetchInitialItems(); // Fetch items once on mount
    }, []); // Only runs once on mount

    // Custom debounce function
    const debouncePromise = (func, delay) => {
        let timer;
        return (...args) => {
            return new Promise((resolve) => {
                if (timer) clearTimeout(timer);
                timer = setTimeout(() => resolve(func(...args)), delay);
            });
        };
    };

    // Load item options based on user search input
    const loadOptions = async (inputValue) => {
        try {
            const response = await fetchItems({ search: inputValue, perPage: 10, page: 1 });
            const options = response.data.map(item => ({
                value: item.id,
                label: item.product_name,
            }));
            return options;
        } catch (error) {
            console.error('Error fetching items:', error);
            return [];
        }
    };

    // Debounced loadOptions function
    const debouncedLoadOptions = useMemo(() => debouncePromise(loadOptions, 700), []);

    // Handle the selection change
    const handleSelectChange = (selected) => {
        if (!selected || selected.length === 0) {
            setSelectedItems([]);
            onChange('value', JSON.stringify([])); // Update parent component with an empty array
            return;
        }

        setSelectedItems(selected); // Update the local state with the selected items
        const selectedValues = selected.map(opt => opt.value);
        onChange('value', JSON.stringify(selectedValues)); // Update the parent component with JSON string
    };

    return (
        <FormGroup>
            {/* Operator Selection */}
            <Label for="operator">Operator</Label>
            <Input
                type="select"
                id="operator"
                value={operator || ''}
                onChange={(e) => onChange('operator', e.target.value)}
            >
                <option value="">Select Operator</option>
                {supportedOperators.map(op => (
                    <option key={op} value={op}>{op}</option>
                ))}
            </Input>

            <Label className="mt-2" for="value">Item</Label>
            <AsyncSelect
                id="value"
                isMulti
                cacheOptions={false} // Disable caching for reliable fetching
                defaultOptions // Use default options for quicker initial results
                loadOptions={debouncedLoadOptions} // Debounced loadOptions function
                value={selectedItems} // Controlled value for multiple items
                onChange={handleSelectChange}
                placeholder="Search and select items"
                noOptionsMessage={() => "No items available"}
            />
        </FormGroup>
    );
};

export default ItemSelectorComponent;
