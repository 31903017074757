import React, { useEffect, useState, useMemo } from 'react';
import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import { fetchCampaigns } from '../../../services/Http/Api';
import { MdEdit, MdDelete, MdAdd, MdHelpOutline, MdInfoOutline } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import QuestionModal from '../../global/QuestionModal';

const CampaignList = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [campaigns, setCampaigns] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRows, setTotalRows] = useState(0);
    const [showQuestionModal, setShowQuestionModal] = useState(false);
    const [showTutorialModal, setShowTutorialModal] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetchCampaigns({
                    page: currentPage,
                    perPage,
                    search: searchTerm,
                });
                const { data, total } = response.data;
                setCampaigns(data);
                setTotalRows(total);
            } catch (error) {
                console.error('Error fetching campaigns:', error);
            }
        };

        fetchData();
    }, [currentPage, perPage, searchTerm]);

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
        setResetPaginationToggle(!resetPaginationToggle);
    };

    const handleAddNew = () => {
        navigate('/campaigns/create');
    };

    const handleEditCampaign = (id) => {
        navigate(`/campaigns/edit/${id}`);
    };

    const handleDeleteCampaign = (id) => {
        console.log('Deleting campaign with ID:', id);
    };

    const columns = useMemo(
        () => [
            { name: t('Campaigns.name'), selector: row => row.name || 'N/A', sortable: true },
            { name: t('Campaigns.description'), selector: row => row.description || 'N/A', sortable: true },
            { name: t('Campaigns.startDate'), selector: row => row.start_date || 'N/A', sortable: true },
            { name: t('Campaigns.endDate'), selector: row => row.end_date || 'N/A', sortable: true },
            { name: t('Campaigns.goalDescription'), selector: row => row.goal_description || 'N/A', sortable: true },
            { name: t('Campaigns.budget'), selector: row => row.budget || 'N/A', sortable: true },
            {
                name: t('Campaigns.actions'),
                cell: row => (
                    <ActionButtons>
                        <MdEdit className="edit-icon" onClick={() => handleEditCampaign(row.id)} />
                        <MdDelete className="delete-icon" onClick={() => handleDeleteCampaign(row.id)} />
                    </ActionButtons>
                ),
                ignoreRowClick: true,
                allowOverflow: true,
                button: true,
            },
        ],
        [t]
    );

    return (
        <Container>
            <Header>
                <SearchInput
                    type="text"
                    placeholder={t('Campaigns.search')}
                    value={searchTerm}
                    onChange={handleSearchChange}
                />
                <AddButton onClick={handleAddNew}>
                    <MdAdd size={20} />
                    {t('Campaigns.addNew')}
                </AddButton>
            </Header>
            <IconButtonGroup>
                <IconButton onClick={() => setShowQuestionModal(true)}>
                    <MdHelpOutline className="icon" />
                </IconButton>
                <IconButton onClick={() => setShowTutorialModal(true)}>
                    <MdInfoOutline className="icon" />
                </IconButton>
            </IconButtonGroup>
            <DataTable
                columns={columns}
                data={campaigns}
                pagination
                paginationServer
                persistTableHead
                highlightOnHover
                resetPaginationToggle={resetPaginationToggle}
                paginationTotalRows={totalRows}
                onChangePage={(page) => setCurrentPage(page)}
                onChangeRowsPerPage={(newPerPage, newPage) => {
                    setPerPage(newPerPage);
                    setCurrentPage(newPage);
                }}
            />
            <QuestionModal
                show={showQuestionModal}
                onClose={() => setShowQuestionModal(false)}
                param="campaigns_overview"
            />
        </Container>
    );
};

export default CampaignList;

// Styled Components
const Container = styled.div`
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

    @media (max-width: 768px) {
        padding: 10px;
    }
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
`;

const SearchInput = styled.input`
    padding: 10px;
    font-size: 1rem;
    border: 1px solid rgba(130, 22, 146, 0.4);
    border-radius: 5px;
    width: 100%;
    max-width: 300px;

    &:focus {
        outline: none;
        border-color: rgba(130, 22, 146, 0.8);
        box-shadow: 0 0 5px rgba(130, 22, 146, 0.5);
    }
`;

const AddButton = styled.button`
    display: flex;
    align-items: center;
    background-color: rgba(130, 22, 146, 1);
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;

    svg {
        margin-right: 5px;
    }

    &:hover {
        background-color: rgba(110, 12, 136, 1);
    }
`;

const IconButtonGroup = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-bottom: 15px;
`;

const IconButton = styled.button`
    background: none;
    border: none;
    cursor: pointer;

    .icon {
        font-size: 1.5rem;
        color: rgba(130, 22, 146, 1);
    }

    &:hover {
        opacity: 0.8;
    }
`;

const ActionButtons = styled.div`
    display: flex;
    gap: 10px;

    .edit-icon {
        color: rgba(130, 22, 146, 1);
        cursor: pointer;
        font-size: 1.2rem;

        &:hover {
            color: rgba(110, 12, 136, 1);
        }
    }

    .delete-icon {
        color: #d9534f;
        cursor: pointer;
        font-size: 1.2rem;

        &:hover {
            color: #c9302c;
        }
    }
`;
