import React from 'react';
import styled from 'styled-components';

const Options = ({ onRegisterClick, onForgotPasswordClick }) => {
    return (
        <OptionsContainer>
            <OptionButton disabled onClick={onRegisterClick}>
                Register
            </OptionButton>
            <OptionButton disabled onClick={onForgotPasswordClick}>
                Forgot Password
            </OptionButton>
        </OptionsContainer>
    );
};

const OptionsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
`;

const OptionButton = styled.button`
    background: none;
    color: ${({ theme }) => theme.colors.brand};
    border: none;
    cursor: pointer;
    font-size: 0.9rem;
    font-weight: bold;
    text-decoration: underline;
    transition: color 0.3s;

    &:disabled {
        color: ${({ theme }) => theme.colors.brandLight};
        cursor: not-allowed;
    }

    &:hover:not(:disabled) {
        color: ${({ theme }) => theme.colors.brand};
    }
`;

export default Options;
