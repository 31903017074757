import React, { useContext } from 'react';
import { FormGroup, Label, Input } from 'reactstrap';
import { SettingsContext } from "../../SettingsContext";

const StrategyComponent = ({ strategy, onChange }) => {
    const { siteSettings } = useContext(SettingsContext);
    const strategyTypes = siteSettings?.getStrategyTypes() || [];

    // Find the selected strategy type and attribute based on IDs
    const selectedType = strategy?.strategyTypeId
        ? strategyTypes.find(type => type.id === strategy.strategyTypeId)
        : null;

    // Get the selected attribute from strategy.values
    const selectedAttributeId = strategy?.values && strategy.values.length ? strategy.values[0].attributeId : '';
    const selectedAttribute = selectedType?.attributes?.find(attr => attr.id === selectedAttributeId);

    // Handle type change
    const handleTypeChange = (event) => {
        const newTypeId = event.target.value;
        onChange('strategyTypeId', newTypeId);
        onChange('values', []); // Reset values
    };

    // Handle attribute change
    const handleAttributeChange = (event) => {
        const newAttributeId = event.target.value;

        // Find the selected attribute from selectedType attributes
        const selectedAttr = selectedType?.attributes?.find(attr => attr.id === newAttributeId);

        // Set the initial value based on the data type
        const newValue = selectedAttr?.dataType === 'boolean' ? 'true' : '';

        // Update the value with the new attributeId and set the initial value
        const updatedValues = [
            {
                id: null,
                promotionStrategyId: strategy.id,
                attributeId: newAttributeId,
                value: newValue,
            },
        ];

        onChange('values', updatedValues);
    };

    // Handle value change
    const handleValueChange = (event) => {
        const newValue = event.target.type === 'checkbox' ? event.target.checked.toString() : event.target.value;
        const updatedValues = strategy.values.map((val) =>
            val.attributeId === selectedAttributeId ? { ...val, value: newValue } : val
        );

        onChange('values', updatedValues);
    };

    return (
        <FormGroup className="mb-2 border p-3">
            {/* Select Strategy Type */}
            <Label for="strategyType">Strategy Type</Label>
            <Input
                type="select"
                id="strategyType"
                value={strategy?.strategyTypeId || ''}
                onChange={handleTypeChange}
            >
                <option value="">Select Strategy Type</option>
                {strategyTypes.map(type => (
                    <option key={type.id} value={type.id}>
                        {type.name}
                    </option>
                ))}
            </Input>

            {/* Select Attribute */}
            {selectedType && (
                <>
                    <Label className="mt-2" for="strategyAttribute">Strategy Attribute</Label>
                    <Input
                        type="select"
                        id="strategyAttribute"
                        value={selectedAttributeId || ''}
                        onChange={handleAttributeChange}
                    >
                        <option value="">Select Attribute</option>
                        {selectedType.attributes.map(attr => (
                            <option key={attr.id} value={attr.id}>
                                {attr.label || attr.name}
                            </option>
                        ))}
                    </Input>
                </>
            )}

            {/* Render value for the selected attribute */}
            {selectedAttribute && selectedAttribute.dataType !== 'boolean' && (
                <div className="mt-3">
                    <Label className="mt-2" for="attributeValue">{selectedAttribute.label}</Label>
                        <Input
                            type="text"
                            id="attributeValue"
                            value={strategy.values.length ? strategy.values[0].value : ''}
                            onChange={handleValueChange}
                        />
                </div>
            )}

            {/* Additional Global Settings */}
            <div className="mt-3">
                <Label for="globalUsers">Apply to Global Users</Label>
                <Input
                    type="checkbox"
                    id="globalUsers"
                    checked={strategy.globalUsers || false}
                    onChange={(e) => onChange('globalUsers', e.target.checked)}
                />
            </div>

            <div className="mt-3">
                <Label for="frequencyCap">Frequency Cap</Label>
                <Input
                    type="number"
                    id="frequencyCap"
                    value={strategy.frequencyCap || ''}
                    onChange={(e) => onChange('frequencyCap', e.target.value)}
                    placeholder="Enter frequency cap"
                />
            </div>

            <div className="mt-3">
                <Label for="impressionLimit">Impression Limit</Label>
                <Input
                    type="number"
                    id="impressionLimit"
                    value={strategy.impressionLimit || ''}
                    onChange={(e) => onChange('impressionLimit', e.target.value)}
                    placeholder="Enter impression limit"
                />
            </div>
        </FormGroup>
    );
};

export default StrategyComponent;
