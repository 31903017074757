// src/lang/en.js
export default {
    LoginPage: {
        "email_label": "Email",
        "password_label": "Password",
        "login_button": "Login",
        "login_error": "Failed to login. Check email and password.",
        "register": "Register",
        "forgot_password": "Forgot password",
        Tutorial: {
            "welcome_title": "Welcome to GSOFT Demo!",
            "welcome_message": "Welcome to the demo of our application! Feel free to explore and experience the features we have implemented.",
            "info_message": "If you ever feel lost or don't understand something, you can click on the info icon to find an explanation of every page and segment.",
            "demo_credentials": "For testing, you can use: \n - Email: organization1@dwm.com, Password: password \n - Email: organization2@dwm.com, Password: password. \nOrganization 1 has 6k users, and Organization 2 has 4k users, simulating how users from one organization interact with another.",
            "about_app":"This application is designed to streamline the management of loyalty programs for retailers by integrating digital warranties for purchased items and facilitating campaign management for promotions. It offers powerful analytical tools to help users track market trends effectively. By consolidating points management, discounts, and warranties into a single platform, users can eliminate the hassle of handling paper warranties and receipts. This innovative approach ensures a seamless experience, allowing customers to easily manage their loyalty rewards and warranty information—all within one convenient app.",
            "about_app_title":"About the App",
            "info_title": "Information",
            "credentials_title": "Demo Credentials",
            "question_title": "Have Questions?",
            "question_message": "If you have any questions about the system or page, just click the question mark icon and write us your question. Someone will answer you soon.",
            "development_message":"If you this icon it means this feature is currently under development."
        },
    },
    Navigation: {
        "dashboard": "Dashboard",
        "users": "Users",
        "transactions": "Transactions",
        "venues": "Venues",
        "items": "Items",
        "warranties": "Warranties",
        "warranties_templates": "Warranties Templates",
        "campaigns": "Campaigns",
        "pos_integration": "POS Integration",
        "settings": "Settings",
        authorizedServices: "Services",
        "logout": "Logout"
    },
    Dashboard: {
        title: 'Dashboard',
        loading: 'Loading...',
        error: 'Error loading dashboard data',
        average_revenue_per_user: 'Average Revenue per User',
        average_selling_transaction_value: 'Average Selling Transaction Value',
        belonging_users_count: 'Belonging Users Count',
        campaigns_count: 'Campaigns Count',
        manufacturer_count: 'Manufacturer Count',
        revenue_value: 'Revenue Value',
        selling_transactions_count: 'Selling Transactions Count',
        sold_items_count: 'Sold Items Count',
        total_transaction_volume: 'Total Transaction Volume',
        users_count: 'Users Count',
        venues_count: 'Venues Count',
        Tutorial: {
            "title": "Dashboard Tutorial",
            "explanation_title": "Overview",
            "explanation_message": "This dashboard displays your organization's statistics from the day of registration. You can customize the view according to your preferences."
        }
    },
    Users: {
        title: "Users",
        Tutorial: {
            title: "Users Tutorial",
            explanation_title: "Overview",
            explanation_message: "There are two types of users within organizations: users registered through their own organization and users registered through other organizations. Due to stricter privacy rules in the full version, some fields will not be visible for users who do not belong to the organization. Statistical overviews can be customized according to the organization's needs, allowing them to choose which information they want to track.",
        },
        statistics: "Statistics",
        timeline: "Timeline",
        usersList: "Users List",
        UserStatistics: {
            loading: "Loading statistics...",
            noStatistics: "No statistics available.",
            overviewTitle: "Statistics Overview",
            totalBuyers: "Total Buyers",
            totalBuyersOwn: "Total Buyers (Own)",
            totalBuyersGlobal: "Total Buyers (Global)",
            totalRevenueOwn: "Total Revenue (Own)",
            totalRevenueGlobal: "Total Revenue (Global)",
            topBuyersByItems: "Top Buyers by Items",
            items: "Items",
            own: "Own",
            global: "Global",
            topBuyersByTotalSpent: "Top Buyers by Total Spent",
            salesByCountry: {
                title: "Sales by Countries",
                userCount: "{{userCount}} users" // Placeholder for user count
            }
        },
        Timeline: {
            title: "User Registrations by Month",
            loading: "Loading timeline data...",
            error: "Error loading timeline data",
            xAxisTitle: "Month",
            yAxisTitle: "Number of Registrations"
        },
        UsersList: {
            title: "Users",
            addUserButton: "Add User",
            legend: "Legend:",
            ownUser: "Own User",
            globalUser: "Global User",
            loading: "Loading...",
            noUsers: "No users available.",
            modalTitle: "Add User",
            columns: {
                userId: "User ID",
                name: "Name",
                totalSpent: "Total Spent",
                firstTransactionDate: "First Transaction Date",
                lastTransactionDate: "Last Transaction Date",
                countOfItems: "Count of Items",
                venuesCount: "Venues Count"
            }
        },
        UserCreate: {
            title: "Add User",
            name: "Name",
            surname: "Surname",
            email: "Email",
            country: "Country",
            phoneNumber: "Phone Number",
            personalId: "Personal ID",
            gender: "Gender",
            selectCountry: "Select Country",
            selectGender: "Select Gender",
            cancel: "Cancel",
            save: "Save",
            saving: "Saving...",
            errorAlert: "Please fix the errors below."
        }
    },
    PhoneInput: {
        selectCountryCode: "Select Country Code",
        placeholder: "Enter Phone Number"
    },
    UserInfoModal: {
        title: "Enter Your Information",
        explanation: "Please enter your Name/Company Name and Email. Your email will only be used to respond to any questions you may have about the system during your demo.",
        name: "Name/Company Name",
        namePlaceholder: "Enter your Name or Company Name",
        email: "Email",
        emailPlaceholder: "Enter your Email",
        cancel: "Cancel",
        save: "Save",
        explanationSub:"You can leave this fields empty if you do not have any questions at this time.",
        errors: {
            "generic": "Please fix the errors below.",
            "nameRequired": "Name is required.",
            "emailRequired": "Email is required."
        }
    },
    QuestionModal: {
        title: "Submit Your Question",
        explanation: "Please fill in the information below.",
        name: "Name/Company Name",
        namePlaceholder: "Enter your Name or Company Name",
        email: "Email",
        emailPlaceholder: "Enter your Email",
        question: "Question",
        questionPlaceholder: "Enter your Question here",
        cancel: "Cancel",
        save: "Submit",
        saving: "Submitting...",
        errors: {
            generic: "Please fix the errors below.",
            questionRequired: "Question is required."
        }
    },
    Transactions: {
        title: "Transactions",
        overview: "Overview",
        statistics: "Statistics",
        progress: "Progress",
        loading: "Loading transactions...",
        noTransactions: "No transactions available.",
        Tutorial: {
            title: "Transactions Tutorial",
            welcome_message: "Welcome to the Transactions tutorial! Here’s what you need to know.",
            period_selection_title: "Period Selection",
            period_selection_message: "You can choose the period for the details shown in the right corner of the page. The selected period is shared across each tab you select.",
            overview_tab_title: "Overview Tab",
            overview_tab_message: "The first Overview tab shows the transactions grouped by date and summarizes that period in the headers.",
            statistics_tab_title: "Statistics Tab",
            statistics_tab_message: "The second tab shows user demographics and interactions with the organization, such as genders, countries where items were purchased, the countries where users are from, age groups of users, and cities.",
            progress_tab_title: "Progress Tab",
            progress_tab_message: "The third tab shows a comparison of the selected period. For instance, if 'Yesterday' is selected, it compares data with the day before yesterday. If 'Last Week' is selected, it compares data with the previous week, and similarly for 'This Month,' 'This Quarter,' and 'This Year.'",
            customization_title: "Customization",
            customization_message: "All these features can be customized and enriched even more with upcoming features such as filtering and targeting."
        },
        TransactionsOverview: {
            loading: "Loading transactions...",
            noStatistics: "No transaction statistics available.",
            distinctManufacturers: "Distinct Manufacturers",
            totalPrice: "Total Price",
            totalRevenue: "Total Revenue",
            totalTransactions: "Total Transactions",
            distinctBuyers: "Distinct Buyers",
            columns: {
                date: "Date",
                totalPrice: "Total Price",
                totalRevenue: "Total Revenue",
                averagePrice: "Average Price",
                averageRevenue: "Average Revenue",
                transactionCount: "Transaction Count",
            },
            questionTitle: "Have Questions?",
            questionMessage: "If you have any questions about the transactions or data displayed, please click the question mark icon to submit your question.",
        },
        TransactionsStatistics: {
            loading: "Loading demographics data...",
            error: "Error loading demographics data: {{error}}",
            title: "Transactions Demographics",
            genderDistribution: "Gender Distribution",
            countryDistribution: "Country Distribution",
            ageGroupDistribution: "Age Group Distribution",
            cityDistribution: "City Distribution",
            residentialCountryDistribution: "Residential Country Distribution",
            questionTitle: "Have Questions?",
            questionMessage: "If you have any questions about the demographics or data displayed, please click the question mark icon to submit your question.",
        },
        TransactionsProgress: {
            title: "Transactions Progress",
            showingDataFor: "Showing data for: {{period}}",
            totals: "Totals",
            distinctManufacturers: "Distinct Manufacturers",
            totalPrice: "Total Price",
            totalRevenue: "Total Revenue",
            totalTransactions: "Total Transactions",
            distinctBuyerCount: "Distinct Buyer Count",
            demographics: "Demographics",
            ageGroups: "Age Groups",
            genderDistribution: "Gender Distribution",
            countryDistribution: "Country Distribution",
            cityDistribution: "City Distribution",
            residentialCountryDistribution: "Residential Country Distribution",
            currentPeriod: "Current Period",
            previousPeriod: "Previous Period",
            loading: "Loading...",
            error: "Error fetching progress data: {{error}}"
        },

    },
    Venues: {
        title: "Venues",
        overview: "Venue Overview",
        Tutorial: {
            title: "Venues Management Tutorial",
            welcome_message: "Welcome to the Venues Management tutorial.",
            venues_management_title: "Manage Your Venues",
            venues_management_message: "You can easily add, edit, or delete venues as needed.",
            exploration_title: "Explore Venue Details",
            exploration_message: "Click on 'Edit' to see what additional information and metrics you can track for each venue."
        },
        columns: {
            id: "ID",
            name: "Name",
            email: "Email",
            phone: "Phone",
            country: "Country",
            address: "Address",
            action: "Action"
        },
        search_placeholder: "Search by name or description",
        add_new_button: "Add New",
        VenueForm: {
            title_create: "Create New Venue",
            title_edit: "Edit Venue {{venueName}}",
            basic_info: "Basic Information",
            name_label: "Name:",
            name_placeholder: "Venue Name",
            description_label: "Description:",
            description_placeholder: "Venue Description",
            email_label: "Email:",
            email_placeholder: "Venue Email",
            website_label: "Website:",
            website_placeholder: "Venue Website",
            location_details: "Location Details",
            country_label: "Country:",
            select_country: "Select Country",
            address_label: "Address:",
            address_placeholder: "Venue Address",
            city_label: "City:",
            city_placeholder: "Venue City",
            latitude_label: "Latitude:",
            latitude_placeholder: "Latitude",
            longitude_label: "Longitude:",
            longitude_placeholder: "Longitude",
            social_media: "Social Media Links",
            twitter_label: "Twitter:",
            twitter_placeholder: "https://twitter.com/venue",
            facebook_label: "Facebook:",
            facebook_placeholder: "https://facebook.com/venue",
            instagram_label: "Instagram:",
            instagram_placeholder: "https://instagram.com/venue",
            save_button: "Save"
        },
        "VenueView": {
            "title": "Venue: {{name}}",
            "tabs": {
                "details": "Details",
                "statistics": "Statistics",
                "integration": "Integration",
                "edit": "Edit Venue"
            },
            "Tutorial": {
                "details_title": "Details Tab",
                "details_message": "This tab provides an overview of the current Venue's details.",
                "statistics_title": "Statistics Tab",
                "statistics_message": "This tab shows transactions grouped by day for the selected period in the top right corner. You will be able to download more detailed transactions as a CSV file, which will be enabled later.",
                "integration_title": "Integration Tab",
                "integration_message": "This section allows you to generate an API key and secret. These will be used if you want to integrate your system with just one Venue. If you need to integrate the entire system, please check the POS Integration part.",
                "edit_title": "Edit Tab",
                "edit_message": "In this tab, you can modify the details of the Venue. Make sure to save your changes."
            },
            "VenueStatistics": {
                "date": "Date",
                "total_price": "Total Price",
                "total_revenue": "Total Revenue",
                "total_quantity": "Total Quantity",
                "average_price": "Average Price",
                "average_revenue": "Average Revenue",
                "total_discount": "Total Discount",
                "total_tax": "Total Tax",
                "transaction_count": "Transaction Count",
                "total_transactions": "Total Transactions"
            },
            "VenueIntegration": {
                "api_integration": "API Integration",
                "api_key": "API Key",
                "generate_secret": "Generate API Secret",
                "generated_secret": "API Secret: {{secret}}",
                "secret_timer": "Secret will disappear in {{seconds}} seconds."
            },
            "VenueDetails": {
                "title": "Venue Details",
                "name_label": "Name:",
                "email_label": "Email:",
                "description_label": "Description:",
                "website_label": "Website:",
                "phone_label": "Phone:",
                "address_label": "Address:",
                "city_label": "City:",
                "country_label": "Country:",
                "latitude_label": "Latitude:",
                "longitude_label": "Longitude:",
                "organization_label": "Organization:",
                "social_media": "Social Media Links",
                "twitter": "Twitter",
                "facebook": "Facebook",
                "instagram": "Instagram"
            }
        }
    },
    Items: {
        "title": "Items",
        "overview": "Item Overview",
        "performance_insights": "Performance Insights",
        "warranty_alerts": "Warranty Alerts",
        "Tutorial": {
            "title": "Items Tutorial",
            "message": "This tutorial will guide you through the functionalities of the Items section."
        },
        "Overview": {
            "title": "Items Overview",
            "model_number": "Model Number",
            "product_name": "Product Name",
            "current_holding_count": "Current Holding Count",
            "sold_items_count": "Sold Items Count",
            "release_year": "Release Year",
            "warranty_coverage": "Warranty Coverage",
            "actions": "Actions",
            "view": "View"
        },
        "SearchFilters": {
            "search_placeholder": "Search by Model Number, Serial Number, or Product Name",
            "select_manufacturer": "Select Manufacturer",
            "search_button": "Search"
        },
        "ViewItem": {
            "tabs": {
                "description": "Description",
                "transactions": "Transactions",
                "charts": "Charts",
                "sold_items": "Sold Items"
            },
            "Tutorial": {
                "welcome_message": "Welcome to the Item View tutorial!",
                "tabs": {
                    "description": {
                        "title": "Description Tab",
                        "message": "Here you can view detailed information about the item."
                    },
                    "transactions": {
                        "title": "Transactions Tab",
                        "message": "This tab shows all transactions related to the item.",
                        "additional_message": "Please note that charts, timelines, and item details by user demographics will be available in future updates."
                    }
                },
            }
        },
        "ItemDescription": {
            "no_details": "No item details available",
            "model_number": "Model Number:",
            "description": "Description:",
            "release_year": "Release Year:",
            "warranty_duration": "Warranty Duration:",
            "warranty_coverage": "Warranty Coverage:",
            "documentation_url": "Documentation URL:",
            "view_documentation": "View Documentation",
            "technical_specifications": "Technical Specifications",
            "no_technical_specifications": "No technical specifications available",
            "year": "{{count}} year",
            "month": "{{count}} month"
        },
        "TransactionsTable": {
            "title": "Transactions for Item",
            "date": "Date",
            "buyer": "Buyer",
            "venue": "Venue",
            "quantity": "Quantity",
            "price": "Price",
            "revenue": "Revenue",
            "payment_method": "Payment Method",
            "transaction_type": "Transaction Type",
            "shipping_cost": "Shipping Cost",
            "warranty": "Warranty",
            "n_a": "N/A"
        }
    },
    Warranties: {
        warrantiesOverview: "Warranties Overview",
        warrantyTemplates: "Warranty Templates",
        searchWarranties: "Search Warranties",
        noWarranties: "No warranties found.",
        noTemplates: "No templates found.",
        searchByWarrantyName: "Search by Warranty Name",
        enterWarrantyName: "Enter Warranty Name",
        createWarrantyTemplate: "Create Warranty Template",
        templateName: "Template Name",
        templateDescription: "Template Description",
        selectManufacturer: "Select Manufacturer",
        selectCategory: "Select Category",
        createNewTemplate: "Create New Template",
        cancel: "Cancel",
        createTemplate: "Create Template",
        warrantyName: "Warranty Name",
        status: "Status",
        expirationDate: "Expiration Date",
        coverageDetails: "Coverage Details",
        termsAndConditions: "Terms and Conditions",
        documentation: "Documentation",
        view: "View",
        description: "Description",
        manufacturer: "Manufacturer",
        category: "Category",
        trackStatusTitle: "Tracking Warranty Status",
        trackStatusMessage: "You can track the status of your warranties through the overview and templates tabs.",
        searchWarrantiesTitle: "Searching Warranties",
        searchWarrantiesMessage: "Use the search functionality to filter warranties by name.",
        documentationTitle: "Documentation",
        documentationMessage: "Ensure you have the proper documentation for claims.",
        manageTemplatesTitle: "Managing Templates",
        manageTemplatesMessage: "Easily create and manage warranty templates.",
        welcomeMessage: "Welcome to the Warranties management section.",
        createTemplateTitle: "Creating Warranty Templates",
        createTemplateMessage: "You can create templates for different warranty types.",
    },
    AuthorizedServices: {
        overview: "Authorized Service Centers Overview",
        addService: "Add Authorized Service",
        addNewService: "Add New Service",
        noServices: "No authorized services available.",
        createService: "Create Authorized Service",
        serviceName: "Service Name",
        serviceAddress: "Service Address",
        servicePhone: "Service Phone",
        cancel: "Cancel",
        create: "Create",
    },
    "POSIntegration": {
        "pageTitle": "POS Integration",
        "apiTitle": "POS Integration API",
        "apiDescription": "API for integrating POS systems with our application.",
        "generateApiKeySummary": "Generate API Key",
        "apiKeyGenerated": "API Key generated",
        "importItemsSummary": "Import Items",
        "itemsImportedSuccessfully": "Items imported successfully"
    },
    "Campaigns": {
        "name": "Name",
        "description": "Description",
        "startDate": "Start Date",
        "endDate": "End Date",
        "goalDescription": "Goal Description",
        "budget": "Budget",
        "actions": "Actions",
        "edit": "Edit",
        "delete": "Delete",
        "addNew": "Add New Campaign",
        "search": "Search campaigns...",
        "noCampaigns": "No campaigns found.",
        "Tutorial": {
            "welcomeMessage": "Welcome to the Campaigns Management!",
            "createCampaignTitle": "Create New Campaign",
            "createCampaignMessage": "Here you can create a new campaign for your business needs.",
            "manageCampaignsTitle": "Manage Your Campaigns",
            "manageCampaignsMessage": "In this section, you can view, edit, and delete your existing campaigns. Use the search bar to quickly find a specific campaign.",
            "tutorialTitle": "Campaigns Tutorial",
            "tutorialMessage": "This tutorial will guide you through the process of managing campaigns effectively."
        },
        "CampaignCreate": {
            "title": "Create New Campaign",
            "name": "Campaign Name",
            "description": "Description",
            "startDate": "Start Date",
            "endDate": "End Date",
            "budget": "Budget",
            "goalDescription": "Goal Description",
            "createCampaign": "Create Campaign",
            "cancel": "Cancel",
            "loading": "Creating campaign..."
        },
        "CampaignEdit": {
            "editCampaignTitle": "Edit Campaign",
            "initialReach": "Initial Reach",
            "promotion": "Promotion",
            "reach": "Reach",
            "type": "Type",
            "price": "Price",
            "suggestions": "Suggestions",
            "noSuggestions": "No suggestions available.",
            "updateCampaign": "Update Campaign",
            "calculations": "Analysis & Suggestions",
            "calculate": "Calculate",
            "reaches": "Reaches",
            "prices": "Prices",
            "welcomeMessage": "Welcome to the Campaign Edit Page!",
            "managePromotions": "Manage Your Promotions",
            "successUpdate": "Campaign updated successfully",
            "failedUpdate": "Failed to update the campaign",
            "failedCalculation": "Failed to calculate the campaign data",
            "goalDescription": "Goal Description",
            "budget": "Budget",
            "startDate": "Start Date",
            "endDate": "End Date",
            "description": "Description",
            "placeholderGoalDescription": "Enter campaign goal description",
            "placeholderBudget": "Enter campaign budget",
            "placeholderStartDate": "Select start date",
            "placeholderEndDate": "Select end date",
            "placeholderDescription": "Enter campaign description",
            "placeholderName": "Enter campaign name",
            "name": "Campaign Name",
            "revenue": "Revenue",
            "strategy": "Strategy",
            "breakdown": "Campaign Breakdown",
            "target": "Target {index} Reach",
            "promotionLabel": "Promotion {index}",
            "initialReachPromotion": "Initial Reach: {value}",
            "strategyReach": "Strategy {index} (Type: {type}) Reach: {value}",
            "pricesLabel": "Prices",
            "priceStrategy": "Strategy {index} (Type: {type}) Price: ${price}",
            "suggestionsForPromotion": "Promotion {index} Suggestions:",
            "noSuggestionsAvailable": "No suggestions available.",
            "editTab":"Edit Campaign",
            "promotionsTab":"Edit Promotions",
            "analysisTab":"Analyse Campaign",
            "Tutorial": {
                "tutorialTitle": "Campaign Edit Tutorial",
                "welcomeMessage": "Welcome to the Campaign Edit tutorial! Here you will learn how to effectively manage your campaigns.",
                "campaignSettingsTitle": "Campaign Settings Overview",
                "campaignSettingsMessage": "In this section, you can configure the basic settings of your campaign.",
                "promotionsListTitle": "Promotions List",
                "promotionsListMessage": "This section displays all the promotions associated with your campaign.",
                "promotionEditInstruction": "Click on a promotion to edit its details.",
                "addPromotionInstruction": "Use the 'Add Promotion' button to create a new promotion.",
                "conditionsTitle": "Conditions",
                "conditionsMessage": "Conditions are rules that determine when a promotion is active.",
                "conditionsInstruction1": "You can add multiple conditions for a promotion.",
                "conditionsInstruction2": "Ensure conditions are met before the promotion goes live.",
                "strategiesTitle": "Strategies",
                "strategiesMessage": "Strategies define how a promotion will be executed.",
                "strategiesInstruction1": "You can add multiple strategies to a promotion.",
                "strategiesInstruction2": "Choose the best strategies based on your campaign goals.",
                "targetsTitle": "Targets",
                "targetsMessage": "Targets are the audience segments you want to reach with your promotion.",
                "targetsInstruction1": "Define your target audience carefully.",
                "targetsInstruction2": "You can add multiple targets for each promotion.",
                "incentivesTitle": "Incentives",
                "incentivesMessage": "Incentives are benefits you offer to encourage customer participation.",
                "incentivesInstruction1": "Consider what incentives will be most appealing to your audience.",
                "incentivesInstruction2": "You can offer multiple types of incentives for each promotion.",
                "schedulesTitle": "Schedules",
                "schedulesMessage": "Schedules determine the timing of your promotions.",
                "schedulesInstruction1": "Set start and end dates for your promotions.",
                "schedulesInstruction2": "Adjust your schedules based on campaign performance."
            }
        },
        "PromotionEdit": {
            "basicInformation": "Basic Information",
            "targets": "Targets({{count}})",
            "strategies": "Strategies({{count}})",
            "conditions": "Conditions({{count}})",
            "incentives": "Incentives({{count}})",
            "addTarget": "Add Target",
            "addStrategy": "Add Strategy",
            "addCondition": "Add Condition",
            "addIncentive": "Add Incentive",
            "editPromotion": "Edit Promotion: {{promotionName}}",
            "promotionName": "Promotion"
        },
        "PromotionList": {
            "promotionsList": "Promotions List",
            "addPromotion": "Add Promotion",
            "promotion": "Promotion {{index}}"
        }
    }
};
