import React from 'react';
import { useNavigate } from 'react-router-dom';
import { addVenueToOrganization } from '../../../services/Http/Api';
import VenueForm from "../../global/Venue/VenueForm";
import { useTranslation } from 'react-i18next';

const VenueCreate = () => {
    const navigate = useNavigate();
    const { t } = useTranslation(); // Initialize translation hook

    const handleSave = async (venueData) => {
        try {
            await addVenueToOrganization(venueData);
            navigate('/venues');
        } catch (error) {
            console.error('Error adding venue:', error);
        }
    };

    return (
        <div>
            <h2>{t('Venues.VenueForm.title_create')}</h2> {/* Translated title */}
            <VenueForm onSave={handleSave} />
        </div>
    );
};

export default VenueCreate;
