import React from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';

const AuthLayout = () => (
    <AuthWrapper>
        <AuthContainer>
            <AuthContent>
                <Outlet /> {/* Renders the specific authentication page, e.g., LoginPage, RegisterPage */}
            </AuthContent>
        </AuthContainer>
    </AuthWrapper>
);

export default AuthLayout;

// Styled Components
const AuthWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f8f9fa;
`;

const AuthContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    @media (min-width: 768px) {
        padding: 0; /* Remove extra padding on larger screens */
    }
`;

const AuthContent = styled.div`
    width: 100%;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

    @media (min-width: 768px) {
        max-width: unset;
        padding: 40px; /* More padding on larger screens */
    }
`;
