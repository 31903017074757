import React, { useState, useEffect } from 'react';
import { fetchVenueIntegration, generateVenueSecret } from "../../../services/Http/Api";
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const VenueIntegration = ({ venueId }) => {
    const { t } = useTranslation();
    const [apiKey, setApiKey] = useState('');
    const [apiStatus, setApiStatus] = useState(false);
    const [apiSecret, setApiSecret] = useState(null); // Store the generated secret here
    const [secretTimer, setSecretTimer] = useState(60); // For the countdown timer

    useEffect(() => {
        const loadVenueIntegration = async () => {
            try {
                const response = await fetchVenueIntegration(venueId);
                setApiKey(response.api_key || ''); // If no API key, show empty
                setApiStatus(response.is_api_active); // Set active/inactive status
            } catch (error) {
                console.error('Failed to load venue integration details:', error);
            }
        };

        loadVenueIntegration();
    }, [venueId]);

    const handleGenerateSecret = async () => {
        try {
            const response = await generateVenueSecret(venueId);
            setApiKey(response.api_key); // Update API key if a new one is generated
            setApiSecret(response.api_secret); // Show the generated secret

            // Start countdown for 60 seconds to remove secret from view
            setSecretTimer(60);
            const countdown = setInterval(() => {
                setSecretTimer((prevTimer) => {
                    if (prevTimer <= 1) {
                        clearInterval(countdown);
                        setApiSecret(null); // Remove secret after 60 seconds
                        return 0;
                    }
                    return prevTimer - 1;
                });
            }, 1000);
        } catch (error) {
            console.error('Failed to generate API secret:', error);
        }
    };

    return (
        <IntegrationContainer>
            <IntegrationHeader>
                <Title>{t('Venues.VenueView.VenueIntegration.api_integration')}</Title>
            </IntegrationHeader>

            <IntegrationBody>
                <FormGroup>
                    <Label htmlFor="apiKeyInput">
                        {t('Venues.VenueView.VenueIntegration.api_key')}
                    </Label>
                    <Input type="text" id="apiKeyInput" value={apiKey} readOnly />
                </FormGroup>

                <GenerateButton onClick={handleGenerateSecret}>
                    {t('Venues.VenueView.VenueIntegration.generate_secret')}
                </GenerateButton>

                {apiSecret && (
                    <SecretContainer>
                        <Alert>
                            <strong>
                                {t('Venues.VenueView.VenueIntegration.generated_secret', { secret: apiSecret })}
                            </strong>
                        </Alert>
                        <Timer>
                            {t('Venues.VenueView.VenueIntegration.secret_timer', { seconds: secretTimer })}
                        </Timer>
                    </SecretContainer>
                )}
            </IntegrationBody>
        </IntegrationContainer>
    );
};

export default VenueIntegration;

// Styled Components

const IntegrationContainer = styled.div`
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

    @media (max-width: 768px) {
        padding: 10px;
    }
`;

const IntegrationHeader = styled.div`
    margin-bottom: 20px;
    border-bottom: 2px solid rgba(130, 22, 146, 0.2);
`;

const Title = styled.h3`
    color: rgba(130, 22, 146, 1);
    font-size: 1.5rem;
    font-weight: bold;

    @media (max-width: 768px) {
        font-size: 1.2rem;
    }
`;

const IntegrationBody = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
`;

const FormGroup = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
`;

const Label = styled.label`
    font-weight: bold;
    color: rgba(130, 22, 146, 0.8);
    margin-bottom: 5px;
`;

const Input = styled.input`
    padding: 10px;
    border: 1px solid rgba(130, 22, 146, 0.2);
    border-radius: 5px;
    background-color: #f8f9fa;
    color: #333;
    font-size: 1rem;

    &:focus {
        outline: none;
        border-color: rgba(130, 22, 146, 0.8);
    }
`;

const GenerateButton = styled.button`
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: rgba(130, 22, 146, 1);
    color: #fff;
    font-size: 1rem;
    cursor: pointer;

    &:hover {
        background-color: rgba(130, 22, 146, 0.8);
    }
`;

const SecretContainer = styled.div`
    margin-top: 15px;
    padding: 15px;
    background-color: #e9f7e9;
    border: 1px solid #28a745;
    border-radius: 5px;
`;

const Alert = styled.div`
    font-size: 1rem;
    color: #28a745;
    margin-bottom: 5px;
`;

const Timer = styled.p`
    font-size: 0.9rem;
    color: #555;
`;
