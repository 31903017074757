import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { postQuestion } from "../../services/Http/Api";

const QuestionModal = ({ show, onClose, param }) => {
    const { t } = useTranslation();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [question, setQuestion] = useState('');
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [hasStoredName, setHasStoredName] = useState(false);
    const [hasStoredEmail, setHasStoredEmail] = useState(false);

    useEffect(() => {
        if (show) {
            setErrors({});
            setLoading(false);
            setQuestion('');

            const contactEmail = localStorage.getItem('contactEmail');
            const contactName = localStorage.getItem('contactName');

            if (contactEmail) {
                setEmail(contactEmail);
                setHasStoredEmail(true);
            } else {
                setEmail('');
                setHasStoredEmail(false);
            }

            if (contactName) {
                setName(contactName);
                setHasStoredName(true);
            } else {
                setName('');
                setHasStoredName(false);
            }
        }
    }, [show]);

    const handleSubmit = async () => {
        const newErrors = {};
        if (!question.trim()) newErrors.question = t('QuestionModal.errors.questionRequired');

        if (!hasStoredName && !name.trim()) newErrors.name = t('QuestionModal.errors.nameRequired');
        if (!hasStoredEmail && !email.trim()) newErrors.email = t('QuestionModal.errors.emailRequired');

        setErrors(newErrors);

        if (Object.keys(newErrors).length === 0) {
            setLoading(true);
            try {
                await postQuestion(name, email, question, param);
                if (!hasStoredName) localStorage.setItem('contactName', name);
                if (!hasStoredEmail) localStorage.setItem('contactEmail', email);
                onClose();
            } catch (error) {
                console.error('Failed to submit question:', error);
                setErrors({ submit: t('QuestionModal.errors.submitFailed') });
            } finally {
                setLoading(false);
            }
        }
    };

    return (
        <StyledModal show={show}>
            <ModalHeader>
                <ModalTitle>{t('QuestionModal.title')}</ModalTitle>
                <CloseButton onClick={onClose}>&times;</CloseButton>
            </ModalHeader>
            <ModalBody>
                {errors.submit && <ErrorMessage>{errors.submit}</ErrorMessage>}
                {!hasStoredName && (
                    <InputGroup>
                        <Label>{t('QuestionModal.name')}</Label>
                        <Input
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            placeholder={t('QuestionModal.namePlaceholder')}
                            isinvalid={!!errors.name}
                        />
                        {errors.name && <ErrorText>{errors.name}</ErrorText>}
                    </InputGroup>
                )}
                {!hasStoredEmail && (
                    <InputGroup>
                        <Label>{t('QuestionModal.email')}</Label>
                        <Input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder={t('QuestionModal.emailPlaceholder')}
                            isInvalid={!!errors.email}
                        />
                        {errors.email && <ErrorText>{errors.email}</ErrorText>}
                    </InputGroup>
                )}
                <InputGroup>
                    <Label>{t('QuestionModal.question')}</Label>
                    <TextArea
                        value={question}
                        onChange={(e) => setQuestion(e.target.value)}
                        placeholder={t('QuestionModal.questionPlaceholder')}
                        isInvalid={!!errors.question}
                    />
                    {errors.question && <ErrorText>{errors.question}</ErrorText>}
                </InputGroup>
            </ModalBody>
            <ModalFooter>
                <CancelButton onClick={onClose}>{t('QuestionModal.cancel')}</CancelButton>
                <SaveButton onClick={handleSubmit} disabled={loading}>
                    {loading ? t('QuestionModal.saving') : t('QuestionModal.save')}
                </SaveButton>
            </ModalFooter>
        </StyledModal>
    );
};

export default QuestionModal;

// Styled Components
const StyledModal = styled.div`
    display: ${({ show }) => (show ? 'flex' : 'none')};
    flex-direction: column;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 500px;
    max-width: 90%;
    z-index: 1050;
    overflow: hidden;
`;

const ModalHeader = styled.div`
    padding: 15px 20px;
    background-color: rgba(130, 22, 146, 0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(130, 22, 146, 0.2);
`;

const ModalTitle = styled.h5`
    margin: 0;
    color: rgba(130, 22, 146, 1);
    font-weight: bold;
`;

const CloseButton = styled.button`
    background: none;
    border: none;
    font-size: 1.5rem;
    color: #333;
    cursor: pointer;

    &:hover {
        color: rgba(130, 22, 146, 1);
    }
`;

const ModalBody = styled.div`
    padding: 20px;
    display: flex;
    flex-direction: column;
`;

const ErrorMessage = styled.div`
    color: #dc3545;
    font-size: 0.9rem;
    margin-bottom: 10px;
`;

const InputGroup = styled.div`
    margin-bottom: 15px;
`;

const Label = styled.label`
    font-size: 0.9rem;
    font-weight: bold;
    color: #333;
    display: block;
    margin-bottom: 5px;
`;

const Input = styled.input`
    width: 100%;
    padding: 12px 15px;
    border: 1px solid ${({ isInvalid }) => (isInvalid ? '#dc3545' : '#ccc')};
    border-radius: 8px;
    margin-top: 5px;
    box-sizing: border-box;
    box-shadow: ${({ isInvalid }) => (isInvalid ? '0 0 4px #dc3545' : 'none')};

    &:focus {
        border-color: rgba(130, 22, 146, 1);
        outline: none;
        box-shadow: 0 0 4px rgba(130, 22, 146, 0.5);
    }
`;

const TextArea = styled.textarea`
    width: 100%;
    padding: 12px 15px;
    border: 1px solid ${({ isInvalid }) => (isInvalid ? '#dc3545' : '#ccc')};
    border-radius: 8px;
    margin-top: 5px;
    box-sizing: border-box;
    box-shadow: ${({ isInvalid }) => (isInvalid ? '0 0 4px #dc3545' : 'none')};

    &:focus {
        border-color: rgba(130, 22, 146, 1);
        outline: none;
        box-shadow: 0 0 4px rgba(130, 22, 146, 0.5);
    }
`;

const ErrorText = styled.span`
    color: #dc3545;
    font-size: 0.8rem;
`;

const ModalFooter = styled.div`
    padding: 15px 20px;
    background-color: rgba(130, 22, 146, 0.05);
    border-top: 1px solid rgba(130, 22, 146, 0.2);
    display: flex;
    justify-content: flex-end;
`;

const CancelButton = styled.button`
    background: #ccc;
    color: #333;
    border: none;
    padding: 10px 15px;
    border-radius: 8px;
    margin-right: 10px;
    cursor: pointer;

    &:hover {
        background: #bbb;
    }
`;

const SaveButton = styled.button`
    background: rgba(130, 22, 146, 1);
    color: #fff;
    border: none;
    padding: 10px 15px;
    border-radius: 8px;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

    &:hover {
        background: rgba(110, 12, 136, 1);
    }

    &:disabled {
        background: #ccc;
        cursor: not-allowed;
    }
`;
