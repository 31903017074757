import React, { useState, useEffect } from 'react';
import { fetchCountryCodes } from "../../../services/Http/Api";
import PhoneNumberInput from "../PhoneNumberInput";
import { Accordion } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const VenueForm = ({ venue, onSave }) => {
    const { t } = useTranslation();
    const [countryCodes, setCountryCodes] = useState([]);
    const [socialLinks, setSocialLinks] = useState({
        twitter: '',
        facebook: '',
        instagram: ''
    });

    const [venueData, setVenueData] = useState({
        name: venue?.name || '',
        description: venue?.description || '',
        email: venue?.email || '',
        phone: venue?.phone || '',
        phone_country_id: venue?.phone_country_id ? String(venue.phone_country_id) : '',
        country_id: venue?.country_id || '',
        address: venue?.address || '',
        city: venue?.city || '',
        website: venue?.website || '',
        latitude: venue?.latitude || '',
        longitude: venue?.longitude || '',
        organization_id: venue?.organization_id || ''
    });

    useEffect(() => {
        if (venue?.social_media_links) {
            const parsedLinks = JSON.parse(venue.social_media_links);
            setSocialLinks({
                twitter: parsedLinks.twitter || '',
                facebook: parsedLinks.facebook || '',
                instagram: parsedLinks.instagram || ''
            });
        }
    }, [venue]);

    useEffect(() => {
        const fetchCountries = async () => {
            try {
                const response = await fetchCountryCodes();
                setCountryCodes(response);
            } catch (error) {
                console.error('Error fetching country codes:', error);
            }
        };
        fetchCountries();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setVenueData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handlePhoneNumberChange = (data) => {
        setVenueData((prevData) => ({
            ...prevData,
            phone: data.phone,
            phone_country_id: data.phoneCountryCode
        }));
    };

    const handleSocialChange = (e) => {
        const { name, value } = e.target;
        setSocialLinks((prevLinks) => ({ ...prevLinks, [name]: value }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const fullVenueData = {
            ...venueData,
            social_media_links: JSON.stringify(socialLinks)
        };
        onSave(fullVenueData);
    };

    return (
        <FormContainer onSubmit={handleSubmit}>
            <StyledAccordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                    <Accordion.Header>{t('Venues.VenueForm.basic_info')}</Accordion.Header>
                    <Accordion.Body>
                        <FormGroup>
                            <Label htmlFor="venue-name">{t('Venues.VenueForm.name_label')}</Label>
                            <Input
                                type="text"
                                name="name"
                                id="venue-name"
                                value={venueData.name}
                                onChange={handleChange}
                                placeholder={t('Venues.VenueForm.name_placeholder')}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label htmlFor="venue-description">{t('Venues.VenueForm.description_label')}</Label>
                            <TextArea
                                name="description"
                                id="venue-description"
                                value={venueData.description}
                                onChange={handleChange}
                                placeholder={t('Venues.VenueForm.description_placeholder')}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label htmlFor="venue-email">{t('Venues.VenueForm.email_label')}</Label>
                            <Input
                                type="email"
                                name="email"
                                id="venue-email"
                                value={venueData.email}
                                onChange={handleChange}
                                placeholder={t('Venues.VenueForm.email_placeholder')}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label htmlFor="venue-website">{t('Venues.VenueForm.website_label')}</Label>
                            <Input
                                type="url"
                                name="website"
                                id="venue-website"
                                value={venueData.website}
                                onChange={handleChange}
                                placeholder={t('Venues.VenueForm.website_placeholder')}
                            />
                        </FormGroup>
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="1">
                    <Accordion.Header>{t('Venues.VenueForm.location_details')}</Accordion.Header>
                    <Accordion.Body>
                        <PhoneNumberInput
                            countryCodes={countryCodes}
                            phoneValue={venueData.phone}
                            phoneCountryValue={venueData.phone_country_id}
                            onChange={handlePhoneNumberChange}
                        />
                        <FormGroup>
                            <Label htmlFor="venue-country">{t('Venues.VenueForm.country_label')}</Label>
                            <Select
                                name="country_id"
                                id="venue-country"
                                value={venueData.country_id}
                                onChange={handleChange}
                            >
                                <option value="">{t('Venues.VenueForm.select_country')}</option>
                                {countryCodes.map((country) => (
                                    <option key={country.id} value={country.id}>
                                        {`${country.name} (+${country.phonecode})`}
                                    </option>
                                ))}
                            </Select>
                        </FormGroup>
                        <FormGroup>
                            <Label htmlFor="venue-address">{t('Venues.VenueForm.address_label')}</Label>
                            <Input
                                type="text"
                                name="address"
                                id="venue-address"
                                value={venueData.address}
                                onChange={handleChange}
                                placeholder={t('Venues.VenueForm.address_placeholder')}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label htmlFor="venue-city">{t('Venues.VenueForm.city_label')}</Label>
                            <Input
                                type="text"
                                name="city"
                                id="venue-city"
                                value={venueData.city}
                                onChange={handleChange}
                                placeholder={t('Venues.VenueForm.city_placeholder')}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label htmlFor="venue-latitude">{t('Venues.VenueForm.latitude_label')}</Label>
                            <Input
                                type="text"
                                name="latitude"
                                id="venue-latitude"
                                value={venueData.latitude}
                                onChange={handleChange}
                                placeholder={t('Venues.VenueForm.latitude_placeholder')}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label htmlFor="venue-longitude">{t('Venues.VenueForm.longitude_label')}</Label>
                            <Input
                                type="text"
                                name="longitude"
                                id="venue-longitude"
                                value={venueData.longitude}
                                onChange={handleChange}
                                placeholder={t('Venues.VenueForm.longitude_placeholder')}
                            />
                        </FormGroup>
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="2">
                    <Accordion.Header>{t('Venues.VenueForm.social_media')}</Accordion.Header>
                    <Accordion.Body>
                        <FormGroup>
                            <Label htmlFor="venue-twitter">{t('Venues.VenueForm.twitter_label')}</Label>
                            <Input
                                type="text"
                                name="twitter"
                                id="venue-twitter"
                                value={socialLinks.twitter}
                                onChange={handleSocialChange}
                                placeholder={t('Venues.VenueForm.twitter_placeholder')}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label htmlFor="venue-facebook">{t('Venues.VenueForm.facebook_label')}</Label>
                            <Input
                                type="text"
                                name="facebook"
                                id="venue-facebook"
                                value={socialLinks.facebook}
                                onChange={handleSocialChange}
                                placeholder={t('Venues.VenueForm.facebook_placeholder')}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label htmlFor="venue-instagram">{t('Venues.VenueForm.instagram_label')}</Label>
                            <Input
                                type="text"
                                name="instagram"
                                id="venue-instagram"
                                value={socialLinks.instagram}
                                onChange={handleSocialChange}
                                placeholder={t('Venues.VenueForm.instagram_placeholder')}
                            />
                        </FormGroup>
                    </Accordion.Body>
                </Accordion.Item>
            </StyledAccordion>
            <FormFooter>
                <SaveButton type="submit">{t('Venues.VenueForm.save_button')}</SaveButton>
            </FormFooter>
        </FormContainer>
    );
};

export default VenueForm;

// Styled Components
const FormContainer = styled.form`
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const StyledAccordion = styled(Accordion)`
    .accordion-item {
        border: none;

        .accordion-header {
            background-color: rgba(130, 22, 146, 0.1);
            padding: 10px;
            border-radius: 8px;

            button {
                font-weight: bold;
                color: rgba(130, 22, 146, 1);

                &:focus {
                    box-shadow: none;
                }
            }
        }

        .accordion-body {
            padding: 15px;
            background-color: #f9f9f9;
            border-radius: 8px;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        }
    }
`;

const FormGroup = styled.div`
    margin-bottom: 15px;
`;

const Label = styled.label`
    font-weight: bold;
    margin-bottom: 5px;
    display: block;
`;

const Input = styled.input`
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
`;

const TextArea = styled.textarea`
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
`;

const Select = styled.select`
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
`;

const FormFooter = styled.div`
    text-align: right;
    margin-top: 20px;
`;

const SaveButton = styled.button`
    background-color: rgba(130, 22, 146, 1);
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-weight: bold;
    cursor: pointer;

    &:hover {
        background-color: rgba(110, 12, 136, 1);
    }
`;
