import React from 'react';
import {BrowserRouter as Router, Routes, Route, Navigate, useLocation} from 'react-router-dom';
import { useAuth } from './context/AuthProvider';
import LoginPage from "./components/layout/LoginPage";
import RegisterPage from "./components/layout/RegisterPage";
import Dashboard from "./components/parts/Dashboard";
import Transactions from "./components/parts/Transactions/Transactions";
import VenueCreate from "./components/parts/Venues/VenueCreate";
import Venues from "./components/parts/Venues/Venues";
import VenueView from "./components/parts/Venues/VenueView";
import Items from "./components/parts/Items/Items";
import ViewItem from "./components/parts/Items/Item/ViewItem";
import WarrantiesList from "./components/parts/WarrantiesList";
import WarrantiesTemplatesList from "./components/parts/WarrantiesTemplatesList";
import CampaignList from "./components/parts/campaign/CampaignList";
import CampaignCreate from "./components/parts/campaign/CampaignCreate";
import CampaignEdit from "./components/parts/campaign/CampaignEdit";
import AuthorizedServiceCenters from "./components/parts/AuthorizedServiceCenters";
import POSIntegration from "./components/parts/POSIntegration";
import Settings from "./components/parts/Settings/Settings";
import OrganizationDetail from "./components/parts/OrganizationDetail";
import AuthLayout from "./layouts/AuthLayout";
import MainLayout from "./layouts/MainLayout";
import Users from "./components/parts/Users/Users";
import ForgotPasswordPage from "./components/layout/ForgotPasswordPage";
import CampaignWizard from "./components/parts/campaign/CampaignWizard";

const AppRouter = () => {
    const { isAuthenticated, loading } = useAuth(); // Access authentication state
    const location = useLocation(); // Get the current location

    if (loading) {
        // Optionally, show a loading spinner while authentication is initializing
        return <div>Loading...</div>;
    }

    return (
            <Routes>
                {/* Non-authenticated routes */}
                {!isAuthenticated ? (
                    <Route element={<AuthLayout />}>
                        <Route path="/login" element={<LoginPage />} />
                        <Route path="/register" element={<RegisterPage />} />
                        <Route path="/forgot-password" element={<ForgotPasswordPage />} />
                        <Route path="*" element={<Navigate to="/login" replace />} />
                    </Route>
                ) : (
                    // Authenticated routes wrapped in MainLayout
                    <Route element={<MainLayout />}>
                    {/* Dashboard Route */}
                        <Route path="/" element={<Dashboard />} />

                    {/* Other Routes */}
                        <Route path="users" element={<Users />} />
                        <Route path="transactions" element={<Transactions />} />

                        {/* Venues Routes */}
                        <Route path="venues">
                        <Route index element={<Venues />} />
                        <Route path="create" element={<VenueCreate />} />
                        <Route path="view/:id" element={<VenueView />} />
                        </Route>

                        {/* Items Routes */}
                        <Route path="items">
                        <Route index element={<Items />} />
                        <Route path="view/:itemId" element={<ViewItem />} />
                        </Route>

                        {/* Warranties Routes */}
                        <Route path="warranties">
                        <Route index element={<WarrantiesList />} />
                        <Route path="templates" element={<WarrantiesTemplatesList />} />
                        </Route>

                        {/* Campaigns Routes */}
                        <Route path="campaigns">
                            <Route index element={<CampaignList />} /> {/* Campaign list */}
                            <Route path="create" element={<CampaignCreate />} /> {/* Create campaign */}
                            <Route path="edit/:id" element={<CampaignEdit />} /> {/* Edit specific campaign */}
                        </Route>

                        {/* Other Routes */}
                        <Route path="authorized_services" element={<AuthorizedServiceCenters />} />
                        <Route path="integration" element={<POSIntegration />} />
                        <Route path="settings" element={<Settings />} />
                        <Route path="organizations/:id" element={<OrganizationDetail />} />

                    {/* Fallback */}
                    <Route path="*" element={<Navigate to="/dashboard" replace />} />
                    </Route>
                )}
            </Routes>
    );
};

export default AppRouter;
