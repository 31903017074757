// MainLayout.js
import React, { useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthProvider';
import Navigation from '../components/layout/Navigation';
import styled from 'styled-components';
import { MdMenu, MdClose } from 'react-icons/md';
import Logo from '../assets/images/gsoft_logo.png';
import { Modal } from 'react-bootstrap';

const MainLayout = () => {
    const { logout } = useAuth();
    const navigate = useNavigate();
    const [showNavModal, setShowNavModal] = useState(false);

    const handleLogout = () => {
        logout();
        navigate('/');
    };

    const toggleNavModal = () => {
        setShowNavModal(!showNavModal);
    };

    return (
        <LayoutContainer>
            <TopBanner>
                <LogoContainer>
                    <img src={Logo} alt="Logo" />
                </LogoContainer>
                <MobileMenuIcon onClick={toggleNavModal}>
                    <MdMenu size="24" />
                </MobileMenuIcon>
            </TopBanner>

            <NavContainer>
                <Navigation handleLogout={handleLogout} />
            </NavContainer>

            <ContentWrapper>
                <Outlet />
            </ContentWrapper>

            <FullScreenModal show={showNavModal} onHide={toggleNavModal} centered>
                <ModalContent>
                    <CloseButton onClick={toggleNavModal}>
                        <MdClose size="24" />
                    </CloseButton>
                    <Navigation handleLogout={handleLogout} closeMenu={toggleNavModal} />
                </ModalContent>
            </FullScreenModal>
        </LayoutContainer>
    );
};

export default MainLayout;

// Styled Components for Layout
const LayoutContainer = styled.div`
    display: grid;
    grid-template-rows: auto 1fr;
    grid-template-columns: 250px 1fr;
    height: 100vh;
    overflow: hidden;

    @media (max-width: 1200px) {
        grid-template-columns: 200px 1fr;
    }

    @media (max-width: 768px) {
        grid-template-columns: 1fr;
    }
`;

const TopBanner = styled.header`
    grid-column: 1 / -1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    color: white;
    width: 100%;
    border-bottom: 3px solid rgb(130, 22, 146);
    box-sizing: border-box;

    @media (max-width: 1200px) {
        padding: 8px 15px;
    }

    @media (max-width: 768px) {
        padding: 8px 10px;
    }
`;

const LogoContainer = styled.div`
    display: flex;
    align-items: center;
    img {
        height: 40px;
    }

    @media (max-width: 1200px) {
        img {
            height: 30px;
        }
    }

    @media (max-width: 768px) {
        img {
            height: 25px;
        }
    }
`;

const MobileMenuIcon = styled.div`
    display: none;
    cursor: pointer;
    color: rgb(130, 22, 146);

    @media (max-width: 768px) {
        display: block;
    }
`;

const NavContainer = styled.aside`
    overflow-y: auto;
    overflow-x: hidden; /* Prevents horizontal scrolling */
    height: 100vh;
    padding-top: 10px;
    border-right: 1px solid #d3cdcd;
    width: 100%;
    box-sizing: border-box; /* Ensures padding doesn’t extend the width */

    @media (max-width: 1200px) {
        padding-top: 50px;
    }

    @media (max-width: 768px) {
        display: none;
    }
`;

const ContentWrapper = styled.main`
    overflow-y: auto;
    max-width: 100%;
    background-color: #fff;
    margin-bottom: 50px;

    @media (max-width: 1200px) {
        padding: 15px;
    }

    @media (max-width: 768px) {
        max-width: 100%;
        padding: 10px;
    }
`;

const FullScreenModal = styled(Modal)`
    .modal-dialog {
        max-width: 100%;
        margin: 0;
    }
    .modal-content {
        height: 100vh;
        width: 100vw;
        border: none;
        border-radius: 0;
    }
`;

const ModalContent = styled.div`
    position: relative;
    padding: 20px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f4f4f4;
`;

const CloseButton = styled.div`
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    color: gray;
`;
