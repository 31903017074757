import React, { useState } from 'react';
import { Button, Form, FormGroup, Label, Input, Spinner } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { createCampaign } from '../../../services/Http/Api'; // Make sure your API service is correctly set up
import { useTranslation } from 'react-i18next';

const CampaignCreate = () => {
    const { t } = useTranslation();
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [budget, setBudget] = useState(''); // State for budget
    const [goalDescription, setGoalDescription] = useState(''); // State for goal description
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const campaignData = {
                name,
                description,
                start_date: startDate,
                end_date: endDate,
                budget,
                goal_description: goalDescription,
            };

            await createCampaign(campaignData);
            navigate('/campaigns'); // Redirect to the campaign list after successful creation
        } catch (error) {
            console.error('Error creating campaign:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="container mt-4">
            <h2>{t('Campaigns.CampaignCreate.title')}</h2>
            <Form onSubmit={handleSubmit}>
                <FormGroup>
                    <Label for="name">{t('Campaigns.CampaignCreate.name')}</Label>
                    <Input
                        type="text"
                        id="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="description">{t('Campaigns.CampaignCreate.description')}</Label>
                    <Input
                        type="textarea"
                        id="description"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="startDate">{t('Campaigns.CampaignCreate.startDate')}</Label>
                    <Input
                        type="date"
                        id="startDate"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                        required
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="endDate">{t('Campaigns.CampaignCreate.endDate')}</Label>
                    <Input
                        type="date"
                        id="endDate"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                        required
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="budget">{t('Campaigns.CampaignCreate.budget')}</Label>
                    <Input
                        type="number"
                        id="budget"
                        value={budget}
                        onChange={(e) => setBudget(e.target.value)}
                        required
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="goalDescription">{t('Campaigns.CampaignCreate.goalDescription')}</Label>
                    <Input
                        type="textarea"
                        id="goalDescription"
                        value={goalDescription}
                        onChange={(e) => setGoalDescription(e.target.value)}
                    />
                </FormGroup>
                <Button color="primary" type="submit" disabled={loading}>
                    {loading ? <Spinner size="sm" /> : t('Campaigns.CampaignCreate.createCampaign')}
                </Button>{' '}
                <Button color="secondary" onClick={() => navigate('/campaigns')}>
                    {t('Campaigns.CampaignCreate.cancel')}
                </Button>
            </Form>
        </div>
    );
};

export default CampaignCreate;
