import styled from 'styled-components';
import { FlagIcon } from 'react-flag-kit';

const StyledButton = styled.button`
    width: 50px; /* Circle diameter */
    height: 50px; /* Circle diameter */
    border-radius: 50%; /* Always circular */
    border: 1px solid ${(props) => props.borderColor || 'transparent'};
    background-color: ${(props) => props.bgColor || 'transparent'};
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden; /* Ensure only part of the flag is visible */
    cursor: pointer;
    position: relative; /* Needed for positioning the flag icon */
    transition: transform 0.2s, background-color 0.3s;

    &:hover {
        background-color: ${(props) => props.hoverBgColor || '#e0e0e0'};
        transform: scale(1.1); /* Add hover effect */
    }

    &:disabled {
        background-color: #ccc;
        cursor: not-allowed;
    }

    .flag-icon {
        width: 70px; /* Larger than the button for overflow effect */
        height: auto; /* Maintain aspect ratio */
        position: absolute; /* Keep the flag centered in the button */
    }
`;

const LanguageButton = ({ countryCode, ...props }) => {
    return (
        <StyledButton {...props}>
            {countryCode && <FlagIcon code={countryCode} className="flag-icon" />}
        </StyledButton>
    );
};

export default LanguageButton;
