import React from 'react';
import styled from 'styled-components';
import { MdInfoOutline } from 'react-icons/md';

const LogoHeader = ({ logoSrc, title, onHelpClick }) => {
    return (
        <LogoContainer>
            <img src={logoSrc} alt={`${title} Logo`} />
            <h2>{title}</h2>
            <HelpButton onClick={onHelpClick}>
                <MdInfoOutline className="info-icon" />
            </HelpButton>
        </LogoContainer>
    );
};

const LogoContainer = styled.div`
    text-align: center;
    margin-bottom: 20px;

    img {
        width: 150px;
        margin-bottom: 10px;
    }

    h2 {
        color: ${({ theme }) => theme.colors.brand};
        margin-bottom: 0;
    }
`;

const HelpButton = styled.button`
    background: none;
    border: none;
    color: ${({ theme }) => theme.colors.brand};
    font-size: 1.5rem;
    cursor: pointer;
`;

export default LogoHeader;
