import React, { useState } from 'react';
import ItemsOverview from './ItemsOverview';
import { useTranslation } from 'react-i18next';
import { MdInfoOutline, MdHelpOutline } from 'react-icons/md';
import styled from 'styled-components';
import QuestionModal from '../../global/QuestionModal';

const Items = () => {
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState('overview');
    const [showQuestionModal, setShowQuestionModal] = useState(false);
    const [showTutorialModal, setShowTutorialModal] = useState(false);

    const handleShowQuestionModal = () => setShowQuestionModal(true);
    const handleCloseQuestionModal = () => setShowQuestionModal(false);
    const handleShowTutorialModal = () => setShowTutorialModal(true);
    const handleCloseTutorialModal = () => setShowTutorialModal(false);

    return (
        <ItemsContainer>
            <Header>
                <Title>{t('Items.title')}</Title>
                <ButtonGroup>
                    <IconButton onClick={handleShowQuestionModal}>
                        <MdHelpOutline className="info-icon" />
                    </IconButton>
                    <IconButton onClick={handleShowTutorialModal}>
                        <MdInfoOutline className="info-icon" />
                    </IconButton>
                </ButtonGroup>
            </Header>

            <Tabs>
                <Tab
                    active={activeTab === 'overview'}
                    onClick={() => setActiveTab('overview')}
                >
                    {t('Items.overview')}
                </Tab>
                <Tab
                    active={activeTab === 'performance'}
                    onClick={() => setActiveTab('performance')}
                >
                    {t('Items.performance_insights')}
                </Tab>
                <Tab
                    active={activeTab === 'warranty'}
                    onClick={() => setActiveTab('warranty')}
                >
                    {t('Items.warranty_alerts')}
                </Tab>
            </Tabs>

            <Content>
                {activeTab === 'overview' && <ItemsOverview />}
                {activeTab === 'performance' && <div>{t('Items.performance_insights')}</div>}
                {activeTab === 'warranty' && <div>{t('Items.warranty_alerts')}</div>}
            </Content>

            {/* Question Modal */}
            <QuestionModal
                show={showQuestionModal}
                onClose={handleCloseQuestionModal}
                param="items"
            />

            {/* Tutorial Modal */}
            <StyledModal show={showTutorialModal}>
                <ModalHeader>
                    <ModalTitle>{t('Items.Tutorial.title')}</ModalTitle>
                    <CloseButton onClick={handleCloseTutorialModal}>&times;</CloseButton>
                </ModalHeader>
                <ModalBody>
                    <TutorialCard>
                        <h5>{t('Items.Tutorial.explanation_title')}</h5>
                        <p>{t('Items.Tutorial.explanation_message')}</p>
                    </TutorialCard>
                </ModalBody>
            </StyledModal>
        </ItemsContainer>
    );
};

export default Items;

// Styled Components

const ItemsContainer = styled.div`
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

    @media (max-width: 768px) {
        padding: 10px;
    }
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 15px;
    border-bottom: 2px solid rgba(130, 22, 146, 0.8);

    @media (max-width: 768px) {
        flex-direction: column;
        gap: 10px;
    }
`;

const Title = styled.h1`
    color: rgba(130, 22, 146, 1);
    font-size: 1.8rem;
    font-weight: bold;

    @media (max-width: 768px) {
        font-size: 1.5rem;
    }
`;

const ButtonGroup = styled.div`
    display: flex;
    gap: 10px;

    @media (max-width: 768px) {
        flex-wrap: wrap;
    }
`;

const IconButton = styled.button`
    background-color: rgba(130, 22, 146, 0.1);
    color: rgba(130, 22, 146, 1);
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
        background-color: rgba(130, 22, 146, 0.2);
    }

    .info-icon {
        font-size: 1.5rem;
    }
`;

const Tabs = styled.div`
    display: flex;
    margin-top: 20px;
    border-bottom: 2px solid rgba(130, 22, 146, 0.2);

    @media (max-width: 768px) {
        flex-wrap: wrap;
        gap: 10px;
    }
`;

const Tab = styled.button`
    flex: 1;
    padding: 10px;
    border: none;
    background: ${({ active }) => (active ? '#fff' : 'transparent')};
    border-bottom: ${({ active }) =>
    active ? '4px solid rgba(130, 22, 146, 1)' : 'none'};
    color: ${({ active }) => (active ? 'rgba(130, 22, 146, 1)' : '#333')};
    font-weight: bold;
    cursor: pointer;
    text-align: center;

    &:hover {
        color: rgba(130, 22, 146, 1);
    }

    @media (max-width: 768px) {
        padding: 8px;
        font-size: 0.9rem;
    }
`;

const Content = styled.div`
    margin-top: 20px;
`;

const StyledModal = styled.div`
    display: ${({ show }) => (show ? 'flex' : 'none')};
    flex-direction: column;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 500px;
    max-width: 90%;
    z-index: 1050;
`;

const ModalHeader = styled.div`
    padding: 15px;
    background-color: rgba(130, 22, 146, 0.1);
    border-bottom: 1px solid rgba(130, 22, 146, 0.2);
`;

const ModalTitle = styled.h5`
    margin: 0;
    color: rgba(130, 22, 146, 1);
    font-weight: bold;
`;

const CloseButton = styled.button`
    background: none;
    border: none;
    font-size: 1.5rem;
    color: #333;
    cursor: pointer;

    &:hover {
        color: rgba(130, 22, 146, 1);
    }
`;

const ModalBody = styled.div`
    padding: 20px;
`;

const TutorialCard = styled.div`
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 8px;
    border: 1px solid rgba(130, 22, 146, 0.2);
    color: #333;
    margin-bottom: 15px;

    h5 {
        font-size: 1.25rem;
        color: rgba(130, 22, 146, 1);
        margin-bottom: 10px;
    }

    p {
        font-size: 1rem;
        line-height: 1.5;
    }
`;
