import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
    MdDashboard,
    MdPeople,
    MdSyncAlt,
    MdStore,
    MdInventory,
    MdDescription,
    MdCampaign,
    MdPointOfSale,
    MdSettings,
    MdLogout,
    MdBuild,
    MdError,
} from 'react-icons/md'; // Import Material Design icons
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const Navigation = ({ handleLogout, closeMenu }) => {
    const { t } = useTranslation();
    const location = useLocation();

    const isActive = (path) => location.pathname === path;

    return (
        <NavList>
            <NavItem to="/" $isActive={isActive('/')} onClick={closeMenu}>
                <MdDashboard className="icon" /> {t('Navigation.dashboard')}
            </NavItem>
            <NavItem to="/users" $isActive={isActive('/users')} onClick={closeMenu}>
                <MdPeople className="icon" /> {t('Navigation.users')}
            </NavItem>
            <NavItem to="/transactions" $isActive={isActive('/transactions')} onClick={closeMenu}>
                <MdSyncAlt className="icon" /> {t('Navigation.transactions')}
            </NavItem>
            <NavItem to="/venues" $isActive={isActive('/venues')} onClick={closeMenu}>
                <MdStore className="icon" /> {t('Navigation.venues')}
            </NavItem>
            <NavItem to="/campaigns" $isActive={isActive('/campaigns')} onClick={closeMenu}>
                <MdCampaign className="icon" /> {t('Navigation.campaigns')}
            </NavItem>
            <NavItem to="/items" $isActive={isActive('/items')} onClick={closeMenu}>
                <MdInventory className="icon" /> {t('Navigation.items')}
            </NavItem>
            <NavItem to="/warranties" $isActive={isActive('/warranties')} onClick={closeMenu}>
                <MdDescription className="icon" /> {t('Navigation.warranties')}
                <MdError className="status-icon" />
            </NavItem>
            <NavItem to="/authorized_services" $isActive={isActive('/authorized_services')} onClick={closeMenu}>
                <MdBuild className="icon" /> {t('Navigation.authorizedServices')}
                <MdError className="status-icon" />
            </NavItem>
            <NavItem to="/integration" $isActive={isActive('/integration')} onClick={closeMenu}>
                <MdPointOfSale className="icon" /> {t('Navigation.pos_integration')}
            </NavItem>
            <NavItem to="/settings" $isActive={isActive('/settings')} onClick={closeMenu}>
                <MdSettings className="icon" /> {t('Navigation.settings')}
                <MdError className="status-icon" />
            </NavItem>
            <LogoutButton onClick={() => { handleLogout(); closeMenu && closeMenu(); }}>
                <MdLogout className="icon" /> {t('Navigation.logout')}
            </LogoutButton>
        </NavList>
    );
};

export default Navigation;

// Styled Components

const NavList = styled.ul`
    list-style: none;
    padding: 1rem 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    font-size: 1.2rem;

    @media (min-width: 1200px) {
        padding-top: 0.5rem;
        font-size: 1.1rem;
    }

    @media (max-width: 768px) {
        padding: 0; /* Remove padding on mobile */
        margin: 0; /* Remove margin on mobile */
        width: 100%; /* Full width */
        font-size: 1rem;
    }
`;

const NavItem = styled(Link)`
    display: flex;
    align-items: center;
    color: ${({ $isActive }) => ($isActive ? '#fff' : 'rgb(130, 22, 146)')};
    text-decoration: none;
    padding: 12px 20px;
    border-radius: 5px;
    transition: background-color 0.3s;
    background-color: ${({ $isActive }) => ($isActive ? 'rgba(130, 22, 146, 0.8)' : 'transparent')};
    width: 100%;

    &:hover {
        background-color: rgba(130, 22, 146, 0.1);
    }

    .icon {
        margin-right: 0.8rem;
        font-size: 1.5rem;
        opacity: ${({ $isActive }) => ($isActive ? 1 : 0.75)};
    }

    .status-icon {
        margin-left: 0.5rem;
        color: red;
        font-size: 1rem;
    }

        @media (max-width: 768px) {
        padding: 1em; /* Full-width padding on mobile */
        border-radius: 0; /* Remove border radius */
    }
`;

const LogoutButton = styled.button`
    display: flex;
    align-items: center;
    color: rgb(130, 22, 146);
    font-size: 1.2rem;
    padding: 12px 20px;
    background: none;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
    border-radius: 5px;
    width: 100%;

    &:hover {
        background-color: rgba(130, 22, 146, 0.1);
    }

    .icon {
        margin-right: 0.8rem;
        font-size: 1.5rem;
        }

        @media (max-width: 768px) {
        padding: 1em; /* Full-width padding on mobile */
        border-radius: 0; /* Remove border radius on mobile */
        font-size: 1rem;
    }
`;
