import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { Spinner } from 'reactstrap';
import { fetchItems } from "../../../services/Http/Api";
import { useNavigate } from 'react-router-dom';
import SearchFilters from "./SearchFilters";
import { useTranslation } from 'react-i18next';
import { MdVisibility } from 'react-icons/md';
import styled from 'styled-components';

const ItemsOverviewContainer = styled.div`
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    h4 {
        color: rgba(130, 22, 146, 1);
        font-weight: bold;
    }
`;

const ActionButton = styled.button`
    background: none;
    border: none;
    cursor: pointer;
    color: rgba(130, 22, 146, 1);

    &:hover {
        color: rgba(130, 22, 146, 0.8);
    }
`;

const StyledSpinner = styled(Spinner)`
    color: rgba(130, 22, 146, 1);
`;

const ItemsOverview = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [filters, setFilters] = useState({ searchTerm: '', manufacturerId: '' });
    const [sortField, setSortField] = useState('');
    const [sortDirection, setSortDirection] = useState('');

    const handleViewItem = (id) => {
        navigate(`/items/view/${id}`);
    };

    const columns = [
        {
            name: t('Items.Overview.model_number'),
            selector: row => row.model_number || "N/A",
            sortable: true
        },
        {
            name: t('Items.Overview.product_name'),
            selector: row => row.product_name || "N/A",
            sortable: true
        },
        {
            name: t('Items.Overview.current_holding_count'),
            selector: row => row.current_holding_count || 0,
            sortable: true
        },
        {
            name: t('Items.Overview.sold_items_count'),
            selector: row => row.sold_items_count || 0,
            sortable: true
        },
        {
            name: t('Items.Overview.release_year'),
            selector: row => row.release_year || "Unknown",
            sortable: true
        },
        {
            name: t('Items.Overview.warranty_coverage'),
            selector: row => row.warranty_coverage || "N/A",
            sortable: true
        },
        {
            name: t('Items.Overview.actions'),
            cell: row => (
                <ActionButton onClick={() => handleViewItem(row.id)}>
                    <MdVisibility size={20} />
                </ActionButton>
            ),
        },
    ];

    const fetchData = async (page, size = perPage, appliedFilters = filters) => {
        setLoading(true);
        try {
            const response = await fetchItems({
                page,
                perPage: size,
                sortBy: sortField,
                sortDirection,
                search: appliedFilters.searchTerm,
                manufacturerId: appliedFilters.manufacturerId,
            });

            const { data, total } = response;
            setItems(data);
            setTotalRows(total);
        } catch (error) {
            console.error("Error fetching items:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleFilterChange = (newFilters) => {
        setFilters(newFilters);
        setCurrentPage(1);
        fetchData(1, perPage, newFilters);
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
        fetchData(page);
    };

    const handlePerRowsChange = (newPerPage, page) => {
        setPerPage(newPerPage);
        fetchData(page, newPerPage);
    };

    const handleSort = (column, sortDirection) => {
        setSortField(column.key);
        setSortDirection(sortDirection);
        fetchData(currentPage);
    };

    return (
        <ItemsOverviewContainer>
            <Header>
                <h4>{t('Items.Overview.title')}</h4>
            </Header>

            <SearchFilters onFilter={handleFilterChange} />

            <DataTable
                columns={columns}
                data={items}
                pagination
                paginationServer
                paginationTotalRows={totalRows}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handlePerRowsChange}
                onSort={handleSort}
                sortServer
                highlightOnHover
                progressPending={loading}
                progressComponent={<StyledSpinner />}
            />
        </ItemsOverviewContainer>
    );
};

export default ItemsOverview;
