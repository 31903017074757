import styled from 'styled-components';

const StyledDetailsContainer = styled.div`
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;

    h3 {
        color: rgba(130, 22, 146, 1);
        font-size: 1.8rem;
        font-weight: bold;
        margin-bottom: 20px;

        @media (max-width: 768px) {
            font-size: 1.5rem;
        }
    }

    .header-buttons {
        display: flex;
        justify-content: flex-end;
        gap: 10px;
        margin-bottom: 15px;

        button {
            background-color: rgba(130, 22, 146, 0.1);
            color: rgba(130, 22, 146, 1);
            border: none;
            border-radius: 50%;
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            &:hover {
                background-color: rgba(130, 22, 146, 0.2);
            }

            .icon {
                font-size: 1.5rem;
            }
        }
    }

    form {
        .form-group {
            margin-bottom: 15px;

            label {
                font-weight: 500;
                color: #333;
                margin-bottom: 5px;
                display: block;
            }

            input {
                padding: 10px;
                border: 1px solid #ddd;
                border-radius: 6px;
                font-size: 1rem;
                width: 100%;
                transition: border-color 0.3s ease;

                &:focus {
                    border-color: rgba(130, 22, 146, 1);
                    outline: none;
                }
            }
        }
    }
`;

export default StyledDetailsContainer;
