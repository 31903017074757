import React, { useState, useEffect } from 'react';
import { fetchVenueStatistics } from '../../../services/Http/Api';
import DataTable from 'react-data-table-component';
import PeriodSelectBox from "../../global/PeriodSelectBox";
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { MdAttachMoney, MdShoppingCart, MdReceipt, MdTrendingUp } from 'react-icons/md';

const numberFormatter = (value) => new Intl.NumberFormat('en-US').format(value);

const VenueStatistics = ({ venueId }) => {
    const { t } = useTranslation();
    const [venueStats, setVenueStats] = useState([]);
    const [totals, setTotals] = useState(null);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [totalRows, setTotalRows] = useState(0);
    const [sortBy, setSortBy] = useState('date');
    const [sortDirection, setSortDirection] = useState('asc');
    const [selectedPeriod, setSelectedPeriod] = useState('yesterday');

    useEffect(() => {
        const loadVenueStats = async () => {
            setLoading(true);
            try {
                const data = await fetchVenueStatistics({
                    venueId,
                    period: selectedPeriod,
                    page: currentPage,
                    perPage,
                    sortBy,
                    sortDirection,
                });

                setTotals(data.totals);
                setVenueStats(data.stats.data);
                setTotalRows(data.stats.total);
            } catch (error) {
                console.error('Failed to load venue stats', error);
            } finally {
                setLoading(false);
            }
        };

        loadVenueStats();
    }, [selectedPeriod, currentPage, perPage, sortBy, sortDirection, venueId]);

    const handlePageChange = (page) => setCurrentPage(page);
    const handlePerRowsChange = (newPerPage, page) => {
        setPerPage(newPerPage);
        setCurrentPage(page);
    };
    const handleSort = (column, sortDirection) => {
        const columnField = column.key || 'date';
        setSortBy(columnField);
        setSortDirection(sortDirection);
    };

    const columns = [
        { name: t('Venues.VenueView.VenueStatistics.date'), selector: row => new Date(row.date).toLocaleDateString(), sortable: true, key: 'date' },
        { name: t('Venues.VenueView.VenueStatistics.total_price'), selector: row => numberFormatter(row.total_price), sortable: true, key: 'total_price' },
        { name: t('Venues.VenueView.VenueStatistics.total_revenue'), selector: row => numberFormatter(row.total_revenue), sortable: true, key: 'total_revenue' },
        { name: t('Venues.VenueView.VenueStatistics.total_quantity'), selector: row => numberFormatter(row.total_quantity), sortable: true, key: 'total_quantity' },
        { name: t('Venues.VenueView.VenueStatistics.transaction_count'), selector: row => numberFormatter(row.transaction_count), sortable: true, key: 'transaction_count' },
    ];

    const handlePeriodChange = (e) => {
        setSelectedPeriod(e.target.value);
    };

    return (
        <StatisticsContainer>
            <Header>
                <PeriodSelectBox value={selectedPeriod} onChange={handlePeriodChange} />
            </Header>

            <StatsGrid>
                <StatCard color="primary">
                    <MdAttachMoney size={30} className="icon" />
                    <StatContent>
                        <StatTitle>{t('Venues.VenueView.VenueStatistics.total_price')}</StatTitle>
                        <StatValue>${totals ? numberFormatter(totals.total_revenue) : 0}</StatValue>
                    </StatContent>
                </StatCard>

                <StatCard color="success">
                    <MdShoppingCart size={30} className="icon" />
                    <StatContent>
                        <StatTitle>{t('Venues.VenueView.VenueStatistics.total_transactions')}</StatTitle>
                        <StatValue>{totals ? numberFormatter(totals.total_transactions) : 0}</StatValue>
                    </StatContent>
                </StatCard>

                <StatCard color="info">
                    <MdReceipt size={30} className="icon" />
                    <StatContent>
                        <StatTitle>{t('Venues.VenueView.VenueStatistics.total_revenue')}</StatTitle>
                        <StatValue>{totals ? numberFormatter(totals.total_revenue) : 0}</StatValue>
                    </StatContent>
                </StatCard>

                <StatCard color="warning">
                    <MdTrendingUp size={30} className="icon" />
                    <StatContent>
                        <StatTitle>{t('Venues.VenueView.VenueStatistics.total_revenue')}</StatTitle>
                        <StatValue>${totals ? numberFormatter(totals.total_price) : 0}</StatValue>
                    </StatContent>
                </StatCard>
            </StatsGrid>

            <DataTable
                columns={columns}
                data={venueStats}
                pagination
                paginationServer
                paginationTotalRows={totalRows}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handlePerRowsChange}
                onSort={handleSort}
                sortServer
                highlightOnHover
                progressPending={loading}
                paginationRowsPerPageOptions={[10, 25, 50, 100]}
                paginationDefaultPage={currentPage}
                paginationPerPage={perPage}
            />
        </StatisticsContainer>
    );
};

export default VenueStatistics;

// Styled Components

const StatisticsContainer = styled.div`
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

    @media (max-width: 768px) {
        padding: 10px;
    }
`;

const Header = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;

    @media (max-width: 768px) {
        justify-content: center;
    }
`;

const StatsGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
    gap: 15px;

    @media (max-width: 768px) {
        gap: 10px;
    }
`;

const StatCard = styled.div`
    display: flex;
    align-items: center;
    padding: 15px;
    background: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-left: 4px solid ${({ color }) => getColor(color)};

    .icon {
        font-size: 2rem;
        color: ${({ color }) => getColor(color)};
        margin-right: 15px;
    }

    @media (max-width: 768px) {
        padding: 10px;
        .icon {
            font-size: 1.5rem;
        }
    }
`;

const getColor = (color) => {
    switch (color) {
        case 'primary':
            return '#007bff';
        case 'success':
            return '#28a745';
        case 'info':
            return '#17a2b8';
        case 'warning':
            return '#ffc107';
        default:
            return '#333';
    }
};

const StatContent = styled.div`
    display: flex;
    flex-direction: column;
`;

const StatTitle = styled.h5`
    font-size: 1.1rem;
    color: rgba(130, 22, 146, 0.8);
`;

const StatValue = styled.p`
    font-size: 1.3rem;
    font-weight: bold;
`;
