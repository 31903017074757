import React, { createContext, useState, useContext, useEffect } from 'react';
import { GetMe, refreshAuthToken, login as loginService } from '../services/Http/Api';
import { useNavigate, useLocation } from 'react-router-dom';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

const isTokenExpired = (token) => {
    if (!token) return true;
    const decoded = JSON.parse(atob(token.split('.')[1])); // Decode JWT payload
    const now = Math.floor(Date.now() / 1000); // Current time in seconds
    return decoded.exp < now; // Check if the token is expired
};

const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const initializeAuth = async () => {
            const token = localStorage.getItem('jwt_token');
            if (token && !isTokenExpired(token)) {
                try {
                    const userData = await GetMe();
                    setUser(userData);
                    setIsAuthenticated(true);
                } catch (error) {
                    console.error('Failed to fetch user data during initialization:', error);
                    setIsAuthenticated(false);
                    localStorage.removeItem('jwt_token'); // Clear invalid token
                }
            } else if (token && isTokenExpired(token)) {
                try {
                    const newToken = await refreshAuthToken();
                    if (newToken) {
                        localStorage.setItem('jwt_token', newToken);
                    const userData = await GetMe();
                    setUser(userData);
                    setIsAuthenticated(true);
                    }
                } catch (error) {
                    console.error('Failed to refresh token during initialization:', error);
                    setIsAuthenticated(false);
                    localStorage.removeItem('jwt_token');
                }
            }
            setLoading(false);
        };

        initializeAuth();
    }, []);

    useEffect(() => {
        if (!isAuthenticated) {
            if (!['/login', '/register', '/forgot-password'].includes(location.pathname)) {
                localStorage.setItem('lastVisitedPath', location.pathname);
            }
        }
    }, [isAuthenticated, location.pathname]);

    const login = async (email, password) => {
        try {
            const data = await loginService(email, password);
            localStorage.setItem('jwt_token', data.token);
            setUser(data.user);
            setIsAuthenticated(true);

            // Navigate to the saved path or a default route
            const targetPath = localStorage.getItem('lastVisitedPath') || '/';
            localStorage.removeItem('lastVisitedPath');
            navigate(targetPath, { replace: true });
        } catch (error) {
            throw new Error('Login failed');
    }
    };

    const logout = () => {
        localStorage.removeItem('jwt_token');
        localStorage.removeItem('lastVisitedPath');
        setUser(null);
        setIsAuthenticated(false);
        navigate('/login', { replace: true });
    };

    return (
        <AuthContext.Provider
            value={{
                isAuthenticated,
                user,
                login,
                logout,
                loading,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;
